import React from 'react';
import './nutrition.css'; // Import the CSS file

const BasketLoader = () => {
    // Calculate the loading time based on the Redux state
    const loadingTime = 30;

    return (
        <div className="load-container" style={{width: '95%'}}>
            <svg
                stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 16 16" className="loader"
                style={{
                    animation: `fillUpAnimation ${loadingTime}s ease-out forwards`
                }}
                xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M5.071 1.243a.5.5 0 0 1 .858.514L3.383 6h9.234L10.07 1.757a.5.5 0 1 1 .858-.514L13.783 6H15.5a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5H15v5a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V9H.5a.5.5 0 0 1-.5-.5v-2A.5.5 0 0 1 .5 6h1.717L5.07 1.243zM3.5 10.5a.5.5 0 1 0-1 0v3a.5.5 0 0 0 1 0v-3zm2.5 0a.5.5 0 1 0-1 0v3a.5.5 0 0 0 1 0v-3zm2.5 0a.5.5 0 1 0-1 0v3a.5.5 0 0 0 1 0v-3zm2.5 0a.5.5 0 1 0-1 0v3a.5.5 0 0 0 1 0v-3zm2.5 0a.5.5 0 1 0-1 0v3a.5.5 0 0 0 1 0v-3z"></path>
            </svg>
            <h5>Generating shopping list...</h5>
        </div>
    );
};

export default BasketLoader;




