import {useDispatch, useSelector} from 'react-redux';
import callApi from "../../../common/api/Apiutils";
import {useAlert} from "../../../common/fieldfactory/alert/AlertContext";
import {IsEmptyOrSpaces} from "../../../common/logic/Events";
import {
    addDay,
    addExercise,
    addLinkedExercise,
    addWeek,
    deleteDay,
    deleteExercise,
    deleteWeek,
    duplicateWeek,
    editDayName,
    editWeekName,
    setChangeWorkout,
    setCloseModal,
    setExercise,
    setNewWorkout,
    setPageState,
    setWorkoutDescription,
    setDropDownLists,
    setWorkoutName,
    setWorkOutSelected,
    updateExercise,
    addOneRepMax,
    updateOneRepMaxName,
    deleteOneRepMax
} from './Reducer';

export const useReduxActions = () => {
    const handleOpenModalForCreate = useOpenModalForCreate();
    const handleOpenModalForEdit = useOpenModalForEdit();
    const handleDeleteDay = useHandleDeleteDay();
    const handleDeleteExercise = useHandleDeleteExercise();
    const handleAddOrUpdateExercise = useHandleAddOrUpdateExercise();
    const handleAddDay = useHandleAddDay();
    const handleAddWeek = useHandleAddWeek();
    const handleDuplicateWeek = useHandleDuplicateWeek();
    const handleDeleteWeek = useHandleDeleteWeek();
    const handleSaveWorkout = useHandleSaveWorkout();
    const handleSwitchWorkout = useHandleSwitchWorkout();
    const handleCreateNewWorkOut = useHandleCreateNewWorkOut();
    const handleDeleteWorkOut = useHandleDeleteWorkOut();
    const handleUpdateWorkoutDescription = useHandleUpdateWorkoutDescription();
    const handleUpdateWorkoutName = useHandleUpdateWorkoutName();
    const handleSelectExercise = useHandleSelectExercise();
    const handleEditDayName = useHandleEditDayName();
    const handleEditWeekName = useHandleEditWeekName();
    const handleDeleteOneRepMax = useHandleDeleteOneRepMax();
    const handleAddOneRepMax = useHandleAddOneRepMax();
    const handleUpdateOneRepMaxName = useHandleUpdateOneRepMaxName();
    const handleSetExercise = useSetExercise();
    const handleSetLinkedExerciseWeek = useSetLinkedExerciseWeek();
    const handleSetLinkedExerciseDay = useSetLinkedExerciseDay();
    const handleSetLinkedExerciseExercise = useSetLinkedExerciseExercise();
    const handleCancelCreateExercise = useCancelCreateExercise();
    const handleSetLinkedExerciseDefault = useSetLinkedExerciseDefault();
    return {
        handleDuplicateWeek,
        handleOpenModalForCreate,
        handleDeleteWorkOut,
        handleOpenModalForEdit,
        handleDeleteDay,
        handleDeleteExercise,
        handleAddOrUpdateExercise,
        handleAddDay,
        handleAddWeek,
        handleDeleteWeek,
        handleSaveWorkout,
        handleSwitchWorkout,
        handleCreateNewWorkOut,
        handleUpdateWorkoutName,
        handleUpdateWorkoutDescription,
        handleSelectExercise,
        handleEditDayName,
        handleEditWeekName,
        handleDeleteOneRepMax,
        handleAddOneRepMax,
        handleUpdateOneRepMaxName,
        handleSetExercise,
        handleSetLinkedExerciseWeek,
        handleSetLinkedExerciseDay,
        handleSetLinkedExerciseExercise,
        handleCancelCreateExercise,
        handleSetLinkedExerciseDefault
    };
};

const useSetLinkedExerciseExercise = () => {
    return (e, exerciseName,setState) => {
        setState((prevState) => ({
            ...prevState,
            linkedExercise: {
                ...prevState.linkedExercise,
                exercise: e,
                exerciseName: exerciseName
            },
        }));
    }
}

const useCancelCreateExercise = () => {
    const dispatch = useDispatch();
    return () => {
        dispatch(setCloseModal());
    }
}

const useSetLinkedExerciseWeek = () => {
    return (e, exerciseModalSetState) => {
        exerciseModalSetState((prevState) => ({
            ...prevState,
            linkedExercise: {
                ...prevState.linkedExercise,
                week: e,
                day: 0,
                exercise: undefined,
                exerciseName: undefined
            },
        }));

    };
}

const useSetLinkedExerciseDefault = () => {
    return (exerciseModalSetState) => {
        exerciseModalSetState((prevState) => ({
            ...prevState,
            linkedExercise: {
                ...prevState.linkedExercise,
                week: undefined,
                day: undefined,
                exercise: undefined,
                exerciseName: undefined
            },
        }));

    };
}

const useSetLinkedExerciseDay = () => {
    return (e, exerciseModalSetState) => {
        exerciseModalSetState((prevState) => ({
            ...prevState,
            linkedExercise: {
                ...prevState.linkedExercise,
                day: e,
                exercise: undefined,
                exerciseName: undefined
            },
        }));
    }
}

const useHandleAddOneRepMax = () => {
    const dispatch = useDispatch();
    return async () => {
        dispatch(addOneRepMax())
    }
};

const useHandleDeleteOneRepMax = () => {
    const dispatch = useDispatch();
    return (indexToDelete) => {
        const trueIndex = indexToDelete + 1;
        dispatch(deleteOneRepMax(trueIndex));
    }
};

const useHandleUpdateOneRepMaxName = () => {

    const dispatch = useDispatch();
    return (index, value) => {
        const trueIndex = index + 1;
        dispatch(updateOneRepMaxName({index: trueIndex, value: value}))
    }

}

const useHandleEditDayName = () => {
    const dispatch = useDispatch();
    return (name) => {
        dispatch(editDayName(name));
    }
}

const useHandleEditWeekName = () => {
    const dispatch = useDispatch();
    return (name) => {
        dispatch(editWeekName(name));
    }
}

const useHandleSelectExercise = () => {
    const dispatch = useDispatch();
    return (weekIndex, dayIndex, exerciseIndex, week, day, exercise) => {
        dispatch(addLinkedExercise({
            weekIndex: weekIndex,
            dayIndex: dayIndex,
            exerciseIndex: exerciseIndex,
            week: week,
            day: day,
            exercise: exercise
        }))
    };
}

const useHandleDeleteWorkOut = () => {
    const {showAlert, hideAlert} = useAlert();
    const {workOutSelected} = useSelector(state => state.createWorkOut);
    const authorization = useSelector(state => state.default.authorization);
    const dispatch = useDispatch();
    return async () => {

        const newButtons = [
            {
                label: "Yes",
                onClick: async () => {
                    const response = await callApi('/fit/workout', 'DELETE', null, {
                        Authorization: authorization,
                        service: 'CreateWorkoutService'
                    }, {workOutName: workOutSelected}, dispatch)
                    showAlert(response.message)
                    await loadDropDownLists(dispatch, showAlert, authorization);
                    dispatch(setNewWorkout());
                }
            },
            {label: "No", onClick: hideAlert}
        ];
        showAlert('Are you sure you want to delete the Program?', newButtons);
    }
}

const useHandleCreateNewWorkOut = () => {
    const dispatch = useDispatch();
    return () => {
        dispatch(setNewWorkout())
    }
}

export const loadDropDownLists = async (dispatch, showAlert, authorization) => {

    const dropDownLists = await callApi(`/fit/workout/programlist`, 'GET', null, {
        Authorization: authorization,
        service: 'CreateWorkoutService'
    }, undefined, dispatch);
    if (dropDownLists.error) {
        showAlert(dropDownLists.message)
        return false;
    }
    dispatch(setDropDownLists(dropDownLists));
    return true;
}

export const columns = [
    {label: 'Exercise Name', key: 'name', width: '200px'},
    {label: 'Repetition Type', key: 'repetitionType', width: '150px'},
    {label: 'Sets', key: 'sets', width: '130px', align: 'right'},
    {label: 'Reps', key: 'reps', width: '130px', align: 'right'},
    {label: 'Weight', key: 'weight', width: '130px', align: 'right'},
    {label: 'Intensity', key: 'intensity', width: '125px', align: 'right'},
    {label: 'One Rep Max', key: 'oneRepMaxLink', width: '150px'},
    {label: 'RPE', key: 'rpe', width: '125px', align: 'right'},
    {label: 'Linked Exercise', key: 'linkedExerciseName', width: '205px'},
    {label: 'Increment By', key: 'increment', width: '150px', align: 'right'},
    {label: 'Round to closest', key: 'roundWeight', width: '150px', align: 'right'},
];

const useHandleUpdateWorkoutDescription = () => {
    const dispatch = useDispatch();
    return async (e) => {
        dispatch(setWorkoutDescription(e.target.value))
    }
}

const useHandleUpdateWorkoutName = () => {
    const dispatch = useDispatch();
    return async (e) => {
        dispatch(setWorkoutName(e.target.value))
    }
}

const useHandleSwitchWorkout = () => {
    const {showAlert} = useAlert();
    const dispatch = useDispatch();
    const authorization = useSelector(state => state.default.authorization);
    return async (e) => {
        if (IsEmptyOrSpaces(e)) {
            dispatch(setNewWorkout())
            return;
        }
        const response = await callApi(`/fit/workout`, 'GET', null, {
            Authorization: authorization,
            service: 'CreateWorkoutService'
        }, {workOutName: e}, dispatch)
        if (response.error) {
            showAlert(response.message);
            return;
        }
        dispatch(setChangeWorkout(response));
        showAlert('Program loaded');

    }
}

const validateExercise = ({exercise, dayIndex, weekIndex}) => {
    const {
        name,
        sets,
        weight,
        intensity,
        repetitionType,
        increment,
        roundWeight,
        oneRepMaxLink,
    } = exercise;


    if (!name || name.length < 1 || name.length > 60) {
        return `Week ${weekIndex + 1} , Day ${dayIndex + 1} - ${name} name length must be between 1 and 60 characters`;
    }

    if (sets < 0 || sets > 20) {
        return `Week ${weekIndex + 1} , Day ${dayIndex + 1} - ${name} Maximum number of sets is 20`;
    }


    const decimalValidation = /^(\d{1,8})(\.\d{1,2})?$/;
    if (weight && !decimalValidation.test(weight.toString())) {
        return `Week ${weekIndex + 1} , Day ${dayIndex + 1} - ${name} Weight must be a decimal number with up to 8 integer digits and 2 fractional digits`;
    }
    if (intensity && !decimalValidation.test(intensity.toString())) {
        return `Week ${weekIndex + 1} , Day ${dayIndex + 1} - ${name} Intensity must be a decimal number with up to 8 integer digits and 2 fractional digits`;
    }
    if (roundWeight && !decimalValidation.test(roundWeight.toString())) {
        return `Week ${weekIndex + 1} , Day ${dayIndex + 1} - ${name} Round weight must be a decimal number with up to 8 integer digits and 2 fractional digits`;
    }

    if (repetitionType && !["AMRAP", "Custom", "Eight Rep Max", "Five Rep Max", "One Rep Max"].includes(repetitionType)) {
        return `Week ${weekIndex + 1} , Day ${dayIndex + 1} - ${name} Supported repetition Types are AMRAP,Custom,Eight Rep Max,Five Rep Max,One Rep Max`;
    }


    if (increment && !/^(\d{1,2})(\.\d{1,2})?$/.test(increment.toString())) {
        return `Week ${weekIndex + 1} , Day ${dayIndex + 1} - ${name} Increment must be a decimal number with up to 2 integer digits and 2 fractional digits`;
    }


    if (oneRepMaxLink && oneRepMaxLink.trim() === '') {
        return `Week ${weekIndex + 1} , Day ${dayIndex + 1} - ${name} One Rep Max Link must not be empty`;
    }


    return '';
}


const validateWorkout = ({weeks, name, description, oneRepMaxList}) => {
    let errors = [];

    if (IsEmptyOrSpaces(name)) errors.push('Please enter a workout name');
    if (name.length < 10 || name.length > 255) errors.push('Program name must be between 10 and 255 characters.');
    if (weeks.length > 20) errors.push('The number of weeks must not exceed 20.');
    if (oneRepMaxList.length > 10) errors.push('A program can only have 10 one rep maxes.');
    if (description.length > 5000) errors.push('Max description length is 5000 characters.');

    weeks.forEach((week, weekIndex) => {
        if (week.name.length < 1 || week.name.length > 50) errors.push(`Week ${weekIndex + 1} name must be between 1 and 50 characters.`);
        if (week.days.length > 7) errors.push(`Week ${weekIndex + 1}: The number of days in a week cannot exceed 7.`);

        week.days.forEach((day, dayIndex) => {
            if (day.name.length < 1 || day.name.length > 50) errors.push(`Day ${dayIndex + 1} name must be between 1 and 50 characters.`);
            if (day.exercises.length > 20) errors.push(`Day ${dayIndex + 1}: The number of exercises in a day cannot exceed 20.`);

            day.exercises.forEach((exercise) => {
                let exerciseErrors = validateExercise({exercise, dayIndex, weekIndex});
                errors = errors.concat(exerciseErrors);
            });
        });
    });


    errors = errors.flat().filter(error => error);

    // Join all error strings into one
    return errors.length > 0 ? errors.join(' ') : '';
};

const useHandleSaveWorkout = () => {
    const {weeks, name, description, oneRepMaxList} = useSelector(state => state.createWorkOut);
    const authorization = useSelector(state => state.default.authorization);
    const validation = validateWorkout({weeks, name, description, oneRepMaxList});
    const {showAlert} = useAlert();
    const dispatch = useDispatch();
    return async () => {
        if (validation !== '') {
            showAlert(validation);
            return;
        }
        const response = await callApi(`/fit/workout`, 'POST', {
            name: name,
            description: description,
            weeks: weeks,
            oneRepMaxList: oneRepMaxList.slice(1)
        }, {Authorization: authorization, service: 'CreateWorkoutService'}, undefined, dispatch)
        showAlert(response.message)
        await loadDropDownLists(dispatch, showAlert, authorization);
        dispatch(setWorkOutSelected(name))

    }
}

const useOpenModalForCreate = () => {
    const dispatch = useDispatch();
    return async (dayIndex, weekIndex, setShowCreate) => {
        dispatch(setPageState('CREATE'));
        setShowCreate(true);
        dispatch(setExercise({dayIndex, exerciseIndex: -1, weekIndex}))
    }
};

const useHandleAddWeek = () => {
    const {showAlert} = useAlert();
    const dispatch = useDispatch();
    return (weekName, setWeekName) => {
        if (!IsEmptyOrSpaces(weekName)) {
            const newDay = weekName;
            setWeekName('');
            dispatch(addWeek(newDay));
        } else {
            showAlert('Enter a week name');
        }
    }
};

const useHandleDuplicateWeek = () => {
    const {showAlert, hideAlert} = useAlert();
    const dispatch = useDispatch();
    const {weeks} = useSelector(state => state.createWorkOut);
    return (index) => {
        const currentWeek = updateLinkedExerciseWeekImmutable(weeks[index]);
        const newButtons = [
            {
                label: "Yes",
                onClick: () => {
                    dispatch(duplicateWeek(currentWeek));
                }
            },
            {label: "No", onClick: hideAlert}
        ];
        showAlert(`Are you sure you want to duplicate Week ${index + 1}?`, newButtons);
    }
}

const updateLinkedExerciseWeekImmutable = (currentWeek) => {
    // Create a deep copy of currentWeek with updated linkedExercise weeks
    return {
        ...currentWeek,
        days: currentWeek.days.map(day => ({
            ...day,
            exercises: day.exercises.map(exercise => {
                // Check if the exercise has a linkedExercise
                if (exercise.linkedExercise) {
                    // Return a new exercise object with an updated linkedExercise
                    return {
                        ...exercise,
                        linkedExercise: {
                            day: exercise.linkedExercise.day,
                            exercise: exercise.linkedExercise.exercise,
                            week: exercise.linkedExercise.week + 1
                        }
                    }
                }
                // Return the exercise unchanged if no linkedExercise
                return exercise;
            })
        }))
    };
};

const useHandleAddDay = () => {
    const {showAlert} = useAlert();
    const dispatch = useDispatch();
    return (dayName, setDayName, weekIndex) => {
        if (!IsEmptyOrSpaces(dayName)) {
            const newDay = dayName;
            setDayName('');
            dispatch(addDay({newDay, weekIndex}));
        } else {
            showAlert('Enter a day name');
        }
    }

};

const useOpenModalForEdit = () => {
    const dispatch = useDispatch();
    return (exerciseIndex, dayIndex, weekIndex) => {
        dispatch(setExercise({dayIndex, exerciseIndex, weekIndex}));
        dispatch(setPageState('EDIT'));
    }
};

const useSetExercise = () => {
    const dispatch = useDispatch();
    return (exerciseIndex, dayIndex, weekIndex) => {
        dispatch(setExercise({dayIndex, exerciseIndex, weekIndex}));
    }
}

const useHandleAddOrUpdateExercise = () => {
    const {showAlert} = useAlert();
    const dispatch = useDispatch();
    return (modalMode, setShowCreate, exerciseIndex, dayIndex, weekIndex, state) => {
        const {
            exerciseName,
            exerciseMode,
            sets,
            reps,
            amrAp,
            weight,
            intensity,
            rpe,
            increment,
            roundWeight,
            oneRepMaxLink,
            linkedExercise
        } = state;
        if (IsEmptyOrSpaces(exerciseName)) {
            showAlert('Enter an exercise name');
            return;
        }
        const exerciseData = {
            name: exerciseName,
            sets: amrAp === 'Eight Rep Max' || amrAp === 'One Rep Max' || amrAp === 'Five Rep Max' ? null : parseInt(sets),
            reps: amrAp === 'AMRAP' || amrAp === 'Eight Rep Max' || amrAp === 'One Rep Max' || amrAp === 'Five Rep Max' ? null : parseInt(reps),
            repetitionType: amrAp,
            oneRepMaxLink: oneRepMaxLink === '' ? undefined : oneRepMaxLink,
            increment: increment,
            roundWeight: roundWeight,
            // Default properties
            rpe: null,
            intensity: null,
            weight: null,
            linkedExercise: null,
        };

        switch (exerciseMode) {
            case 2:
                exerciseData.weight = parseFloat(weight);
                exerciseData.linkedExerciseName = null;
                break;
            case 1:
                if (exerciseData.oneRepMaxLink === undefined) {
                    showAlert('You have selected intensity which requires you to select a one rep max');
                    return;
                }
                if (intensity === null) {
                    showAlert('You have selected intensity which requires you to input a intensity percentage');
                    return;
                }
                exerciseData.intensity = parseFloat(intensity);
                exerciseData.linkedExerciseName = null;
                break;
            case 0:
                if (rpe === '') {
                    showAlert('You enter a RPE value');
                    return;
                }
                exerciseData.rpe = rpe;
                exerciseData.linkedExerciseName = null;
                break;
            case 3:
                exerciseData.linkedExercise = linkedExercise;
                exerciseData.linkedExerciseName = linkedExercise.exerciseName;
                break;
            default:
                // Optionally handle any unexpected cases
                break;
        }
        if (exerciseData.repetitionType === '') {
            showAlert('You have to select an Repetition Type');
            return;
        }
        if (exerciseData.repetitionType === 'Custom' && exerciseData.reps === 0) {
            showAlert('You have to set a minimum of 1 rep in custom reps');
            return;
        }
        if (exerciseData.rpe === null && exerciseData.intensity === null && exerciseData.weight === null && exerciseData.linkedExercise === null) {
            showAlert('You have to select an Effort Determination Method');
            return;
        }
        if (exerciseData.increment === null) {
            showAlert('Please set an increment value');
            return;
        }
        if (exerciseData.roundWeight === null) {
            showAlert('Please select a round weight value');
            return;
        }
        const action = modalMode === 'CREATE'
            ? addExercise({dayIndex, exercise: exerciseData, weekIndex})
            : updateExercise({dayIndex, exerciseIndex, exercise: exerciseData, weekIndex});
        dispatch(action);
        setShowCreate(false);
    };
};

const useHandleDeleteExercise = () => {
    const dispatch = useDispatch();
    const {hideAlert, showAlert} = useAlert();
    return (dayIndex, exerciseIndex, weekIndex) => {
        const newButtons = [
            {
                label: "Yes",
                onClick: () => {
                    dispatch(deleteExercise({dayIndex, exerciseIndex, weekIndex}));
                    dispatch(setCloseModal());
                }
            },
            {label: "No", onClick: hideAlert}
        ];
        showAlert('Are you sure you want to delete the exercise?', newButtons);
    };

};

const useHandleDeleteDay = () => {
    const dispatch = useDispatch();
    const {hideAlert, showAlert} = useAlert();
    return (dayIndex, weekIndex) => {
        const newButtons = [
            {label: "Yes", onClick: () => dispatch(deleteDay({dayIndex, weekIndex}))},
            {label: "No", onClick: hideAlert}
        ];
        showAlert('Are you sure you want to delete the day?', newButtons);
    }
};

const useHandleDeleteWeek = () => {
    const dispatch = useDispatch();
    const {hideAlert, showAlert} = useAlert();
    return (weekIndex) => {
        const newButtons = [
            {label: "Yes", onClick: () => dispatch(deleteWeek(weekIndex))},
            {label: "No", onClick: hideAlert}
        ];
        showAlert('Are you sure you want to delete the week?', newButtons);
    }
};