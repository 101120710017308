import React from 'react';
import PropTypes from 'prop-types';
import {Button} from '../../../common/fieldfactory/index';
import {useReduxActions} from "../logic/Events";

const LinkExerciseItem = ({index, displayExercise, setState,linkedExercise}) => {
    const renderField = (label, value) => {
        if (value !== null && value !== '') {
            return (
                <p><strong>{label}:</strong> {value}</p>
            );
        }
        return null;
    };
    const {
        handleSetLinkedExerciseExercise,
    } = useReduxActions();
    const {exercise} = linkedExercise;
    const buttonView = exercise === index;
    return (
        <div className={'exercise-item'}>
            <h4>Exercise {index + 1} - {displayExercise.name}</h4>
            {renderField('Repetition Type', displayExercise.repetitionType)}
            {renderField('Sets', displayExercise.sets)}
            {renderField('Reps', displayExercise.reps)}
            {buttonView && <div className="button-container-right">
                <Button label="Currently Selected" className="button mt-10 half-width"
                        onClick={() => console.log('TEST')}/>
            </div>}
            {!buttonView && <div className="button-container-right">
                <Button label="Select" className="button button-tertiary mt-10 half-width"
                        onClick={() => handleSetLinkedExerciseExercise(index,displayExercise.name,setState)}/>
            </div>}
        </div>

    );
};

LinkExerciseItem.propTypes = {
    displayExercise: PropTypes.shape({
        name: PropTypes.string.isRequired,
        repetitionType: PropTypes.string,
        sets: PropTypes.number,
        reps: PropTypes.number,
        weight: PropTypes.number,
        intensity: PropTypes.string,
        oneRepMaxLink: PropTypes.string,
        rpe: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        linkedExercise: PropTypes.object,
        linkedExerciseName: PropTypes.string,
        increment: PropTypes.string,
        roundWeight: PropTypes.string,
    }).isRequired,
};

const LinkExerciseList = ({exercises, setState ,linkedExercise}) => {


    return (<>
        <div className={'exercise-list'}>
            {exercises.map((exercise, index) => (
                <LinkExerciseItem key={index} displayExercise={exercise} index={index} setState={setState} linkedExercise={linkedExercise}/>
            ))}
        </div>
    </>);
};

LinkExerciseList.propTypes = {
    exercises: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default LinkExerciseList;
