import {useDispatch, useSelector} from "react-redux";
import {
    addCategory, addItemToCategories,
    addOneRepMax, decreaseOrder, deleteCategory, deleteItemCategory,
    deleteOneRepMax, editCategory, editItemCategory, increaseOrder, setCurrentProgram,
    setSelectedUserWorkout, setSelectedWorkout,
    setUnit,
    updateOneRepMaxName,
    updateOneRepMaxWeight
} from "./Reducer";
import callApi from "../../../common/api/Apiutils";
import {useAlert} from "../../../common/fieldfactory/alert/AlertContext";
import {setUsernameAndEmail} from "../../login/logic/Reducer";

const useHandleAddItem = () => {
    const dispatch = useDispatch();
    return (index) => {
        dispatch(addItemToCategories(index))
    };
}

const useHandleEditItem = () => {
    const dispatch = useDispatch();
    return (value, index,itemIndexIndex) => {
        dispatch(editItemCategory({value, index , itemIndexIndex}));
    };
}

export const useReduxActions = () => {
    const handleSetUnit = useHandleSetUnit();
    const handleSaveCategories = useHandleSaveCategories();
    const handleSaveSettings = useHandleSaveSettings();
    const handleDeleteOneRepMax = useHandleDeleteOneRepMax();
    const handleAddOneRepMax = useHandleAddOneRepMax();
    const handleUpdateOneRepMaxName = useHandleUpdateOneRepMaxName();
    const handleUpdateOneRepMaxWeight = useHandleUpdateOneRepMaxWeight();
    const handleSaveOneRepMaxList = useHandleSaveOneRepMaxList();
    const handleSetSelectedUserWorkout = useSetSelectedUserWorkout();
    const handleSetCurrentUserWorkout = useSetCurrentUserWorkout();
    const handleSetCurrentSubscribedWorkout = useSetCurrentSubscribedWorkout();
    const handleSetSelectedWorkout = useSetSelectedWorkout();
    const handleDecreaseOrder = useHandleDecreaseOrder();
    const handleIncreaseOrder = useHandleIncreaseOrder();
    const handleDeleteCategory = useHandleDeleteCategory();
    const handleAddCategory = useHandleAddCategory();
    const handleEditCategory = useHandleEditCategory();
    const handleAddItem = useHandleAddItem();
    const handleEditItem = useHandleEditItem();
    const handleDeleteItem = useHandleDeleteItem();
    return {
        handleDeleteItem,
        handleEditItem,
        handleAddItem,
        handleSaveCategories,
        handleEditCategory,
        handleDeleteCategory,
        handleAddCategory,
        handleDecreaseOrder,
        handleIncreaseOrder,
        handleSetUnit,
        handleSetSelectedWorkout,
        handleSaveSettings,
        handleDeleteOneRepMax,
        handleAddOneRepMax,
        handleUpdateOneRepMaxName,
        handleUpdateOneRepMaxWeight,
        handleSaveOneRepMaxList,
        handleSetSelectedUserWorkout,
        handleSetCurrentUserWorkout,
        handleSetCurrentSubscribedWorkout
    }
}

const useHandleDeleteItem = () => {
    const dispatch = useDispatch();
    return ( index , itemIndexIndex) => {
        dispatch(deleteItemCategory({ index , itemIndexIndex}))
    }
}

const useHandleSaveCategories = () => {
    const categories = useSelector(state => state.setting.nutrition.categories);
    const {showAlert, hideAlert} = useAlert();
    const dispatch = useDispatch();
    const authorization = useSelector(state => state.default.authorization);
    return async () => {
        const newButtons = [
            {
                label: "Yes",
                onClick: async () => {
                    const response = await callApi(`/fit/logging/category`, 'POST', {categories: categories}, {
                        Authorization: authorization,
                        service: 'NutritionService'
                    }, undefined, dispatch);
                    if (response.error) {
                        showAlert(response.message);
                    }

                }
            },
            {label: "No", onClick: hideAlert}
        ];
        showAlert('Are you sure you want to update the meal categories?', newButtons);

    }
}

const useHandleDeleteCategory = () => {
    const dispatch = useDispatch();
    return (index) => {
        dispatch(deleteCategory(index));
    }
}


const useHandleEditCategory = () => {
    const dispatch = useDispatch();
    return (category, index) => {
        dispatch(editCategory({category, index}));
    }
}

const useHandleAddCategory = () => {
    const dispatch = useDispatch();
    return () => {
        dispatch(addCategory())
    }
}

const useHandleDecreaseOrder = () => {
    const dispatch = useDispatch();
    return (index) => {
        dispatch(decreaseOrder(index));
    }
}

const useHandleIncreaseOrder = () => {
    const dispatch = useDispatch();
    return (index) => {
        dispatch(increaseOrder(index));
    }
}

const useSetCurrentUserWorkout = () => {
    const {userWorkouts} = useSelector(state => state.setting.program);
    const authorization = useSelector(state => state.default.authorization);
    const dispatch = useDispatch();
    const {showAlert} = useAlert();
    return async (index) => {
        if (index >= 0) {
            const {workoutName, duration, programId} = userWorkouts[index];
            await showAlert(`This will change your current program to ${workoutName} .Are you sure you want to change?`
                , switchWorkoutAlertButtons(duration, dispatch, workoutName, programId, showAlert, authorization));
        }

    }
}

const switchWorkoutAlertButtons = (duration, dispatch, workoutName, programId, showAlert, authorization) => [

    {
        label: "Yes",
        onClick: async () => {
            const today = new Date();
            const futureDate = new Date(today.setDate(today.getDate() + duration * 7));
            const formattedDate = futureDate.toDateString();
            const response = await callApi('/fit/program', 'POST', {
                programId
            }, {Authorization: authorization, service: 'UserService'}, undefined, dispatch)
            if (response.error) {
                showAlert(response.message);
            } else {
                showAlert(response.message);
                dispatch(setCurrentProgram({name: workoutName, endDate: formattedDate}));
            }

        }
    },
    {label: "No"}
];

const useSetCurrentSubscribedWorkout = () => {
    const {selectedSubscribedWorkout, subscribedWorkouts} = useSelector(state => state.setting.program);
    const authorization = useSelector(state => state.default.authorization);
    const dispatch = useDispatch();
    const {showAlert} = useAlert();
    return async () => {
        if (selectedSubscribedWorkout >= 0) {
            const {workoutName, duration, programId} = subscribedWorkouts[selectedSubscribedWorkout];
            await showAlert(`This will change your current program to ${workoutName} .Are you sure you want to change?`
                , switchWorkoutAlertButtons(duration, dispatch, workoutName, programId, showAlert, authorization));
        }
    }
}

const useHandleAddOneRepMax = () => {
    const dispatch = useDispatch();
    return () => {
        dispatch(addOneRepMax());
    };
}

const useHandleUpdateOneRepMaxName = () => {
    const dispatch = useDispatch();
    return async (index, name) => {
        dispatch(updateOneRepMaxName({index, name}))
    }
}

const useHandleUpdateOneRepMaxWeight = () => {
    const dispatch = useDispatch();
    return async (index, weight) => {
        dispatch(updateOneRepMaxWeight({index, weight}))
    }
}

const useHandleDeleteOneRepMax = () => {
    const dispatch = useDispatch();
    const {showAlert} = useAlert();
    return async (index) => {
        const newButtons = [
            {
                label: "Yes",
                onClick: () => {
                    dispatch(deleteOneRepMax(index));

                }
            },
            {label: "No"}
        ];
        await showAlert('This will delete all one rep max history.\nAre you sure you want to delete?', newButtons)
    };
}

const useHandleSetUnit = () => {
    const dispatch = useDispatch();
    return (unit) => {
        dispatch(setUnit(unit));
    };
};


const useHandleSaveSettings = () => {
    const {showAlert} = useAlert();
    const dispatch = useDispatch();
    const authorization = useSelector(state => state.default.authorization);
    const {username, email} = useSelector(state => state.login);
    const {unit} = useSelector(state => state.setting);
    const {darkMode} = useSelector(state => state.default);
    return async e => {
        e.preventDefault();
        const response = await callApi('/fit/user/settings', 'POST', {
            username,
            email,
            unit,
            style: darkMode
        }, {Authorization: authorization, service: 'UserService'}, undefined, dispatch)
        if (response.error) {
            await showAlert(response.message);
        }
        dispatch(setUsernameAndEmail({username: response.username, email: response.email}));
        dispatch(setUnit(unit));
        await showAlert('Setting\'s updated');
    }
}

const useHandleSaveOneRepMaxList = () => {
    const {showAlert} = useAlert();
    const authorization = useSelector(state => state.default.authorization);
    const {oneRepMaxList} = useSelector(state => state.setting);
    const dispatch = useDispatch();
    return async e => {
        e.preventDefault();
        const response = await callApi(`/fit/onerepmax`, 'POST', {oneRepMaxDTOS: oneRepMaxList}, {
            Authorization: authorization,
            service: 'UserService'
        }, undefined, dispatch)
        if (response.error) {
            await showAlert(response.message);
        }
        await showAlert(response.message);
    }
}

const useSetSelectedUserWorkout = () => {
    const dispatch = useDispatch();
    return (index) => {
        dispatch(setSelectedUserWorkout(index));
    }
}

const useSetSelectedWorkout = () => {
    const dispatch = useDispatch();
    return (index) => {
        dispatch(setSelectedWorkout(index));
    }
}

export const userWorkoutsColumns = [
    {label: 'Workout Name', key: 'workoutName', width: '233px'},
    {label: 'Duration', key: 'duration', width: '150px', align: 'right'},
    {label: 'Required One Rep Max\'s', key: 'orm', width: '250px'},
    {label: 'Public', key: 'published', width: '194px'},
    {label: 'Subscriptions', key: 'subscriptions', width: '150px'},
    {label: 'Last Completed on', key: 'date', width: '194px'},
]

export const subscribedWorkoutsColumns = [
    {label: 'Workout Name', key: 'workoutName', width: '233px'},
    {label: 'Duration', key: 'duration', width: '150px', align: 'right'},
    {label: 'Required One Rep Max\'s', key: 'orm', width: '250px'},
    {label: 'Creator', key: 'creator', width: '194px'},
    {label: 'Cost', key: 'cost', width: '150px'},
    {label: 'Last Completed on', key: 'date', width: '194px'},
]