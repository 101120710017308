import React from 'react';
import {IoCheckmarkCircle, IoCheckmarkCircleOutline} from "react-icons/io5";

const SetCompletionComponent = ({ total, completed }) => {
    const checkmarks = [];

    for (let i = 0; i < total; i++) {
        checkmarks.push(
            i < completed ? (
                <IoCheckmarkCircle key={i} size={24} />
            ) : (
                <IoCheckmarkCircleOutline key={i} size={24} />
            )
        );
    }

    // Create rows of no more than 10 checkmarks
    const rows = [];
    for (let i = 0; i < checkmarks.length; i += 10) {
        rows.push(
            <div key={i} className="checkmark-row">
                {checkmarks.slice(i, i + 10)}
            </div>
        );
    }

    return (
        <>
            <h5 style={{marginBottom : '10px'}}>Sets completed {completed} / {total}</h5>
            <div className="checkmark-container">
                {rows}
            </div>
        </>
    );
};

export default SetCompletionComponent;
