import React from "react";
import {useSelector} from "react-redux";
import {Card} from '../../../common/fieldfactory/index';
import {convertToDateFormat, workoutInFuture} from "../logic/Events";
import ExerciseLogList from "./ExerciseLogItem";

const WorkoutComponent = () => {
    const { selectedDate , workoutList } = useSelector(state => state.home);
    const workout = workoutList[convertToDateFormat(selectedDate)];
    const header = `Workout ${workoutInFuture(selectedDate,workout) ? 'planned for':'completed on'} ${selectedDate}`;
    return(
        <Card header={header}>
            <ExerciseLogList log={workout}/>
        </Card>
    )
}

export default WorkoutComponent;