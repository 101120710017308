import React, {useEffect, useState} from 'react';
import {FiCheckSquare, FiXSquare} from "react-icons/fi";
import {useSelector} from "react-redux";
import {Button, Card, IconButton} from "../../../common/fieldfactory";
import {useReduxActions} from "../logic/Events";

const ShoppingListComponent = () => {
    const {
        shoppingList,
        page
    } = useSelector(state => state.nutrition);
    const {
        notInBasket,
        inBasket
    } = shoppingList
    const showBasket = inBasket.length !== 0;
    const {mobile} = useSelector(state => state.default);
    const [expandedComponent, setExpandedComponent] = useState(!mobile);
    const {
        handleSetPage,
        handleUpdateShoppingList
    } = useReduxActions();

    const handleCardClick = () => {
        if (mobile) {
            setExpandedComponent(prevState => !prevState);
            if (expandedComponent) {
                handleSetPage('ALL');
            } else {
                handleSetPage('SHOPPING');
            }
        }
    };

    useEffect(() => {
        if (mobile) {
            setExpandedComponent(false);
        } else {
            setExpandedComponent(true);
        }
    }, [mobile]);

    useEffect(() => {
        if (mobile) {
            if (page === 'SHOPPING') {
                setExpandedComponent(true);
            } else if (page === 'ALL') {
                setExpandedComponent(false);
            }
        }
    }, [mobile, page]);
    const cardHeader = page === 'ALL' ? 'Shopping' : 'List';
    return (
        <Card header={cardHeader}
              onClick={mobile && !expandedComponent ? handleCardClick : null}
              expandable={true}
              expanded={expandedComponent}
              collapseOnClick={handleCardClick}
        >
            {expandedComponent ?
                (
                    <>
                        <h5 style={{marginTop: 0}}>Remaining items</h5>
                        {notInBasket.map((item, index) => (
                            <ShoppingItem item={item} key={index} displayButton={true} index={index}/>
                        ))}
                        {showBasket && <> <h5>In basket</h5>
                            {inBasket.map((item, index) => (
                                <ShoppingItem item={item} key={index} displayButton={false} index={index}/>
                            ))}
                            <div className="button-container-right">
                                <Button label="Update shopping list" className="button mt-10 half-width"
                                        onClick={() => handleUpdateShoppingList()}/>
                            </div>
                        </>}
                    </>
                )
                :
                (<div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <h4 style={{marginBottom: 0}}>Remaining items</h4>
                        <h5 style={{marginBottom: 0}}>
                            {notInBasket.length}
                        </h5>
                    </div>
                )
            }

        </Card>
    )
}

export default ShoppingListComponent;


const ShoppingItem = ({item, displayButton, index}) => {
    const {name, quantity, unit} = item;
    const {
        handleAddToBasket,
        handleRemoveFromBasket
    } = useReduxActions();
    return (
        <>
            {index !== 0 && <div className="line-relative"/>}
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}
                 className={"mt-10 mb-10"}>
                <div>
                    {name} {quantity} {unit}
                </div>
                <IconButton
                    className={"button"}
                    onClick={displayButton ? () => handleAddToBasket(name) : () => handleRemoveFromBasket(name)}
                    icon={displayButton ? <FiCheckSquare size={20}/> : <FiXSquare size={20}/>}
                />
            </div>
        </>
    );
};