import React from 'react';
import {useSelector} from "react-redux";
import {Card} from "../../../common/fieldfactory";
import DoughnutGraph from "../../../common/fieldfactory/graphs/DoughnutGraph";

const MacroComponent = () => {
    const {mobile} = useSelector(state => state.default);
    const {selectedDate} = useSelector(state => state.nutrition);
    const {meals} = useSelector(state => state.nutrition.logging);

    const {
        proteinGoal: currentProteinGoal,
        fatGoal: currentFatGoal,
        carbGoal: currentCarbGoal,
    } = useSelector(state => state.nutrition.goals);
    const {
        proteinConsumed: currentProteinConsumed,
        fatConsumed: currentFatConsumed,
        carbsConsumed: currentCarbsConsumed,
    } = useSelector(state => state.nutrition.consumed);

    const selectedLog = meals.filter(item => item.date === selectedDate)[0];
    const {
        consumedCarbohydrate = 0,
        consumedFat = 0,
        consumedProtein = 0,
    } = selectedLog || {};
    const dateCheck = new Date().toLocaleDateString('en-CA') === selectedDate;
    if (!dateCheck && !selectedLog) {
        return <></>;
    }
    const proteinGoal = dateCheck ? Math.round(currentProteinGoal * 10) / 10 : Math.round(consumedProtein * 10) / 10;
    const proteinConsumed = dateCheck ? Math.round(currentProteinConsumed * 10) / 10 : Math.round(consumedProtein * 10) / 10;

    const fatGoal = dateCheck ? Math.round(currentFatGoal * 10) / 10 : Math.round(consumedFat * 10) / 10;
    const fatConsumed = dateCheck ? Math.round(currentFatConsumed * 10) / 10 : Math.round(consumedFat * 10) / 10;

    const carbGoal = dateCheck ? Math.round(currentCarbGoal * 10) / 10 : Math.round(consumedCarbohydrate * 10) / 10;
    const carbsConsumed = dateCheck ? Math.round(currentCarbsConsumed * 10) / 10 : Math.round(consumedCarbohydrate * 10) / 10;


    const proteinColour = 'rgb(226,144,0)';
    const fatColour = 'rgb(30,131,3)';
    const carbColour = 'rgb(186,0,0)';

    const graphSize = mobile ? '90px' : '150px';
    const cutoutSize = mobile ? '75%' : '80%';

    const header = (colour, text) => {
        return (mobile ? <h4 style={{color: colour, marginTop: '2px', marginBottom: '2px'}}>{text}</h4> :
                <h3 style={{color: colour}}>{text}</h3>
        )
    }

    const style = {marginTop: '2px', marginBottom: '2px'}

    const valueDisplay = (goal, consumed) => {
        if (dateCheck) {
            return (!mobile ? <div><h4 style={style}>{consumed}</h4><h5 style={style}>/{goal}g</h5></div> :
                    <div><h5 style={style}>{consumed}</h5><h6 style={style}>/{goal}g</h6></div>
            )
        } else {
            return (!mobile ? <div><h4 style={style}>{consumed}g</h4></div> :
                    <div><h5 style={style}>{consumed}g</h5></div>
            )
        }
    }
    const divStyle = mobile ? {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        width: '100%',
        marginTop: '5px',
    } : {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        width: '100%',
        marginTop: '5px',
        height: '250px'
    };
    return (
        <Card header={'Macros'}
              children={'This card will be a three pie chart of Carbs / Fat / Protein nutrition break down'}
        >
            <h5 style={{marginTop: '5px'}}>Macros calculated based ony your total calories</h5>
            <div style={divStyle}>
                <div style={{textAlign: 'center'}}>
                    {header(proteinColour, 'Protein')}
                    <DoughnutGraph goal={proteinGoal} used={proteinConsumed} cutoutSize={cutoutSize}
                                   graphSize={graphSize}
                                   usedColour={proteinColour}
                                   text={valueDisplay(proteinGoal, proteinConsumed)}
                    />
                </div>
                <div style={{textAlign: 'center'}}>
                    {header(fatColour, 'Fat')}
                    <DoughnutGraph goal={fatGoal} used={fatConsumed} cutoutSize={cutoutSize} graphSize={graphSize}
                                   usedColour={fatColour}
                                   text={valueDisplay(fatGoal, fatConsumed)}
                    />
                </div>
                <div style={{textAlign: 'center'}}>
                    {header(carbColour, 'Carbs')}
                    <DoughnutGraph goal={carbGoal} used={carbsConsumed} cutoutSize={cutoutSize} graphSize={graphSize}
                                   usedColour={carbColour}
                                   text={valueDisplay(carbGoal, carbsConsumed)}
                    />
                </div>
            </div>
        </Card>
    );
}

export default MacroComponent;
