import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
    DarkGreenModeIcon,
    DarkModeIcon,
    DarkRedModeIcon,
    GreenModeIcon,
    LightModeIcon,
    RedModeIcon
} from "../../../index";
import {SettingsIcon, useReduxActions} from "../../logic/Events";
import IconButton from "../../../iconbutton/IconButton";
import MobileNavButton from "../button/MobileNavButton";

const TopNav = () => {
    const dispatch = useDispatch();
    const {page,darkMode} = useSelector(state => state.default);
    const {
        handleNavigation,
    } = useReduxActions();
    const className = "nav-icon-top";
    const icon = (darkMode,classname) => {
        return darkMode === 'dark' ? <DarkModeIcon className={classname}/>:
            darkMode === 'red' ? <RedModeIcon className={classname}/>:
                darkMode === 'green' ? <GreenModeIcon className={classname}/>:
                    darkMode === 'red-dark' ? <DarkRedModeIcon className={classname}/>:
                        darkMode === 'green-dark' ? <DarkGreenModeIcon className={classname}/>
                            :  <LightModeIcon className={classname}/>;
    }
    return (
        <div id="top-nav" className={'nav-closed'}>
            <div className="icon-topbar-container">
                <IconButton onClick={() => console.log('Icon button')}
                            icon={icon(darkMode,'nav-logo')}/>
                <span className="icon-text"
                      onClick={() => console.log('Icon button')}>FitLifes</span>
            </div>
            <ul>
                <MobileNavButton children={SettingsIcon({page,className})} onClick={() =>  handleNavigation('SETTINGS', dispatch)}/>
            </ul>
        </div>
    );
};

export default TopNav;
