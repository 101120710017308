import React from 'react';
import { useSelector } from 'react-redux';
import Loader from "../../common/fieldfactory/loader/Loader";
import {useReduxActions} from "./logic/Events";
import {
    TextBox,
    Button, DarkModeIcon, RedModeIcon, GreenModeIcon, DarkRedModeIcon, DarkGreenModeIcon, LightModeIcon
} from '../../common/fieldfactory/index';

const Login = () => {
    const { username, password, email, pageState ,loading} = useSelector(state => state.login);
    const { darkMode} = useSelector(state => state.default);
    const {
        handleUsernameChange,
        handlePasswordChange,
        handleEmailChange,
        handleSetPageState,
        handleLogin,
        handleSignUp,
        handleResetPassword
    } = useReduxActions();
    const showEmail = pageState === 'FORGOTTEN' || pageState === 'SIGNUP';
    const forgotten = pageState === 'FORGOTTEN';
    const login = pageState === 'LOGIN';
    const signUp = pageState === 'SIGNUP';
    const icon = (darkMode,classname) => {
        return darkMode === 'dark' ? <DarkModeIcon className={classname}/>:
            darkMode === 'red' ? <RedModeIcon className={classname}/>:
                darkMode === 'green' ? <GreenModeIcon className={classname}/>:
                    darkMode === 'red-dark' ? <DarkRedModeIcon className={classname}/>:
                        darkMode === 'green-dark' ? <DarkGreenModeIcon className={classname}/>
                            :  <LightModeIcon className={classname}/>;
    }
    return (
        <div className="centered-container">
            <div className="icon-container">
                {icon(darkMode,'icon')}
            </div>
            <h1>Welcome to FitLifes</h1>
            {loading ? (
                <Loader/>
            ): (
                <form>
                    <div className="form-group">
                        <TextBox
                            type="text"
                            id="username"
                            label="Username"
                            value={username}
                            onChange={handleUsernameChange}
                            className="form-control"
                        />
                    </div>
                    {showEmail && <div className="form-group">
                        <TextBox
                            type="email"
                            id="email"
                            label="Email"
                            value={email}
                            onChange={handleEmailChange}
                            className="form-control"
                        />
                    </div>}
                    {!forgotten && <div className="form-group">
                        <TextBox
                            type="password"
                            id="password"
                            label="Password"
                            value={password}
                            onChange={handlePasswordChange}
                            className="form-control"
                        />
                    </div>}
                    {login && <>
                        <Button label="Log In" className="button mt-10" onClick={(e) => handleLogin(e)}/>
                        <Button label="Sign Up" className="button button-secondary mt-10"
                                onClick={() => handleSetPageState('SIGNUP')}/>
                        <div className="mt-10">
                            <Button label="Forgot Password?" className="button button-tertiary"
                                    onClick={() => handleResetPassword('FORGOTTEN')}/>
                        </div>
                    </>}
                    {signUp && <>
                        <Button label="Create Account" className="button mt-10" onClick={(e) => handleSignUp(e)}/>
                        <Button label="Sign In" className="button button-tertiary mt-10"
                                onClick={() => handleSetPageState('LOGIN')}/>
                    </>}
                    {forgotten && <>
                        <Button label="Password Reset" className="button mt-10"
                                onClick={() => handleSetPageState('RESETTING_PASSWORD')}/>
                        <Button label="Sign In" className="button button-tertiary mt-10"
                                onClick={() => handleSetPageState('LOGIN')}/>
                    </>}
                </form>
            )}
        </div>
    );
};

export default Login;
