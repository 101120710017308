import React, {useEffect} from 'react';
import {AlertProvider} from "./common/fieldfactory/alert/AlertContext";
import {BottomNav,TopNav , Sidebar } from './common/fieldfactory/index'
import {CreateWorkout, Home, Login, Nutrition, Search, RunProgram, Settings, Goals} from './pages/index'
import {setIsMobile} from "./common/statemangment/Reducer";
import ProtectedRoute from './common/components/ProtectedRoute'
import {useDispatch, useSelector} from "react-redux";
import Timer from "./pages/runworkout/components/Timer";
import './common/styles/stylesheet.css'

function App() {
    const dispatch = useDispatch();
    const { sideBarOpen, page, authenticated ,showTimer,mobile} = useSelector(state => state.default);
    const darkMode = useSelector(state => state.default.darkMode);
    useEffect(() => {
        document.body.classList.remove('dark-mode', 'red-theme', 'red-dark-mode', 'green-theme', 'green-dark-mode');

        if (darkMode === 'dark') {
            document.body.classList.add('dark-mode');
        } else if (darkMode === 'red') {
            document.body.classList.add('red-theme');
        } else if (darkMode === 'red-dark') {
            document.body.classList.add('red-dark-mode');
        } else if (darkMode === 'green') {
            document.body.classList.add('green-theme');
        } else if (darkMode === 'green-dark') {
            document.body.classList.add('green-dark-mode');
        } else {

        }
    }, [darkMode]);

    useEffect(() => {
        const handleResize = () => {
            dispatch(setIsMobile(window.innerWidth < 701));
        };

        window.addEventListener('resize', handleResize);


        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, [dispatch]);

    const getPageComponent = () => {
        switch (page) {
            case 'GOALS':
                return  <ProtectedRoute>{authenticated ? <Goals /> : <Login />}</ProtectedRoute>;
            case 'NUTRITION':
                return  <ProtectedRoute>{authenticated ? <Nutrition /> : <Login />}</ProtectedRoute>;
            case 'RUNWORKOUT':
                return  <ProtectedRoute>{authenticated ? <RunProgram /> : <Login />}</ProtectedRoute>;
            case 'FINDWORKOUT':
                return  <ProtectedRoute>{authenticated ? <Search /> : <Login />}</ProtectedRoute>;
            case 'CREATEWORKOUT':
                return <ProtectedRoute>{authenticated ? <CreateWorkout /> : <Login />}</ProtectedRoute>;
            case 'SETTINGS':
                return <ProtectedRoute>{authenticated ? <Settings /> : <Login />}</ProtectedRoute>;
            case 'HOME':
                return <ProtectedRoute>{authenticated ? <Home /> : <Login />}</ProtectedRoute>;
            default:
                return <Login />;
        }
    };


    const showSideBar = page!== 'LOGIN' && !mobile ;
    const mobileNavigation = page!== 'LOGIN' && mobile ;

    const mainContentClass = `main-content ${showTimer ? 'timer-visible' : ''}`;
    return (
        <AlertProvider>

            <div className={"app-flex-container"}>
                {mobileNavigation && <TopNav/>}
                {showSideBar && <Sidebar/>} {/* Render Sidebar only when authenticated */}
                <div className={mainContentClass} style={{marginLeft: sideBarOpen && showSideBar ? '215px' : '0'}}>
                    {getPageComponent()}
                </div>
                {showTimer && <Timer/>}
                {mobileNavigation && <BottomNav/>}
            </div>
        </AlertProvider>
    );
}

export default App;
