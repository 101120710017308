import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {VerticalCards} from "../../../common/fieldfactory";
import {useReduxActions} from "../logic/Events";
import CollapsedWorkoutCalendarComponent from "./CollapsedWorkoutCalendarComponent";
import WorkOutCalendarComponent from "./WorkOutCalanderComponent";
import WorkoutComponent from "./WorkoutComponent";

const WorkoutComponentHOC = () => {
    const {mobile} = useSelector(state => state.default);
    const [expandedComponent, setExpandedComponent] = useState(!mobile);
    const {nextWorkout, page} = useSelector(state => state.home);

    const {handleSetPage} = useReduxActions();

    const handleCardClick = () => {
        if (mobile) {
            setExpandedComponent(prevState => !prevState);
            if (expandedComponent) {
                handleSetPage('ALL');
            } else {
                handleSetPage('HISTORY');
            }
        }
    };

    useEffect(() => {
        if (mobile) {
            setExpandedComponent(false);
        } else {
            setExpandedComponent(true);
        }
    }, [mobile]);

    useEffect(() => {
        if (mobile) {
            if (page === 'HISTORY') {
                setExpandedComponent(true);
            } else if (page === 'ALL') {
                setExpandedComponent(false);
            }
        }
    }, [mobile, page]);


    return (<>
            {expandedComponent ? (
                <VerticalCards cards={[
                    <WorkOutCalendarComponent collapseOnClick={handleCardClick} expanded={expandedComponent}/>,
                    <WorkoutComponent/>]}
                />
            ) : (
                <CollapsedWorkoutCalendarComponent handleCardClick={handleCardClick} expandedComponent={expandedComponent} nextWorkout={nextWorkout}/>
            )}
        </>

    )
}

export default WorkoutComponentHOC;