import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Line } from 'react-chartjs-2';
import 'chartjs-adapter-moment';

const OneRepMaxGraph = ({ oneRepMaxesList }) => {
    const [selectedData, setSelectedData] = useState({ date: undefined, value: undefined });
    const { unit } = useSelector(state => state.setting);
    const { mobile } = useSelector(state => state.default);

    const visibleIndex = mobile ? 5 : 7; // Number of visible points at a time

    // Utility function to generate a consistent color based on the max name
    const getColorFromName = (name) => {
        let hash = 0;
        for (let i = 0; i < name.length; i++) {
            hash = name.charCodeAt(i) + ((hash << 5) - hash);
        }
        let color = '#';
        for (let i = 0; i < 3; i++) {
            const value = (hash >> (i * 8)) & 0xFF;
            color += ('00' + value.toString(16)).slice(-2);
        }
        return color;
    };

    const getVisibleDataSets = () => {
        if (!Array.isArray(oneRepMaxesList)) {
            console.error("Expected oneRepMaxesList to be an array, but received:", oneRepMaxesList);
            return [];
        }

        return oneRepMaxesList
            .filter(entry => entry.visible)
            .map(entry => {
                const color = getColorFromName(entry.max);
                return {
                    label: entry.max,
                    data: entry.data.map(item => ({
                        x: item.date,
                        y: parseFloat(item.weight)
                    })),
                    fill: false,
                    borderColor: color,
                    pointBackgroundColor: 'rgba(255, 255, 255, 0.2)',
                    pointBorderColor: color,
                    tension: 0.1,
                    pointHoverRadius: 12,
                    pointRadius: 12,
                    spanGaps: true,
                };
            });
    };

    const datasets = getVisibleDataSets();

    // Calculate min and max Y values for the Y axis
    const allYValues = datasets.flatMap(dataset => dataset.data.map(point => point.y));
    const allMinYValue = Math.floor(Math.min(...allYValues) / 5) * 5;
    const allMaxYValue = Math.ceil(Math.max(...allYValues) / 5) * 5;

    // Handle scrolling logic for data
    const totalDataPoints = datasets.length > 0 ? datasets[0].data.length : 0;
    const [visibleStartIndex, setVisibleStartIndex] = useState(Math.max(totalDataPoints - visibleIndex, 0));

    useEffect(() => {
        setVisibleStartIndex(Math.max(totalDataPoints - visibleIndex, 0));
    }, [totalDataPoints, visibleIndex]);

    const slicedDatasets = datasets.map(dataset => ({
        ...dataset,
        data: dataset.data.slice(visibleStartIndex, visibleStartIndex + visibleIndex)
    }));

    const options = {
        plugins: {
            legend: {
                display: true,
                onClick: null, // Disable the interaction with the legend
                labels: {
                    useLineStyle: true,
                    boxWidth: 20, // Width of the colored box
                    boxHeight: 5, // Height of the colored box (available in Chart.js v3 and later)
                    padding: 5, // Padding around the label
                    generateLabels: (chart) => {
                        const datasets = chart.data.datasets;
                        return datasets.map((dataset, i) => ({
                            text: dataset.label,
                            fillStyle: dataset.borderColor, // Fill the box with the dataset color
                            strokeStyle: dataset.borderColor,
                            lineWidth: 0,
                            index: i,
                        }));
                    },
                },
            },
            tooltip: {
                enabled: false,
            },
        },
        scales: {
            x: {
                type: 'time',
                time: {
                    unit: 'month',
                    tooltipFormat: 'll',
                },
                beginAtZero: true,
                position: 'bottom',
                ticks: {
                    align: 'start',
                    padding: 10,
                    maxRotation: 0,
                    minRotation: 0,
                }
            },
            y: {
                beginAtZero: false,
                position: 'right',
                min: allMinYValue - 20,
                max: allMaxYValue + 20,

            },
        },
        elements: {
            point: {
                radius: 8,
            },
        },
        onClick: (event, chartElement) => {
            if (chartElement.length > 0) {
                const index = chartElement[0].index;
                const selectedDataset = chartElement[0].datasetIndex;
                const selectedValue = slicedDatasets[selectedDataset].data[index].y;
                const selectedLabel = slicedDatasets[selectedDataset].data[index].x;
                setSelectedData({ date: selectedLabel, value: selectedValue });
            }
        },
    };

    // Dragging logic (with touch support)
    const isDragging = useRef(false);
    const dragStartX = useRef(0);
    const dragStartIndex = useRef(visibleStartIndex);

    const handleStart = (clientX) => {
        isDragging.current = true;
        dragStartX.current = clientX;
        dragStartIndex.current = visibleStartIndex;
    };

    const handleMove = (clientX) => {
        if (isDragging.current) {
            const dragDistance = dragStartX.current - clientX;
            const dragIndex = Math.floor(dragDistance / 30); // Adjust sensitivity by changing divisor
            const newIndex = Math.max(0, Math.min(totalDataPoints - visibleIndex, dragStartIndex.current + dragIndex));
            setVisibleStartIndex(newIndex);
        }
    };

    const handleEnd = () => {
        isDragging.current = false;
    };

    const handleMouseDown = (event) => handleStart(event.clientX);
    const handleMouseMove = (event) => handleMove(event.clientX);
    const handleMouseUp = handleEnd;
    const handleMouseLeave = handleEnd;

    const handleTouchStart = (event) => handleStart(event.touches[0].clientX);
    const handleTouchMove = (event) => handleMove(event.touches[0].clientX);

    return (
        <div
            style={{ width: '100%', position: 'relative' }}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseLeave}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleEnd}
        >
            <h5>Drag chart to change visible data</h5>
            <h4>{selectedData.value ? 'Selected Weight - ' + selectedData.value + ' ' + unit : '\u00A0'}</h4>
            <h5>{selectedData.date ? 'Weight recorded on - ' + selectedData.date : '\u00A0'}</h5>
            <div style={{ overflowX: 'hidden', width: '100%' }}>
                <Line data={{ datasets: slicedDatasets }} options={options} height={250} />
            </div>
        </div>
    );
};

export default OneRepMaxGraph;




