import React from "react";
import {useSelector} from "react-redux";
import VerticalCards from "../../common/fieldfactory/card/VerticalCards";
import {TwoCards, Page} from '../../common/fieldfactory/index';
import OneRepMaxProgressComponent from "./components/OneRepMaxProgressComponent";
import WeightComponent from "./components/WeightComponent";
import WorkOutCalendarComponent from "./components/WorkOutCalanderComponent";
import WorkoutComponent from "./components/WorkoutComponent";
import WorkoutComponentHOC from "./components/WorkoutComponentHOC";

const Home = () => {
    const {mobile} = useSelector(state => state.default);
    const cards = mobile ? <WorkoutComponentHOC/> : <TwoCards card1={<VerticalCards cards={[<WorkOutCalendarComponent/>,
        <WeightComponent/>, <OneRepMaxProgressComponent/>]}/>} card2={<WorkoutComponent/>}/>
    const {page} = useSelector(state => state.home);
    const getPageComponent = () => {
        switch (page) {
            case 'ALL':
                return <>
                    {cards}
                    {mobile && <>
                        <WeightComponent/>
                        <OneRepMaxProgressComponent/>
                    </>}
                </>;
            case 'HISTORY':
                return <>{cards}</>;
            case 'WEIGHT':
                return <WeightComponent/>;
            case 'ONEREPMAX' :
                return <OneRepMaxProgressComponent/>;
            default:
                return <></>;
        }
    }

    const pageHeader = page === 'ALL' ? 'Home': page === 'HISTORY' ? 'Workout schedule' : page === 'WEIGHT' ? 'Weight' : 'One rep max progress';
    return (
        <Page header={pageHeader}>
            {getPageComponent()}
        </Page>
    );
}

export default Home;