import React, {useEffect, useRef, useState} from 'react';
import {Line} from 'react-chartjs-2';
import 'chart.js/auto';
import {useSelector} from "react-redux";

const WeightGraph = ({xScale, weightData}) => {
    const [selectedData, setSelectedData] = useState({date: undefined, value: undefined});
    const {darkMode} = useSelector(state => state.default);
    const {unit} = useSelector(state => state.setting);
    const {mobile} = useSelector(state => state.default);
    const {xscale0, xscale1, xscale2, xscale3} = weightData;
    const [primaryColor, setPrimaryColor] = useState('');

    useEffect(() => {
        const color = getComputedStyle(document.body).getPropertyValue('--primary-color').trim();
        setPrimaryColor(color);
    }, [darkMode]);

    const visibleIndex = mobile ? 5 : 7;

    const processLabelsAndData = () => {
        let labels;
        let data;

        switch (xScale) {
            case 0: // Weekly data (xScale0)
                labels = xscale0.map(item => item.date);
                data = xscale0.map(item => parseFloat(item.weight));
                break;
            case 1: // Bi-weekly data (xScale1)
                labels = xscale1.map(item => item.date);
                data = xscale1.map(item => parseFloat(item.weight));
                break;
            case 2: // Monthly data (xScale2)
                labels = xscale2.map(item => item.date);
                data = xscale2.map(item => parseFloat(item.weight));
                break;
            case 3: // Bi-monthly data (xScale3)
                labels = xscale3.map(item => item.date);
                data = xscale3.map(item => parseFloat(item.weight));
                break;
            default:
                labels = [];
                data = [];
                break;
        }

        return {labels, data};
    };

    const fullProcessedData = processLabelsAndData();
    const totalDataPoints = fullProcessedData.labels.length;

    const [visibleStartIndex, setVisibleStartIndex] = useState(Math.max(totalDataPoints - visibleIndex, 0));

    useEffect(() => {
        setSelectedData({date: undefined, value: undefined});
        setVisibleStartIndex(Math.max(totalDataPoints - visibleIndex, 0));
    }, [xScale, totalDataPoints, visibleIndex]);

    const slicedLabels = fullProcessedData.labels.slice(visibleStartIndex, visibleStartIndex + visibleIndex);
    const slicedData = fullProcessedData.data.slice(visibleStartIndex, visibleStartIndex + visibleIndex);

    const allYValues = fullProcessedData.data.filter(value => value !== null && value !== undefined && !isNaN(value));
    const allMinYValue = Math.floor(Math.min(...allYValues) / 5) * 5;
    const allMaxYValue = Math.ceil(Math.max(...allYValues) / 5) * 5;

    const data = {
        labels: slicedLabels,
        datasets: [
            {
                label: '',
                data: slicedData,
                fill: false,
                borderColor: primaryColor,
                pointBackgroundColor: 'rgba(255, 255, 255, 0.2)',
                pointBorderColor: primaryColor,
                tension: 0.1,
                pointHoverRadius: 12,
                pointRadius: 12,
                spanGaps: true,
            },
        ],
    };

    const options = {
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                enabled: false,
            },
        },
        scales: {
            x: {
                beginAtZero: true,
                position: 'bottom',
                ticks: {
                    align: 'start',
                    padding: 10,
                    maxRotation: 0,
                    minRotation: 0,
                    callback: function (value, index, values) {
                        const label = this.getLabelForValue(value);
                        if (index === values.length - 1) {
                            return '';
                        }
                        return label;
                    },
                }
            },
            y: {
                beginAtZero: false,
                position: 'right',
                min: allMinYValue - 5,
                max: allMaxYValue + 5,
                ticks: {
                    stepSize: 2.5,
                },
            },
        },
        elements: {
            point: {
                radius: 8,
            },
        },
        onClick: (event, chartElement) => {
            if (chartElement.length > 0) {
                const index = chartElement[0].index;
                const selectedValue = slicedData[index];
                const selectedLabel = slicedLabels[index];
                setSelectedData({date: selectedLabel, value: selectedValue});
            }
        },
    };

    // Dragging logic (with touch support)
    const isDragging = useRef(false);
    const dragStartX = useRef(0);
    const dragStartIndex = useRef(visibleStartIndex);

    const handleStart = (clientX) => {
        isDragging.current = true;
        dragStartX.current = clientX;
        dragStartIndex.current = visibleStartIndex;
    };

    const handleMove = (clientX) => {
        if (isDragging.current) {
            const dragDistance = dragStartX.current - clientX;
            const dragIndex = Math.floor(dragDistance / 30);
            const newIndex = Math.max(0, Math.min(totalDataPoints - visibleIndex, dragStartIndex.current + dragIndex));
            setVisibleStartIndex(newIndex);
        }
    };

    const handleEnd = () => {
        isDragging.current = false;
    };

    const handleMouseDown = (event) => handleStart(event.clientX);
    const handleMouseMove = (event) => handleMove(event.clientX);
    const handleMouseUp = handleEnd;
    const handleMouseLeave = handleEnd;

    const handleTouchStart = (event) => handleStart(event.touches[0].clientX);
    const handleTouchMove = (event) => handleMove(event.touches[0].clientX);
    return (
        <div
            style={{width: '100%', position: 'relative'}}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseLeave}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleEnd}
        >
            <h5>Drag chart to change visible data</h5>
            <h4>{selectedData.value ? xScale === 0 ?'Selected Weight - '+ selectedData.value + ' ' + unit : 'Average Weight - '+ selectedData.value + ' ' + unit : '\u00A0'}</h4>
            <h5> {selectedData.date ? xScale === 0 ? 'Weight recorded on - '+selectedData.date : 'Weight average for - '+selectedData.date : '\u00A0'}</h5>
            <div style={{overflowX: 'hidden', width: '100%'}}>
                <Line data={data} options={options} height={200}/>
            </div>
        </div>
    );
};

export default WeightGraph;
