import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    name: '',
    description: '',
    oneRepMaxList: [{'label':'','value':''}],
    weeks: [
    ],
    weekIndex: -1,
    dayIndex: -1,
    exerciseIndex: -1,
    pageState: '',
    linkedExercise: '',
    linkedExerciseName: '',
    workOutSelected: '',
    workOutList: [],
    repList: [],
    roundValueList: [],
    unitList: [],
};

export const createWorkoutSlice = createSlice({
    name: 'createWorkout',
    initialState,
    reducers: {
        setWorkoutName: (state, action) => {
            state.name = action.payload;
        },
        setWorkoutDescription: (state, action) => {
            state.description = action.payload;
        },
        addDay: (state, action) => {
            const {newDay, weekIndex} = action.payload;
            if (state.weeks[weekIndex]) {
                state.weeks[weekIndex].days.push({name: newDay, exercises: []});
            }
        },
        addWeek: (state, action) => {
            state.weeks.push({name: action.payload, days: []});
        },
        duplicateWeek: (state, action) => {
            const currentWeek = action.payload;
            state.weeks.push(currentWeek);
        },
        deleteDay: (state, action) => {
            const {dayIndex, weekIndex} = action.payload;
            if (state.weeks[weekIndex].days[dayIndex]) {
                state.weeks[weekIndex].days = state.weeks[weekIndex].days.filter((_, index) => index !== dayIndex);
            }

        },
        deleteWeek: (state, action) => {
            const weekIndex = action.payload;
            if (state.weeks[weekIndex]) {
                state.weeks = state.weeks.filter((_, index) => index !== weekIndex);

            }
        },
        addExercise: (state, action) => {
            const {dayIndex, exercise, weekIndex} = action.payload;
            if (state.weeks[weekIndex].days[dayIndex]) {
                state.weeks[weekIndex].days[dayIndex].exercises.push(exercise);
            }
        },
        updateExercise: (state, action) => {
            const {dayIndex, exerciseIndex, exercise, weekIndex} = action.payload;
            state.weeks[weekIndex].days[dayIndex].exercises[exerciseIndex] = {
                ...state.weeks[weekIndex].days[dayIndex].exercises[exerciseIndex],
                ...exercise
            };
        },
        addLinkedExercise: (state, action) => {
            const {
                weekIndex,
                dayIndex,
                exerciseIndex,
                week,
                day,
                exercise
            } = action.payload;
            state.weeks[weekIndex].days[dayIndex].exercises[exerciseIndex] = {
                ...state.weeks[weekIndex].days[dayIndex].exercises[exerciseIndex],
                oneRepMaxLink: '',
                linkedExerciseName : state.weeks[week].days[day].exercises[exercise].name
            };
        },
        deleteExercise: (state, action) => {
            const { dayIndex, exerciseIndex, weekIndex } = action.payload;


            if (state.weeks[weekIndex].days[dayIndex]) {
                state.weeks[weekIndex].days[dayIndex].exercises =
                    state.weeks[weekIndex].days[dayIndex].exercises.filter((_, index) => index !== exerciseIndex);
            }


            state.weeks.forEach((week) => {
                week.days.forEach((day) => {
                    day.exercises.forEach((exercise) => {
                        const linked = exercise.linkedExercise;
                        if (linked && linked.week === weekIndex && linked.day === dayIndex && linked.exercise === exerciseIndex) {

                            exercise.linkedExercise = null;
                        }
                    });
                });
            });
        },
        setPageState: (state, action) => {
            state.pageState = action.payload;
        },
        setExercise: (state, action) => {
            const {dayIndex, exerciseIndex, weekIndex} = action.payload;
            state.dayIndex = dayIndex;
            state.exerciseIndex = exerciseIndex;
            state.weekIndex = weekIndex;
        },
        setWeekIndex: (state, action) => {
            state.dayIndex = 0;
            state.weekIndex = action.payload;
        },
        setDayIndex: (state, action) => {
            state.dayIndex = action.payload;
        },
        setChangeWorkout: (state, action) => {
            const workout = action.payload;
            state.name = workout.name;
            state.workOutSelected = workout.name;
            state.weeks = workout.weeks;
            state.description = workout.description;
            state.dayIndex = 0;
            state.exerciseIndex = 0;
            state.weekIndex = 0;
            state.oneRepMaxList = workout.oneRepMaxList === undefined ? [] : workout.oneRepMaxList;
        },
        setDropDownLists: (state, action) => {
            const dropDownLists = action.payload;
            state.workOutList = dropDownLists.programList;
            state.repList = dropDownLists.repetitionList;
            state.roundValueList = dropDownLists.roundValueList;
            state.pageState = '';
        },
        setUnitList: (state,action) => {
            state.unitList = action.payload
        },
        setWorkOutSelected: (state, action) => {
            state.workOutSelected = action.payload;
        },
        setNewWorkout: (state) => {
            state.name = '';
            state.workOutSelected = '';
            state.weeks = [];
            state.description = '';
            state.dayIndex = 0;
            state.exerciseIndex = 0;
            state.weekIndex = 0;
            state.oneRepMaxList = [];
        },
        setCloseModal(state) {
            state.linkedExercise = '';
            state.pageState = '';
        },
        editDayName(state, action) {
            state.weeks[state.weekIndex].days[state.dayIndex].name = action.payload;
        },
        editWeekName(state, action) {
            state.weeks[state.weekIndex].name = action.payload;
        },
        addOneRepMax(state) {
            state.oneRepMaxList = [...state.oneRepMaxList, {
                label: '',
                value: ''
            }];
        },
        updateOneRepMaxName (state, action) {
            const {index, value } = action.payload;
            state.oneRepMaxList = state.oneRepMaxList.map((item, idx) => {
                if (idx === index) {
                    state.weeks.forEach((week) => {
                        week.days.forEach((day) => {
                            day.exercises.forEach((exercise) => {
                                const oneRepMaxLink = exercise.oneRepMaxLink;
                                if (item.value === oneRepMaxLink) {
                                    exercise.oneRepMaxLink = value;
                                }
                            });
                        });
                    });


                    return { ...item, label: value ,
                        value: value};
                }

                return item;
            });
        },
        deleteOneRepMax (state, action) {
            const indexToDelete = action.payload;
            const oRM = state.oneRepMaxList[indexToDelete].value;
            state.oneRepMaxList = state.oneRepMaxList.filter((item, index) => index !== indexToDelete);
            state.weeks.forEach((week) => {
                week.days.forEach((day) => {
                    day.exercises.forEach((exercise) => {
                        const oneRepMaxLink = exercise.oneRepMaxLink;
                        if (oRM === oneRepMaxLink) {
                            exercise.intensity = null;
                            exercise.oneRepMaxLink = null;
                        }
                    });
                });
            });
        },
    },
});


export const {
    deleteOneRepMax,
    updateOneRepMaxName,
    addOneRepMax,
    setWorkOutSelected,
    setDropDownLists,
    setWeekIndex,
    setDayIndex,
    deleteDay,
    deleteExercise,
    setWorkoutName,
    setWorkoutDescription,
    addDay,
    addWeek,
    duplicateWeek,
    addExercise,
    updateExercise,
    setPageState,
    deleteWeek,
    setExercise,
    setChangeWorkout,
    setNewWorkout,
    setCloseModal,
    addLinkedExercise,
    editDayName,
    editWeekName,
    setUnitList
} = createWorkoutSlice.actions;


export default createWorkoutSlice.reducer;

