import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Card, CheckBox} from '../../../common/fieldfactory/index';
import {useReduxActions} from "../logic/Events";
import {updateOneRepMaxesListVisibility} from "../logic/Reducer";
import OneRepMaxGraph from "./linegraph/OneRepMaxGraph";

const OneRepMaxProgressComponent = () => {
    const {handleSetPage} = useReduxActions();
    const {page,oneRepMaxesList} = useSelector(state => state.home);
    const dispatch = useDispatch();
    const {mobile} = useSelector(state => state.default);
    const [expandedComponent, setExpandedComponent] = useState(!mobile);
    const rowSize = mobile ? 2 : 4;
    const {unit} = useSelector(state => state.setting);
    const handleCheckboxChange = (checked, index) => {
        dispatch(updateOneRepMaxesListVisibility({index, checked}));
    };


    // Splitting the buttons into groups
    const rows = [];
    for (let i = 0; i < oneRepMaxesList.length; i += rowSize) {
        rows.push(oneRepMaxesList.slice(i, i + rowSize));
    }

    const handleCardClick = () => {
        if (mobile) {
            setExpandedComponent(prevState => !prevState);
            if (expandedComponent) {
                handleSetPage('ALL');
            } else {
                handleSetPage('ONEREPMAX');
            }
        }
    };

    useEffect(() => {
        if (mobile) {
            setExpandedComponent(false);
        } else {
            setExpandedComponent(true);
        }
    }, [mobile]);

    useEffect(() => {
        if (mobile) {
            if (page === 'ONEREPMAX') {
                setExpandedComponent(true);
            } else if (page === 'ALL') {
                setExpandedComponent(false);
            }
        }
    }, [mobile, page]);

    const cardHeader = page === 'ONEREPMAX' ? 'Progress graph' : 'One rep maxes';

    return (
        <Card header={cardHeader}
              onClick={mobile && !expandedComponent ? handleCardClick : null}
              expandable={true}
              expanded={expandedComponent}
              collapseOnClick={handleCardClick}
        >
            {expandedComponent ? (<>
                {
                    rows.map((row, rIndex) => (
                        <div key={rIndex} className="flex-row-container"
                             style={row.length !== rowSize && mobile ? {
                                 justifyContent: 'flex-start',
                                 marginLeft: '5px'
                             } : {}}>
                            {row.map((oneRep, index) => (
                                <CheckBox
                                    key={`${rIndex}-${index}`}
                                    label={oneRep.max}
                                    // label={index}
                                    checked={oneRep.visible}
                                    onChange={(e) => handleCheckboxChange(e, (rIndex * rowSize) + index)}
                                />))}
                        </div>

                    ))
                }
                <OneRepMaxGraph oneRepMaxesList={oneRepMaxesList}/>
            </>) : (
                <>
                    {
                        oneRepMaxesList.map((oneRepMax, index) => (
                            <div key={index} style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                <h4 style={{marginBottom: 0}}>{oneRepMax.max}</h4>
                                <h5 style={{marginBottom: 0}}>
                                    {oneRepMax.data.length > 0 ? oneRepMax.data[oneRepMax.data.length - 1].weight : 'No data available'} {unit}
                                </h5>
                            </div>
                        ))
                    }
                </>
            )}
        </Card>
    )
}

export default OneRepMaxProgressComponent;