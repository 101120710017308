import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

const ButtonRow = ({ buttons, label, onClick, buttonSelected ,setButtonPerRow }) => {
    const [state, setState] = useState(-1); // Default state is -1
    const buttonRefs = useRef([]);
    const { mobile } = useSelector(state => state.default);
    const numberOfButtonsPerRow = setButtonPerRow ? setButtonPerRow : mobile ? 5 : 7;
    const labelCut = mobile ? label.length > 2 ? label.substring(0,1) :label : label;
    // Set initial state based on buttonSelected prop
    useEffect(() => {
        if (buttonSelected !== undefined) {
            setState(buttonSelected);
        }
    }, [buttonSelected]);

    // Function to handle button click
    const handleButtonClick = (index) => {
        setState(index); // Update state
        if (onClick && typeof onClick === 'function') {
            onClick(index); // Directly call onClick with the index
        }
    };

    // Splitting the buttons into groups
    const rows = [];
    for (let i = 0; i < buttons.length; i += numberOfButtonsPerRow) {
        rows.push(buttons.slice(i, i + numberOfButtonsPerRow));
    }

    return (
        <div className="button-row-container mt-10 mb-10">
            {rows.map((row, rowIndex) => (
                <div key={rowIndex} className="button-row" data-testid="button-row">
                    {row.map((buttonObj, index) => {
                        // Calculate the global index
                        const globalIndex = rowIndex * numberOfButtonsPerRow + index;

                        // Determine the label for each button
                        const buttonLabel = buttonObj.label ? buttonObj.label : `${labelCut} ${globalIndex + 1}`;

                        return (
                            <button
                                key={globalIndex}
                                ref={el => (buttonRefs.current[globalIndex] = el)}
                                className={`button-row-button ${state === globalIndex ? 'active' : ''}`}
                                onClick={() => handleButtonClick(globalIndex)}
                            >
                                {buttonLabel}
                            </button>
                        );
                    })}
                </div>
            ))}
        </div>
    );
};

export default ButtonRow;
