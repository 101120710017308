import React from 'react';
import DoughnutGraph from "../../../common/fieldfactory/graphs/DoughnutGraph";
import HorizontalBarGraph from "../../../common/fieldfactory/graphs/HorizontalBarGraph";

const GoalDoughnutGraph = (props) => {
    const {
        mobile,
        progress,
        valueDisplay,
        doughnutGraphSize,
        graphSize,
        formatedGoalAtSetting,
        unit,
        formatedCurrent,
        formatedGoal,
        xScale,
        dataSet,
        labels,
        doughnutLabel,
        barGraphTitle
    } = props;
    const cutoutSize = mobile ? '80%' : '85%';
    const barSize = {width: mobile ? '332px' : '1168px', height: graphSize};
    return (
        <>
            <div style={{
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center',
                width: '100%',
                marginTop: '10px',
                marginBottom: '5px'
            }}>
                <DoughnutGraph goal={100} used={progress} cutoutSize={cutoutSize}
                               graphSize={doughnutGraphSize}
                               usedColour={'rgb(54, 162, 235)'} text={valueDisplay}/>
                <div style={{width: '55%', height: '100%'}}>
                    <div
                        style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <h4 style={{marginBottom: '10px', marginTop: '10px'}}>Initial {doughnutLabel}</h4>
                        <h5 style={{marginBottom: '10px', marginTop: '10px'}}>
                            {formatedGoalAtSetting} {unit}
                        </h5>
                    </div>
                    <div
                        style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <h4 style={{marginBottom: '10px', marginTop: '10px'}}>Current {doughnutLabel}</h4>
                        <h5 style={{marginBottom: '10px', marginTop: '10px'}}>
                            {formatedCurrent} {unit}
                        </h5>
                    </div>
                    <div
                        style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <h4 style={{marginBottom: '10px', marginTop: '10px'}}>Goal {doughnutLabel}</h4>
                        <h5 style={{marginBottom: '10px', marginTop: '10px'}}>
                            {formatedGoal} {unit}
                        </h5>
                    </div>
                </div>
            </div>

            <h5 style={{marginBottom: 0}}>{barGraphTitle}</h5>
            <HorizontalBarGraph labels={labels} graphSize={barSize} xScale={xScale}
                                dataSet={dataSet}/>
        </>

    )
}

export default GoalDoughnutGraph;