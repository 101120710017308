import React from 'react';

const DumbbellFilled = ({className}) => (
    <svg className={className} viewBox="0 0 395 300" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="321" y="295" width="31" height="290" rx="5" transform="rotate(180 321 295)" fill="currentColor"
              stroke="currentColor" strokeWidth="10"/>
        <rect x="368" y="245" width="29" height="190" rx="5" transform="rotate(180 368 245)" fill="currentColor"
              stroke="currentColor" strokeWidth="10"/>
        <path d="M395 150C395 155.523 390.523 160 385 160H377V140H385C390.523 140 395 144.477 395 150V150Z"
              fill="currentColor"/>
        <rect x="74" y="5" width="31" height="290" rx="5" fill="currentColor" stroke="currentColor" strokeWidth="10"/>
        <rect x="27" y="55" width="29" height="190" rx="5" fill="currentColor" stroke="currentColor" strokeWidth="10"/>
        <path d="M0 150C0 144.477 4.47715 140 10 140H18V160H10C4.47715 160 0 155.523 0 150V150Z" fill="currentColor"/>
        <rect x="110" y="140" width="175" height="20" fill="currentColor"/>
    </svg>
);

export default DumbbellFilled;
