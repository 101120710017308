import React from 'react';
import {useSelector} from "react-redux";
import {Page} from '../../common/fieldfactory/index';
import FrozenMeals from "./components/FrozenMealComponent";
import {CookingComponent, FoodLogComponent, GeneratingComponent, ShoppingListComponent} from "./components/index";
import BasketLoader from "./components/loaders/BasketLoader";
import PlanLoader from "./components/loaders/PlanLoader";

const Nutrition = () => {
    const {
        page,
        shoppingList,
        cookingRecipes,
        generating
    } = useSelector(state => state.nutrition);
    const {
        notInBasket,
        inBasket
    } = shoppingList;
    const showShoppingList = notInBasket.length !== 0 || inBasket.length !== 0;
    const generationAllowed = cookingRecipes.recipes.length === 0;
    const getPageComponent = () => {
        switch (page) {
            case 'ALL':
                return <>
                    <FoodLogComponent/>
                    {showShoppingList && <ShoppingListComponent/>}
                    {!generationAllowed && <CookingComponent/>}
                    {generationAllowed && <GeneratingComponent/>}
                    {generating && <><BasketLoader/><PlanLoader/></>}
                    <FrozenMeals/>
                </>;
            case 'COOKING':
                return <CookingComponent/>;
            case 'SHOPPING':
                return <ShoppingListComponent/>;
            case 'GENERATING':
                return <GeneratingComponent/>;
            case 'LOGGING':
                return <FoodLogComponent/>;
            default:
                return <></>;
        }
    };
    const pageHeader = page === 'ALL' ? 'Nutrition': page === 'COOKING' ? 'Cooking instructions' : page === 'SHOPPING' ? 'Shopping' : page === 'GENERATING' ? 'Meal generation' :'Food logging';
    return (
        <Page header={pageHeader}>
            {getPageComponent()}
        </Page>
    );
}

export default Nutrition;