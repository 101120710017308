import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    weightGoal: '',
    goalWeight: '',
    page: 'ALL',
    exerciseGoalOpen: '',
    weightAtGoalSetting: '',
    weightGoalDropDown: [],
    oneRepMaxGoals: [],
    goalTypeDropDown: [],
    oneRepMaxesWithNoGoal: [],
    newGoal: {}
};

const goalSlice = createSlice({
    name: 'goal',
    initialState,
    reducers: {
        setExerciseGoalOpen: (state, action) => {
            state.page = 'EXERCISEGOAL';
            state.exerciseGoalOpen = action.payload;
        },
        setGoalWeight: (state, action) => {
            state.goalWeight = action.payload;
        },
        setGoals: (state, action) => {
            state.weightGoal = action.payload;
        },
        loadGoals: (state, action) => {
            const {
                goalWeight,
                weightGoal,
                goalDropDown,
                weightAtGoalSetting,
                oneRepMaxGoals,
                goalTypeDropDown,
                oneRepMaxesWithNoGoal
            } = action.payload;
            state.goalWeight = goalWeight;
            state.weightGoal = weightGoal;
            state.weightGoalDropDown = goalDropDown;
            state.weightAtGoalSetting = weightAtGoalSetting;
            state.oneRepMaxGoals = oneRepMaxGoals;
            state.goalTypeDropDown = goalTypeDropDown;
            state.oneRepMaxesWithNoGoal = oneRepMaxesWithNoGoal;
        },
        setPage: (state, action) => {
            state.page = action.payload;
            state.exerciseGoalOpen = '';
            state.newGoal = {
                goalType: '',
                goalNameValue: '',
                goalValue: ''
            }
        },
        setEditGoal: (state, action) => {
            const {goalType, goalNameValue, goalValue} = action.payload;
            state.newGoal.goalType = goalType;
            state.newGoal.goalNameValue = goalNameValue;
            state.newGoal.goalValue = goalValue;
        },
        setCancelEdit: (state) => {
            state.newGoal = {
                goalType: '',
                goalNameValue: '',
                goalValue: ''
            }
        },
        setGoalType: (state, action) => {
            state.newGoal.goalType = action.payload;
            state.newGoal.goalNameValue = '';
            state.newGoal.goalValue = '';
        },
        setGoalValue: (state, action) => {
            state.newGoal.goalNameValue = action.payload;
        },
        setOneRepMaxValue: (state, action) => {
            state.newGoal.goalValue = action.payload;
        },
    }
});

export const {
    setExerciseGoalOpen,
    setEditGoal,
    setCancelEdit,
    setOneRepMaxValue,
    setGoalValue,
    setGoalType,
    setPage,
    setGoals,
    loadGoals,
    setGoalWeight
} = goalSlice.actions;

export default goalSlice.reducer;