import React from 'react';
import {Card} from '../../../common/fieldfactory/index';

const NoProgramComponent = () => {
    return(
        <Card header={'No workout found'}>
            <p>Currently you have no active workout please go to settings to set current workout or create an workout and set it to active.</p>
        </Card>
    )
}

export default NoProgramComponent;