import {setAuthorization, setNonAuthorized} from "../statemangment/Reducer";

/**
 * Performs an API call to the specified endpoint.
 * @param {string} endpoint - The endpoint to call.
 * @param {string} method - The HTTP method to use (e.g., 'GET', 'POST').
 * @param {Object} [body] - The JSON body to send with the request.
 * @param {Object} [headers] - Additional headers for the request.
 * @param {Object} queryParams - Url Query parameters
 * @param dispatch - dispatch to allow updating jwt
 * @param {Object} [queryParams] - Query parameters to append to the endpoint.
 * @returns {Promise<any>} - The JSON response or an error object.
 */
const callApi = async (endpoint, method = 'GET', body = null, headers = {}, queryParams = {},dispatch) => {

    const queryString = new URLSearchParams(queryParams).toString();
    const url = queryString ? `https://api.fitlifes.co.uk/gateway/fit/service${endpoint}?${queryString}` : `https://api.fitlifes.co.uk/gateway/fit/service${endpoint}`;
    const options = {
        method,
        headers: {
            'Content-Type': 'application/json',
            ...headers,
        },
        body: body ? JSON.stringify(body) : null,
    };

    try {
        const response = await fetch(url, options);


        let responseData;
        try {
            responseData = await response.json();
        } catch (error) {

            responseData = null;
        }

        if (!response.ok) {

            console.error("API Error:", {status: response.status, responseData});
            if(response.status === 403){

                if(dispatch !== undefined) dispatch(setNonAuthorized());
            }
            return {
                error: true,
                status: response.status,
                message: responseData?.message || "An error occurred",
                details: responseData?.details || {},
            };
        }
        let authorization = '';
        if (response.headers.has('Authorization')) {
            authorization = response.headers.get('Authorization');
            if(dispatch !== undefined) dispatch(setAuthorization(authorization));
            console.log('Authorization header refresh completed');
        } else {
            console.log('Authorization header refresh not completed');
        }

        if (Array.isArray(responseData)) {

            return { responseArray: responseData, error: false,authorization:authorization };
        } else {

            return { ...responseData, error: false,authorization:authorization };
        }
    } catch (networkError) {
        console.error('Network error:', networkError);

        // Handle network error or fetch failure
        return {
            error: true,
            status: 'NETWORK_ERROR',
            message: 'Failed to fetch data. Please check your network connection.',
            details: {},
        };
    }
};

export default callApi;
