import React, {createContext, useContext, useState} from 'react';
import Alert from "./Alert";

const AlertContext = createContext();

export const useAlert = () => useContext(AlertContext);

export const AlertProvider = ({ children }) => {
    const [alertQueue, setAlertQueue] = useState([]);
    const [resolveAlert, setResolveAlert] = useState(null);

    // Show an alert by adding it to the queue and return a promise
    const showAlert = (msg, buttons = []) => {
        return new Promise((resolve) => {
            const newAlert = {
                message: msg,
                buttons: buttons,
                id: new Date().getTime(), // Unique ID for key purposes
            };
            setAlertQueue(prev => [...prev, newAlert]);
            setResolveAlert(() => resolve);
        });
    };

    // Hide the currently shown alert and resolve the promise
    const hideAlert = () => {
        setAlertQueue(prev => prev.slice(1)); // Remove the first alert from the queue
        if (resolveAlert) {
            resolveAlert();
            setResolveAlert(null);
        }
    };

    const currentAlert = alertQueue[0] || null;

    return (
        <AlertContext.Provider value={{ showAlert, hideAlert }}>
            {children}
            {currentAlert && (
                <Alert
                    count={alertQueue.length}
                    key={currentAlert.id}
                    message={currentAlert.message}
                    isOpen={true}
                    onClose={hideAlert}
                    buttons={currentAlert.buttons}
                />
            )}
        </AlertContext.Provider>
    );
};



