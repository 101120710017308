import React from "react";
import {useSelector} from "react-redux";
import {Button, Card} from "../../../common/fieldfactory";
import {useReduxActions} from "../logic/Events";

const AcceptMealComponent = () => {
    const {accepted, awaitingAcceptance} = useSelector(state => state.nutrition.generatedMealPlan);
    const {handleRemoveMeal, handleAcceptMealPlan} = useReduxActions();
    if (Object.entries(accepted).length === 0) {
        return;
    }
    return (
        <Card header={'Accepted meals'}>
            {accepted.map((meal, index) => (
                <div key={index} className={'set-log-item-container'}>
                    {index !== 0 && <div className={'line'}></div>}
                    <h4>{meal.name}</h4>
                    <h5>Description:</h5>
                    <p>{meal.description}</p>
                    <div className="button-container-right mb-10 mt-10">
                        <Button label="Remove meal" className="button button-tertiary mt-10 half-width"
                                onClick={() => handleRemoveMeal(meal.name)}/>
                    </div>
                </div>))}
            {awaitingAcceptance.length === 0 &&
                <Button label="Accept meal plan" className="button"
                        onClick={handleAcceptMealPlan}/>
            }
        </Card>
    )
}

export default AcceptMealComponent;