import React,{ useState } from 'react';
import {useSelector} from "react-redux";
import {Card} from "../../../common/fieldfactory";
import {useReduxActions} from "../logic/Events";

const DatePickerComponent = ({expandedComponent, handleCardClick}) => {
    const {mobile} = useSelector(state => state.default);

    return (
        <Card header={'Nutrition calendar'}
              onClick={mobile && !expandedComponent ? handleCardClick : null}
              expandable={true}
              expanded={expandedComponent}
              collapseOnClick={handleCardClick}
        >
            <WorkoutCalendar/>
        </Card>
    )
}

export default DatePickerComponent;



const WorkoutCalendar = () => {
    const { handleSetSelectedDate } = useReduxActions();
    const {selectedDate} = useSelector(state => state.nutrition);
    // Days of the week (static), starting with Monday
    const daysOfWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

    // State to manage the start of the current week
    const [startOfWeek, setStartOfWeek] = useState(() => {
        const today = new Date();
        const dayOfWeek = today.getDay();
        return new Date(today.setDate(today.getDate() - (dayOfWeek === 0 ? 6 : dayOfWeek - 1)));
    });

    // Function to check custom highlighting for a specific date
    const getDayHighlightClass = (date) => {
        const today = new Date();
        if (today.toLocaleDateString('en-CA') === date.toLocaleDateString('en-CA')) {
            return 'react-calendar__tile mt-5 react-calendar__tile--now';
        }
        if(selectedDate === date.toLocaleDateString('en-CA')){
            return 'react-calendar__tile react-calendar__tile--active mt-5';
        }

        return 'react-calendar__tile mt-5'; // Default class for non-highlighted days
    };

    // Function to generate the dates for the current week (starting from Monday)
    const weekDates = Array.from({ length: 7 }, (_, i) => {
        const dayDate = new Date(startOfWeek);
        dayDate.setDate(dayDate.getDate() + i);
        return dayDate;
    });

    // Handle moving the week forward
    const handleNextWeek = () => {
        setStartOfWeek((prevDate) => {
            const nextWeek = new Date(prevDate);
            nextWeek.setDate(nextWeek.getDate() + 7);
            return nextWeek;
        });
    };

    // Handle moving the week backward
    const handlePreviousWeek = () => {
        setStartOfWeek((prevDate) => {
            const prevWeek = new Date(prevDate);
            prevWeek.setDate(prevWeek.getDate() - 7);
            return prevWeek;
        });
    };

    return (
        <>
            <div className="react-calendar__navigation">
                <button
                    aria-label="Previous week"
                    className="react-calendar__navigation__arrow react-calendar__navigation__prev-button"
                    type="button"
                    onClick={handlePreviousWeek} // Move to the previous week
                >
                    ‹
                </button>
                <button
                    aria-label="Current month"
                    className="react-calendar__navigation__label"
                    type="button"
                    style={{ flexGrow: 1 }}
                >
                    <span className="react-calendar__navigation__label__labelText">
                        <h3>
                            {startOfWeek.toLocaleString('en-CA', { month: 'long', year: 'numeric' })}
                        </h3>
                    </span>
                </button>
                <button
                    aria-label="Next week"
                    className="react-calendar__navigation__arrow react-calendar__navigation__next-button"
                    type="button"
                    onClick={handleNextWeek} // Move to the next week
                >
                    ›
                </button>
            </div>
            <div style={{ display: 'flex', padding: '10px 0 0 0' }}>
                {weekDates.map((date, index) => (
                    <div key={index} style={{ textAlign: 'center' }}>
                        <div style={{ textDecoration: 'underline dotted' }}>
                            {daysOfWeek[(date.getDay() + 6) % 7]} {/* Shift day labels so Monday is the first */}
                        </div>
                        <div
                            className={getDayHighlightClass(date)}
                            style={{ width: '47.5px', cursor: 'pointer' }}
                            onClick={() => handleSetSelectedDate(date)} // Add onClick handler
                        >
                            <div style={{ fontSize: '13px' }}>{date.getDate()}</div>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
};
