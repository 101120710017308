import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

const ProgressBar = ({progress, height, color, backgroundColor}) => {
    const [animatedProgress, setAnimatedProgress] = useState(0);
    useEffect(() => {
        const timeout = setTimeout(() => {
            setAnimatedProgress(Math.min(Math.max(progress, 0), 100)); // Clamp progress between 0 and 100
        }, 100); // Small delay to ensure animation starts smoothly

        return () => clearTimeout(timeout);
    }, [progress]);

    const containerStyle = {
        width: '100%',
        height: height || '20px',
        backgroundColor: backgroundColor || '#e0e0e0', // Default background color
        borderRadius: '5px',
        overflow: 'hidden',
        marginTop: '10px'
    };

    const progressStyle = {
        width: `${animatedProgress}%`,
        height: '100%',
        backgroundColor: color || '#4caf50', // Default bar color
        borderRadius: '5px',
        transition: 'width 0.5s ease',
    };

    return (
        <div style={containerStyle}>
            <div style={progressStyle}/>
        </div>
    );
};

ProgressBar.propTypes = {
    progress: PropTypes.number.isRequired, // Progress value (0 to 100)
    height: PropTypes.string, // Height of the progress bar
    color: PropTypes.string, // Color of the progress fill
    backgroundColor: PropTypes.string, // Color of the background
};

export default ProgressBar;
