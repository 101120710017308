import React, {useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getDynamicInitialSetState, initialSetState} from "../logic/Events";
import {createExerciseBaseLogs} from "../logic/Reducer";
import DynamicRepTypeComponent from "./DynamicRepTypeComponent";
import SetCompletionComponent from "./SetCompletionComponent";
import StandardRepTypeComponent from "./StandardRepTypeComponent";
import {Card} from '../../../common/fieldfactory/index';

const RunExerciseComponent = ({exercise, index, finalIndex = false}) => {
    const {exerciseName, weight, sets, reps, rpe, repetitionType , weightCompleted,completed, log} = exercise;
    const standardRepType = repetitionType === 'Custom' || repetitionType === 'AMRAP';
    const initialLogs = useMemo(() => {
        return standardRepType
            ? initialSetState(log, weight, reps, sets)
            : getDynamicInitialSetState(log, weight, reps);
    }, [log, weight, reps, sets, standardRepType]);



    const dispatch = useDispatch();

    useEffect(() => {
        // This will now correctly trigger the dispatch
        dispatch(createExerciseBaseLogs({ index, initialLogs }));
    }, [dispatch, index, initialLogs]);


    const {unit} = useSelector(state => state.setting);
    const {mobile} = useSelector(state => state.default);

    const initialState = repetitionType !== 'Custom' ? true : !mobile;
    const [expandedComponent, setExpandedComponent] = useState(initialState);
    const handleCardClick = () => {
        setExpandedComponent(prevState => !prevState);
    };

    const type = repetitionType === 'Custom' ? <h4>Sets - {sets} Reps - {reps}</h4> : repetitionType === 'AMRAP' ?
        <h4>Sets - {sets} Reps - As many reps as possible</h4> : <h4>{repetitionType}</h4>;

    const showWeight = weight !== '0' && weight !== '';
    const maxWeightCompleted = weightCompleted ? weightCompleted : '';
    const customRepType = repetitionType === 'Custom';
    const setsCompleted = completed ? completed: 0;
    return (
        <Card header={exerciseName} key={'RunExerciseComponent' + index}
              onClick={mobile && !expandedComponent ? handleCardClick : null}
              expandable={true}
              expanded={expandedComponent}
              collapseOnClick={handleCardClick}>
            {type}
            {standardRepType ? ( <>
                {showWeight && <h4>Weight - {weight} {unit}</h4>}
                {rpe && <h4>RPE : {rpe}</h4>}
            </>) : (<>{maxWeightCompleted !== '' && <h4>Maximum weight completed- {maxWeightCompleted} {unit}</h4>}</>)}
            {expandedComponent ? (<>
                {standardRepType ? (
                    <StandardRepTypeComponent exercise={exercise} index={index} finalIndex={finalIndex}/>
                ) : (
                    <DynamicRepTypeComponent index={index} finalIndex={finalIndex}/>)}
            </>) : (<>

            </>)}
            {customRepType && <SetCompletionComponent completed={setsCompleted} total={sets}/>}
        </Card>
    );
};

export default RunExerciseComponent;