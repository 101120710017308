import React, {useEffect, useState} from 'react';
import {Doughnut} from "react-chartjs-2";
import {useSelector} from "react-redux";

const DoughnutGraph = ({
                           goal,
                           used,
                           graphSize,
                           usedColour,
                           cutoutSize,
                           text,
                           animation = true,
                           defaultOverColour = false
                       }) => {
    const {darkMode} = useSelector(state => state.default);
    const [colour, setColour] = useState(defaultOverColour ? '#ced4da' : '');
    const [overColour, setOverColour] = useState(defaultOverColour ? 'rgb(124,232,255)' : '');

    useEffect(() => {
        const borderColor = getComputedStyle(document.body).getPropertyValue('--border-color').trim();
        setColour(borderColor);
    }, [darkMode]);

    // Function to lighten or invert RGB color
    const adjustColour = (rgb) => {
        // Parse the RGB values
        const rgbValues = rgb.match(/\d+/g).map(Number);
        const newRgbValues = rgbValues.map(val => Math.min(255, val + 70));
        return `rgb(${newRgbValues.join(",")})`;
    };
    useEffect(() => {
        // Calculate the overColour dynamically based on usedColour
        const newOverColour = adjustColour(usedColour);
        setOverColour(newOverColour);
    }, [usedColour]);

    const usagePercentage = (used / goal) * 100;
    const isOverGoal = usagePercentage > 100;
    const excessPercentage = isOverGoal ? usagePercentage - 100 : 0;
    const usedPercentage = isOverGoal ? 100 : usagePercentage;
    const data = {
        labels: [
            'Over',
            'Used',
            isOverGoal ? 'Remaining' : 'Unused'
        ],
        datasets: [{
            label: 'Used',
            data: isOverGoal
                ? [excessPercentage, usedPercentage, 0]  // First show excess, then used (capped at 100%), no remaining
                : [0, usedPercentage, 100 - usedPercentage],  // If under goal, just show used and remaining
            backgroundColor: isOverGoal
                ? [overColour, usedColour, colour]  // Excess first, used second, then remainder
                : [colour, usedColour, colour],  // No excess, just normal used and remaining
            hoverOffset: 4
        }]
    };

    const options = {
        responsive: true,
        cutout: cutoutSize,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                enabled: false,
            },
        },
        interaction: {
            mode: 'none',
        },
        animation: {
            duration: animation ? 500 : 0,
        },
        onClick: null,
    };

    return (
        <div style={{position: 'relative', width: graphSize, height: graphSize}}>
            <Doughnut data={data} options={options}/>
            <div style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                textAlign: 'center',
                pointerEvents: 'none'
            }}>
                {text}
            </div>
        </div>
    );
}

export default DoughnutGraph;

