import React, {useState} from 'react';
import {Button} from "../../../common/fieldfactory";
import {useReduxActions} from "../logic/Events";

const WorkoutItem = ({index , workout}) => {
    const [open, setOpen] = useState(false);
    const userProgramPublic = workout.public;
    const {
        handleSetCurrentUserWorkout,
    } = useReduxActions();
    const renderField = (label, value) => {
        if (value !== null && value !== '' && value !== undefined) {
            return (
                <h5><strong>{label}:</strong> {value}</h5>
            );
        }
        return null;
    };
    return (
        <div className={'exercise-item'}>
            {renderField('Workout name', workout.workoutName)}
            <div className="button-container-right">
                <Button label={open ? "Hide details": "Show details"} className={open ? "button button-tertiary  mt-10 half-width":"button  mt-10 half-width"}
                        onClick={() =>setOpen(!open)}/>
            </div>
            {open && <>
                {renderField('Duration', workout.duration)}
                {renderField('Required one rep maxes', workout.orm)}
                {renderField('No of subscriptions', workout.subscriptions)}
                {renderField('Published', workout.published)}
                <div className="button-container-right">
                    <Button label="Delete Workout" className="button button-tertiary  mt-10 half-width"
                            onClick={() => console.log('delete')}/>
                </div>
                <div className="button-group">
                    <Button label={userProgramPublic ? 'Publish' : 'Unpublish'} type="submit"
                            className="button button-secondary mt-10 half-width"
                            onClick={() => console.log('Publish \\ Unpublish')}/>
                    <Button label="Set as current workout" className="button mt-10 half-width"
                            onClick={() => handleSetCurrentUserWorkout(index)}/>
                </div>
            </>}
        </div>
    )

}

export default WorkoutItem;