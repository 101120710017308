import React, {useEffect, useState} from 'react';
import DayComponent from "./DayComponent";
import {Button, TextBox, ButtonRow} from '../../../common/fieldfactory/index';
import {useReduxActions} from "../logic/Events";
import {useDispatch, useSelector} from "react-redux";
import {setDayIndex} from "../logic/Reducer";
import EditComponentName from "./EditComponentName";

const WeekComponent = ({thisWeekIndex, week, className}) => {
    const [dayName, setDayName] = useState('');
    const {weekIndex} = useSelector(state => state.createWorkOut);
    const displayDays = weekIndex === thisWeekIndex;
    const dispatch = useDispatch();
    const maxDays = week.days.length === 7;
    const heading = `Week ${thisWeekIndex + 1} - `;
    useEffect(() => {
    }, [week.days, displayDays]);
    const {
        handleAddDay,
        handleDeleteWeek,
        handleEditWeekName
    } = useReduxActions();
    return (<>{displayDays &&
            <div className={className} style={{marginBottom: '10px'}}>
                <EditComponentName
                    heading={heading}
                    handleDelete={() => handleDeleteWeek(thisWeekIndex)}
                    name={week.name}
                    handleSave={handleEditWeekName}
                />
                {!maxDays && <>
                    <TextBox type="text" className="form-control mt-10" label={"Day Name"} value={dayName}
                             onChange={(e) => setDayName(e.target.value)}/>
                    <div className="button-container-right">
                        <Button label="Add Day" className="button mt-10 half-width"
                                onClick={() => handleAddDay(dayName, setDayName, thisWeekIndex)}/>
                    </div>
                </>}
                <ButtonRow buttons={week.days} label={'Day'} onClick={(e) => dispatch(setDayIndex(e))}
                           buttonSelected={0}/>
                {week.days && week.days.map((day, index) => (
                    <DayComponent key={index} thisDayIndex={index} day={day} weekIndex={thisWeekIndex}/>
                ))}

            </div>}
        </>
    );
}

export default WeekComponent;