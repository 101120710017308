import React, {useState} from 'react';
import {useSelector} from "react-redux";
import {Button, Card, TextBox, MiniCard, HorizontalWrapper} from "../../../common/fieldfactory";
import DoughnutGraph from "../../../common/fieldfactory/graphs/DoughnutGraph";
import OneRepMaxGraph from "../../home/components/linegraph/OneRepMaxGraph";
import {useReduxActions} from "../logic/Events";
import GoalDoughnutGraph from "./GoalDoughnutGraph";

const ExerciseGoalsComponent = () => {
    const {oneRepMaxGoals, exerciseGoalOpen, page} = useSelector(state => state.goal);
    const wrapperShow = page === 'ALL';
    return (
        <div key={'ExerciseGoalsComponent'+exerciseGoalOpen}>
            {wrapperShow ? (
                    <HorizontalWrapper>
                        {oneRepMaxGoals.map((goal, index) => (
                            <ExerciseGoalComponent key={goal.oneRepMaxName} goal={goal} index={index}
                                                   exerciseGoalOpen={exerciseGoalOpen}/>
                        ))}
                    </HorizontalWrapper>) :
                (
                    <>
                        {oneRepMaxGoals.map((goal, index) => (<>
                            <ExerciseGoalComponent key={goal.oneRepMaxName} goal={goal} index={index}
                                                   exerciseGoalOpen={exerciseGoalOpen}/>
                                <ProgressComponent  goal={goal} index={index} exerciseGoalOpen={exerciseGoalOpen}/>
                            </>
                        ))}
                    </>
                )
            }
        </div>
    )
}

const ProgressComponent = (props) => {
    const { index, exerciseGoalOpen, goal } = props;
    const { oneRepMaxName } = goal;

    // Fetch the oneRepMaxesList from Redux
    const { oneRepMaxesList } = useSelector((state) => state.home);

    // Early exit if the component should not render
    if (exerciseGoalOpen !== '' && index !== exerciseGoalOpen) {
        return null;
    }

    // Set visible: true for the matching item locally
    const updatedOneRepMaxesList = oneRepMaxesList.map((item) =>
        item.max === oneRepMaxName ? { ...item, visible: true } : item
    );

    // Render the component with updated list
    return (
        <Card header="Progress Graph">
            <OneRepMaxGraph oneRepMaxesList={updatedOneRepMaxesList} />
        </Card>
    );
};


const ExerciseGoalComponent = (props) => {

    const {index, exerciseGoalOpen, goal} = props
    const {
        oneRepMaxName, originalValue, oneRepMaxGoal, currentValue, calculatedValues
    } = goal;


    const {mobile} = useSelector(state => state.default);
    const cardHeader = oneRepMaxName + ' goal'
    const expandedComponent = index === exerciseGoalOpen;

    if (exerciseGoalOpen !== '' && index !== exerciseGoalOpen) {
        return null;
    }

    const progress = oneRepMaxGoal - currentValue === 0 ? 100 : Math.ceil(((originalValue - currentValue) / (originalValue - oneRepMaxGoal)) * 100);
    const style = {marginTop: '5px', marginBottom: '5px'};
    const valueDisplay = () => {
        return (!mobile ? <div><h2 style={style}>{progress}%</h2><h3 style={style}>Progress</h3></div> :
            <div><h4 style={style}>{progress}%</h4><h5 style={style}>Progress</h5></div>)
    }

    return (<>
        {expandedComponent ? (
            <ExpandedComponent mobile={mobile} originalValue={originalValue} currentValue={currentValue}
                               oneRepMaxGoal={oneRepMaxGoal} calculatedValues={calculatedValues}
                               oneRepMaxName={oneRepMaxName} progress={progress} valueDisplay={valueDisplay}
                               cardHeader={cardHeader}/>) : (
            <CollapsedComponent progress={progress} valueDisplay={valueDisplay} mobile={mobile}
                                cardHeader={oneRepMaxName} index={index}/>)}
    </>)
}


const ExpandedComponent = (props) => {
    const {
        valueDisplay,
        mobile,
        originalValue,
        currentValue,
        oneRepMaxGoal,
        calculatedValues,
        oneRepMaxName,
        progress,
        cardHeader
    } = props;
    const [edit, setEdit] = useState(false);
    const {unit} = useSelector(state => state.setting);
    const {newGoal} = useSelector(state => state.goal);
    const doughnutGraphSize = mobile ? '120px' : '250px';
    const labels = ['Goal', 'Current'];
    const data = [oneRepMaxGoal, currentValue];

    calculatedValues.forEach(calculatedValue => {
        const {name, date, value} = calculatedValue;
        const label = name + ' ' + date
        labels.push(label);
        data.push(value);
    })
    const {goalValue} = newGoal;
    const graphSizeScale = mobile ? 75 : 75;
    const graphSize = graphSizeScale * (2 + calculatedValues.length)


    const maxScale = Math.ceil(parseFloat(oneRepMaxGoal) + 10);
    const xScale = {
        min: 0, // Set minimum value for the scale
        max: maxScale,  // Set maximum value for the scale
        ticks: {
            autoSkip: false, // Define the exact values for the X-axis ticks
            callback: function (value) {
                return `${value} ${unit}`; // Custom format for the labels
            }, // Use a custom array of tick values
            values: [-1, -0.75, -0.5, -0.25, 0, 0.25, 0.5, 0.75, 1],
        },
    };


    const formatedGoalAtSetting = Number(originalValue).toFixed(2);
    const formatedCurrent = Number(currentValue).toFixed(2);
    const formatedGoal = Number(oneRepMaxGoal).toFixed(2);

    const dataSet = [{
        label: 'Value', data: data, borderColor: ['rgb(54, 162, 235)'], backgroundColor: ['rgb(54, 162, 235)'],
    }]

    const {
        handelSetEdit, handelSaveGoal, handelDeleteGoal, handleSetOneRepMaxValue,handleSetPage
    } = useReduxActions();
    return (
        <Card header={cardHeader}
              expandable={true}
              expanded={true}
              onClick={false}
              collapseOnClick={() => handleSetPage('ALL')}
        >
        <>
            <GoalDoughnutGraph
                doughnutGraphSize={doughnutGraphSize}
                mobile={mobile}
                progress={progress}
                valueDisplay={valueDisplay()}
                graphSize={graphSize}
                formatedGoalAtSetting={formatedGoalAtSetting}
                unit={unit}
                formatedCurrent={formatedCurrent}
                formatedGoal={formatedGoal}
                xScale={xScale}
                dataSet={dataSet}
                labels={labels}
                doughnutLabel={'max'}
                barGraphTitle={'Max goal chart'}
            />
            {edit ? (<>
                <TextBox type="text" id="name" label="Goal" value={goalValue}
                         onChange={(e) => handleSetOneRepMaxValue(e.target.value)}
                         className="form-control" readOnly={false}/>

                <Button
                    label={"Save"}
                    className="button mt-10"
                    onClick={handelSaveGoal}
                />
                <Button
                    label={"Delete goal"}
                    className="button button-secondary mt-10"
                    onClick={() =>handelDeleteGoal(oneRepMaxName)}
                />
                <Button
                    label={"Cancel"}
                    className="button button-tertiary mt-10"
                    onClick={() => setEdit(!edit)}
                />
            </>) : (<Button
                label={"Edit"}
                className="button mt-10"
                onClick={() => handelSetEdit('One rep max', oneRepMaxName, oneRepMaxGoal, setEdit, edit)}
            />)}
        </>

    </Card>)
}

const CollapsedComponent = (props) => {
    const {progress, mobile, valueDisplay, cardHeader, index} = props;
    const cutoutSize = mobile ? '80%' : '85%';
    const doughnutGraphSize = mobile ? '135px' : '250px';
    const {handelSetExerciseGoalOpen} = useReduxActions();
    return (
        <MiniCard header={cardHeader} onClick={() => handelSetExerciseGoalOpen(index)}>
            <div style={{marginTop: '10px'}}>
                <DoughnutGraph goal={100} used={progress} cutoutSize={cutoutSize}
                               graphSize={doughnutGraphSize}
                               usedColour={'rgb(54, 162, 235)'} text={valueDisplay()}/>
            </div>
        </MiniCard>
    )
}

export default ExerciseGoalsComponent;