import React from 'react';

const TwoCards = ({ card1, card2 }) => {
    return (
        <div className="two-cards-wrapper">
            <div className="side-by-side-card">
                {card1}
            </div>
            <div className="side-by-side-card">
                {card2}
            </div>
        </div>
    );
}

export default TwoCards;
