import React, { useEffect, useRef, useState, useCallback } from 'react';
import { FaMaximize, FaMinimize } from "react-icons/fa6";
import IconButton from '../iconbutton/IconButton'; // Ensure you adjust the import path as necessary

const TextArea = ({ id, value, onChange, formatOnBlur, className, label }) => {
    const [isMinimized, setIsMinimized] = useState(false);
    const textareaRef = useRef(null);

    const adjustHeight = useCallback(() => {
        if (!isMinimized && textareaRef.current) {
            textareaRef.current.style.height = 'inherit';
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        }
    }, [isMinimized]);

    const toggleMinimize = () => {
        setIsMinimized(!isMinimized);
    };

    useEffect(() => {
        adjustHeight();
    }, [value, isMinimized, adjustHeight]); // Adjust height when the value or isMinimized changes

    const handleChange = (e) => {
        if (onChange && typeof onChange === 'function') {
            onChange(e);
        }
        adjustHeight();
    };

    const handleBlur = (e) => {
        if (formatOnBlur && typeof formatOnBlur === 'function') {
            e.target.value = formatOnBlur(e.target.value);
            if (onChange && typeof onChange === 'function') {
                onChange(e);
            }
        }
    };

    // Determine the icon based on the isMinimized state
    const icon = isMinimized ? <FaMaximize size={15}/> : <FaMinimize size={15}/>; // Adjust icons as necessary

    return (
        <div className={`form-group ${isMinimized ? 'minimized-textarea' : ''}`}>
            <label htmlFor={id}>{label}</label>
            <IconButton icon={icon} onClick={toggleMinimize} />
            <textarea
                ref={textareaRef}
                id={id}
                value={value || ''}
                onChange={handleChange}
                onBlur={handleBlur}
                className={`form-control ${className || ''}`}
                style={{ resize: 'none', overflow: 'hidden', height: isMinimized ? '50px' : '' }} // Adjust the height dynamically
                maxLength={5000}
            />
            <div className="character-count">
                {`${value ? value.length : 0} / 5000`}
            </div>
        </div>
    );
};

export default TextArea;

