import React from 'react';
import { useSelector } from 'react-redux';

const MiniCard = ({ header, children, onClick, customHeader }) => {
    const { mobile } = useSelector((state) => state.default);

    // Trim or validate the header length
    const trimmedHeader = header && header.length > 15 ? `${header.slice(0, 15)}...` : header;

    return (
        <div
            className="small-centered-container"
            onClick={onClick ? onClick : null}
            style={{ cursor: onClick ? 'pointer' : 'default' }}
        >
            {mobile ? (
                <div
                    style={
                        customHeader
                            ? {}
                            : { display: 'flex', justifyContent: 'space-between', alignItems: 'center' }
                    }
                >
                    {customHeader ? (
                        customHeader
                    ) : (
                        <h3 style={{ margin: 0 }}>{trimmedHeader}</h3>
                    )}
                </div>
            ) : (
                <>{customHeader ? customHeader : <h2>{trimmedHeader}</h2>}</>
            )}
            {children}
        </div>
    );
};

export default MiniCard;
