import React, {useState} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {isToday, isSameMonth} from 'date-fns';

const CustomDatePicker = ({label, highlightToday, onOpen,value, onChange}) => {
    const startDate = new Date(value);
    const [viewedMonth, setViewedMonth] = useState(new Date());

    const highlightWithClassName = date => {
        return highlightToday && isToday(date) ? 'highlight-today' : '';
    };

    const isDateInCurrentViewedMonth = (date) => {
        return isSameMonth(date, viewedMonth);
    };

    const handleCalendarChange = (date) => {
        setViewedMonth(date);
    };

    const handleOpen = () => {
        const dateToView = startDate || new Date();
        setViewedMonth(dateToView);
        if (onOpen) {
            onOpen();
        }
    };

    return (
        <div className="form-group"
            style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginTop: '10px'}}>
            {label && <label>{label}</label>}
            <DatePicker
                selected={startDate}
                onChange={(date) => onChange(date)}
                dayClassName={highlightWithClassName}
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                filterDate={isDateInCurrentViewedMonth}
                onMonthChange={handleCalendarChange}
                onYearChange={handleCalendarChange}
                onCalendarOpen={handleOpen}
                dateFormat="dd/MM/yyyy"
                popperPlacement="top-start"
            />
        </div>
    );
};

export default CustomDatePicker;
