// index.js or wherever your app is initialized
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { AlertProvider } from "./common/fieldfactory/alert/AlertContext";
import { store } from "./common/statemangment/Store";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <AlertProvider>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </AlertProvider>
        </Provider>
    </React.StrictMode>
);
