import {Button, TextBox, ButtonRow, Card} from '../../../common/fieldfactory/index';
import {setWeekIndex} from "../logic/Reducer";
import WeekComponent from "./WeekComponent";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useReduxActions} from "../logic/Events";


const ScheduleComponent = () => {
    const [weekName, setWeekName] = useState('');
    const dispatch = useDispatch();
    const workout = useSelector(state => state.createWorkOut);
    useEffect(() => {
    }, [workout.weeks]);
    const {
        handleAddWeek,
        handleDuplicateWeek
    } = useReduxActions();
    const displayDuplicate = workout.weeks.length > 0;
    return (
        <Card header="Schedule">
            <TextBox type="text" className="form-control mt-10" label={"Week Name"} value={weekName}
                     onChange={(e) => setWeekName(e.target.value)}/>
            {!displayDuplicate && <div className="button-container-right">
                <Button label="Add Week" className="button mt-10 half-width"
                        onClick={() => handleAddWeek(weekName, setWeekName)}/>
            </div>}
            {displayDuplicate &&
                <div className="button-group mt-10 mb-10">
                    <Button label="Duplicate Week" className="button button-secondary half-width"
                            onClick={() => handleDuplicateWeek(workout.weekIndex)}/>
                    <Button label="Add Week" className="button half-width"
                            onClick={() => handleAddWeek(weekName, setWeekName)}/>
                </div>}
            <ButtonRow buttons={workout.weeks} label={'Week'} onClick={(e) => dispatch(setWeekIndex(e))}
                       buttonSelected={0}/>
            {workout.weeks && workout.weeks.map((week, index) => (
                <WeekComponent key={index} thisWeekIndex={index} week={week}
                               className={'week-white'}
                />
            ))}
        </Card>
    )
}

export default ScheduleComponent;