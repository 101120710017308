import React, {useState} from 'react';
import {useSelector} from "react-redux";
import Button from "../../../common/fieldfactory/button/Button";
import TextBox from "../../../common/fieldfactory/textbox/TextBox";
import {useReduxActions} from "../logic/Events";

const DynamicRepTypeComponent = ({index}) => {
    const {handleCompleteExercise, handleEditExercise,handleUpdateSetLogs,handleDeleteSet,handleAddSet} = useReduxActions();
    const {exercises} = useSelector(state => state.runWorkout);
    const { log ,complete} = exercises[index];
    const [maxWeight] = useState(0);
    if(log=== null){
        return;
    }
    return (
        <>
            {log.map((set, setIndex) => (
                <div key={setIndex}>
                    <h4>Set {setIndex + 1}</h4>
                    {!complete ? (
                        <div className="flex-row-container">
                            <div className="flex-item-half">
                                <TextBox
                                    type="text"
                                    id={`${setIndex}weight`}
                                    label="Weight completed"
                                    value={log[setIndex].weight}
                                    onChange={(e) => handleUpdateSetLogs(e.target.value, setIndex, 'weight',index)}
                                    className="form-control"
                                />
                                <div className="button-container-right">
                                    <Button
                                        label="Delete Set"
                                        type="button"
                                        className="button button-tertiary mt-10 half-width"
                                        onClick={() => handleDeleteSet(setIndex,index)}
                                    />
                                </div>
                            </div>
                        </div>
                    ) : (
                        <>
                            <h5>Weight - {log[setIndex].weight}</h5>
                        </>
                    )}
                </div>
            ))}
            <div className="button-group">
                {!complete && (
                    <Button
                        label="Add Set"
                        type="button"
                        className="button button-secondary mt-10 half-width"
                        onClick={() => handleAddSet(index)}
                    />
                )}
                <Button
                    label={!complete ? "Complete Exercise" : "Edit Exercise"}
                    type="submit"
                    className={!complete ? "button mt-10 half-width" : "button button-tertiary mt-10 half-width"}
                    onClick={() => !complete ? handleCompleteExercise(index, maxWeight) : handleEditExercise(index)}
                />
            </div>
        </>
    );
};

export default DynamicRepTypeComponent;

