import React from "react";
import {useSelector} from "react-redux";
import {useReduxActions} from "../logic/Events";
import {Button,Card,TextBox} from '../../../common/fieldfactory/index';

const OneRepMaxComponent = () => {
    const {oneRepMaxList} = useSelector(state => state.setting);
    const display = oneRepMaxList.length !== 0;
    const {
        handleDeleteOneRepMax,
        handleAddOneRepMax,
        handleUpdateOneRepMaxName,
        handleUpdateOneRepMaxWeight,
        handleSaveOneRepMaxList
    } = useReduxActions();
    return (
        <Card header="One Rep Maximum">
            {display && <>
                {oneRepMaxList.map((oneRep, index) => (
                    <div className="form-group" key={index}>
                        <div className="flex-row-container">
                            <div className="flex-item-half">
                                <TextBox type="text" id="name" label="Exercise name" value={oneRep.exercise}
                                         onChange={(e) => handleUpdateOneRepMaxName(index, e.target.value)}
                                         className="form-control" readOnly={false}/>
                            </div>
                            <div className="flex-item-half">
                                <TextBox type="number" id="weight" label="Max Rep" value={oneRep.max}
                                         onChange={(e) => handleUpdateOneRepMaxWeight(index, e.target.value)}
                                         className="form-control" readOnly={false}/>
                            </div>
                        </div>
                        <div className="button-container-right">
                            <Button label="Delete On Rep Max" className="button button-tertiary mt-10 half-width"
                                    onClick={() => handleDeleteOneRepMax(index)}/>
                        </div>
                    </div>
                ))}
            </>
            }
            <div className="button-group">
                <Button label="Create Record" type="submit" className="button button-secondary mt-10 half-width"
                        onClick={handleAddOneRepMax}/>
                <Button label="Save" className="button mt-10 half-width"
                        onClick={handleSaveOneRepMaxList}/>
            </div>
        </Card>
    );
}

export default OneRepMaxComponent;