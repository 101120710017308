import React from 'react';
import PropTypes from 'prop-types';
import {FaMinus, FaPlus} from "react-icons/fa";
import {useSelector} from "react-redux";
import {IconButton} from '../../../common/fieldfactory/index';

const ExerciseLogList = ({log}) => {
    if (!log) {
        return null;
    }
    const {workout: {workoutName, weekName, dayName, exercises}} = log;
    return (
        <div>
            <h3>{workoutName}</h3>
            <div className={'date-item-container'}>
                <DateItem weekName={weekName} dayName={dayName}/>
            </div>

            {exercises.map((exercise, index) => (
                <React.Fragment key={index}>
                    <SetLogsItem log={exercise?.log} exercise={exercise} index={index}/>
                </React.Fragment>
            ))}

        </div>
    );
};

const SetLogsItem = ({log, index, exercise}) => {
    const [open, setOpen] = React.useState(false);
    const {unit} = useSelector(state => state.setting);
    const {exerciseName, weight, sets, reps, repetitionType, rpe} = exercise;
    const showWeight = weight !== '0' && weight !== '';
    const type = repetitionType === 'Custom' ? <h5>Sets - {sets} Reps - {reps}</h5> : repetitionType === 'AMRAP' ?
        <h5>Sets - {sets} Reps - As many reps as possible</h5> : <h5>{repetitionType}</h5>;
    return (
        <>
            <h4>Exercise {index + 1} - {exerciseName}</h4>
            {showWeight && <h5>Weight : {weight} {unit}</h5>}
            {rpe && <h5>RPE : {rpe}</h5>}
            {type}
            {!log ? (<></>) : (
                <>

                    <div className={'exercise-log-item'}>
                        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                            <h5 style={{marginTop : '0' ,marginBottom : '0'}}>{open ? 'Hide logs': 'Show logs'}</h5>
                            <IconButton onClick={() => setOpen(!open)} icon={open ? <FaMinus/> : <FaPlus/>}/>
                        </div>
                        {open && log.map((set, index) => (
                            <SetLogItem key={index} setLog={set} index={index} unit={unit}/>
                        ))}</div>
                </>
            )}
        </>
    );
};
const DateItem = ({weekName, dayName}) => {
    return (
        <>
            {renderField('Week name', weekName)}
            {renderField('Day name', dayName)}
        </>
    );
};

const SetLogItem = ({setLog, index, unit}) => {
    const {weight, reps} = setLog;

    const weightUnit = `${weight} ${unit}`;
    return (
        <div className={'set-log-item-container'}>
            {index !== 0 && <div className={'line'}></div>}
            <div className={'content'}>
                {renderField('Set no', index + 1)}
                {renderField('Reps completed', reps)}
                {renderField('Weight completed at', weightUnit)}
            </div>
        </div>
    );
}

const renderField = (label, value) => {
    if (value !== null && value !== '') {
        return (
            <h5><strong>{label}:</strong> {value}</h5>
        );
    }
    return null;
};
ExerciseLogList.propTypes = {
    log: PropTypes.object,
};

export default ExerciseLogList;


