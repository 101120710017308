import {createSlice} from '@reduxjs/toolkit';
import {formatISO} from "date-fns";

const initialState = {
    pageState: 'START',
    workoutName: '',
    setUpProgram: {},
    currentExercise: 0,
    completeWorkout: false,
    workoutComplete: false,
    weekName: '',
    dayName: '',
    day: '',
    week: '',
    exercises: [],
    sessionStart: ''
}

export const runWorkoutSlice = createSlice({
    name: 'runWorkout',
    initialState,
    reducers: {
        updateSetLogs: (state, action) => {
            const {value, setIndex, key, index} = action.payload;
            const log = {...state.exercises[index].log[setIndex]};
            log[key] = value;
            state.exercises[index].log[setIndex] = log;
        },
        addSet: (state, action) => {
            const  exerciseIndex = action.payload;
            const {log,weight,reps} = state.exercises[exerciseIndex];
            state.exercises[exerciseIndex].log = [...log, {weight: weight, reps: reps}];
        },
        deleteSet: (state, action) => {
            const { setIndex, exerciseIndex } = action.payload;
            const log = state.exercises[exerciseIndex].log;
            if (log && log.length > setIndex) {
                state.exercises[exerciseIndex].log = log.filter((_, i) => i !== setIndex);
            }
        },
        createExerciseBaseLogs: (state, action) => {
            const {index, initialLogs} = action.payload;
            if (state.exercises[index].log === null) {
                state.exercises[index].log = initialLogs
            }
        },
        rollbackSet: (state) => {
            const currentExerciseIndex = state.currentExercise - 1;
            if (currentExerciseIndex >= 0 && currentExerciseIndex < state.exercises.length) {
                const currentExercise = state.exercises[currentExerciseIndex];

                // Increment completed sets or initialize if not present
                currentExercise.completed = currentExercise.completed ? currentExercise.completed - 1 : 0;
            }
        },
        setCurrentExercise: (state, action) => {
            const {index, weightCompleted} = action.payload;
            // Update the log and mark the exercise as complete
            state.exercises[index].weightCompleted = weightCompleted;
            state.exercises[index].complete = true;

            // Move to the next exercise if appropriate
            state.currentExercise = (state.currentExercise === state.exercises.length)
                ? state.currentExercise
                : (index + 1 !== state.currentExercise)
                    ? state.currentExercise
                    : state.currentExercise + 1;

            // Check if all exercises are complete
            const allExercisesComplete = state.exercises.every(exercise => exercise.complete);
            // If all exercises are complete, set completeWorkout to true
            if (allExercisesComplete) {
                state.completeWorkout = true;
            }
        },
        setRunState: (state) => {
            state.pageState = 'RUN';
            state.currentExercise = state.currentExercise + 1;
            state.sessionStart = formatISO(new Date());
        },
        setUpRunProgram: (state, action) => {
            const {
                setUpProgramDTO,
                workoutName,
                weekName,
                dayName,
                day,
                week,
                exercises,
                workoutCompleted
            } = action.payload;
            state.pageState = !workoutName ? 'NOPROGRAM' : !setUpProgramDTO ? 'START' : 'SETUP';
            state.workoutName = workoutName;
            state.setUpProgram = setUpProgramDTO;
            state.weekName = weekName;
            state.workoutComplete = workoutCompleted;
            state.dayName = dayName;
            state.day = day;
            state.week = week;
            state.currentExercise = 0;
            state.exercises = exercises;
        },
        updateOneRepMax: (state, action) => {
            const {index, val} = action.payload;
            state.setUpProgram.oneRepMaxLinks[index] = {
                ...state.setUpProgram.oneRepMaxLinks[index],
                max: val,
                userOneRepMaxName: ''
            };
        },
        completeSet: (state) => {
            const currentExerciseIndex = state.currentExercise - 1;

            // Ensure currentExerciseIndex is valid before accessing
            if (currentExerciseIndex >= 0 && currentExerciseIndex <= state.exercises.length) {
                const currentExercise = state.exercises[currentExerciseIndex];

                // Increment completed sets or initialize if not present
                currentExercise.completed = currentExercise.completed ? currentExercise.completed + 1 : 1;

                // Check if the current exercise is completed
                if (currentExercise.completed === currentExercise.sets) {
                    const nextExerciseIndex = state.currentExercise;
                    state.exercises[currentExerciseIndex].complete = true;
                    // Check if there is a next exercise
                    if (nextExerciseIndex < state.exercises.length) {

                        state.currentExercise = nextExerciseIndex + 1;
                    }
                }
                const allExercisesComplete = state.exercises.every(exercise => exercise.complete);
                // If all exercises are complete, set completeWorkout to true
                if (allExercisesComplete) {
                    state.completeWorkout = true;
                }
            } else {
                console.error('Invalid exercise index:', currentExerciseIndex);
            }
        },
        updateUserOneRepMaxName: (state, action) => {
            const {index, val} = action.payload;
            state.setUpProgram.oneRepMaxLinks[index] = {
                ...state.setUpProgram.oneRepMaxLinks[index],
                max: 0,
                userOneRepMaxName: val
            };
        },
        updateExerciseEdit: (state, action) => {
            const index = action.payload;
            state.exercises[index].complete = false;
            state.completeWorkout = false;
        }

    }
});

export const {
    updateSetLogs,
    addSet,
    deleteSet,
    completeSet,
    setRunState,
    setUpRunProgram,
    updateOneRepMax,
    updateUserOneRepMaxName,
    setCurrentExercise,
    updateExerciseEdit,
    rollbackSet,
    createExerciseBaseLogs
} = runWorkoutSlice.actions;

export default runWorkoutSlice.reducer;