import React from 'react';
import {useSelector} from "react-redux";
import {Card} from "../../../common/fieldfactory";
import {useReduxActions} from "../logic/Events";

const SessionGoals = () => {
    const {page} = useSelector(state => state.goal);
    const expanded = page === 'SESSIONGOAL';
    return(
        <>
            {expanded ?
                (<ExpandedComponent/>)
                :
                (<CollapsedComponent/>)
            }
        </>
    )
}

const CollapsedComponent = () => {
    const {
        handleSetPage
    } = useReduxActions();
    return(
        <Card header="Session goals"
              expandable={true}
              expanded={false}
              onClick={() => handleSetPage('SESSIONGOAL')}
              collapseOnClick={false}
        >

        closed
        </Card>
    )
}

const ExpandedComponent = () => {
    const {
       handleSetPage
    } = useReduxActions();
    return(
        <Card header="Session goals"
              expandable={true}
              expanded={true}
              onClick={false}
              collapseOnClick={() => handleSetPage('ALL')}
        >

        open
        </Card>
    )
}

export default SessionGoals;