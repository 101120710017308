import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    unit: '',
    oneRepMaxList: [
    ],
    program: {
        currentProgram : {
            name: '',
            week: 0,
            day: 0,
            endDate: ''
        },
        selectedSubscribedWorkout : -1,
        subscribedWorkouts : [
        ],
        selectedUserWorkout : -1,
        userWorkouts : [
        ]
    },
    nutrition : {
        categories : [

        ]
    }
};

export const settingSlice = createSlice({
    name: 'setting',
    initialState,
    reducers: {
        deleteItemCategory: (state, action) => {
            const { index , itemIndexIndex} = action.payload;
            if(state.nutrition.categories[index].items[itemIndexIndex])
                state.nutrition.categories[index].items = state.nutrition.categories[index].items.filter((_, i) => itemIndexIndex !== i);
        },
        editItemCategory:(state, action) => {
            const {value, index , itemIndexIndex} = action.payload;
            state.nutrition.categories[index].items[itemIndexIndex].name = value;
        },
        addItemToCategories: (state, action) => {
            const category = state.nutrition.categories[action.payload];
            if (!category.items) {
                category.items = [{ name: '' }];
            } else {
                category.items.push({ name: '' });
            }
        },
        loadNutritionCategories: (state, action) => {
            state.nutrition.categories = action.payload.categories;
        },
        deleteCategory: (state, action) => {
            const dIndex = action.payload;
            if(state.nutrition.categories[dIndex])
                state.nutrition.categories = state.nutrition.categories.filter((_, index) => dIndex !== index);
        },
        addCategory: (state) => {
            state.nutrition.categories.push(
                {
                    name: '',
                    order: state.nutrition.categories.length
                }
            )
        },
        editCategory: (state,action) => {
            const {index, category} = action.payload;
            state.nutrition.categories[index].name = category;
        },
        decreaseOrder: (state, action) => {
            const index = action.payload;
            if (index > 0) {
                const temp = state.nutrition.categories[index];
                state.nutrition.categories[index] = state.nutrition.categories[index - 1];
                state.nutrition.categories[index - 1] = temp;
                state.nutrition.categories[index].order = index;
                state.nutrition.categories[index - 1].order = index - 1;
            }
        },
        increaseOrder: (state, action) => {
            const index = action.payload;
            if (index < state.nutrition.categories.length - 1) {
                const temp = state.nutrition.categories[index];
                state.nutrition.categories[index] = state.nutrition.categories[index + 1];
                state.nutrition.categories[index + 1] = temp;
                state.nutrition.categories[index].order = index;
                state.nutrition.categories[index + 1].order = index + 1;
            }
        },
        setUnit: (state, action) => {
            state.unit = action.payload;
        },
        addOneRepMax: (state) => {
            state.oneRepMaxList.push({exercise: '', max: ''});
        },
        updateOneRepMaxName: (state, action) => {
            const {index,name} = action.payload;
            state.oneRepMaxList[index] = {...state.oneRepMaxList[index],exercise: name}
        },
        updateOneRepMaxWeight: (state, action) => {
            const {index,weight} = action.payload;
            state.oneRepMaxList[index] = {...state.oneRepMaxList[index],max: weight}
        },
        deleteOneRepMax: (state, action) => {
            const oneRepMaxIndex = action.payload;
            if (state.oneRepMaxList[oneRepMaxIndex]) {
                state.oneRepMaxList = state.oneRepMaxList.filter((_, index) => index !== oneRepMaxIndex);
            }
        },
        setState: (state, action) => {
            const {unit,oneRepMaxList , programs} = action.payload;
            const {name,day,week,endDate} = programs.currentProgramDTO;
            state.unit = unit;
            state.oneRepMaxList = oneRepMaxList;
            state.program.subscribedWorkouts = programs.subscribedWorkouts;
            state.program.userWorkouts = programs.userWorkouts;
            state.program.currentProgram = {
                name: name,
                week: week,
                day: day,
                endDate: endDate
            };
        },
        setSelectedUserWorkout: (state, action) => {
            state.program.selectedUserWorkout = action.payload;
        },
        setSelectedWorkout: (state, action) => {
            state.program.selectedSubscribedWorkout = action.payload;
        },
        setCurrentProgram : (state, action) => {
            const {name,endDate} = action.payload;
            state.program.currentProgram = {
                name: name,
                week: 1,
                day: 1,
                endDate: endDate
            }
        }
    }
});

export const {
    deleteItemCategory,
    editItemCategory,
    addItemToCategories,
    editCategory,
    deleteCategory,
    addCategory,
    decreaseOrder,
    increaseOrder,
    setCurrentProgram
    ,setState
    ,setUnit
    ,addOneRepMax
    ,deleteOneRepMax
    ,updateOneRepMaxName
    ,updateOneRepMaxWeight
    ,setSelectedUserWorkout
    ,setSelectedWorkout,
    loadNutritionCategories
} = settingSlice.actions;

export default settingSlice.reducer;