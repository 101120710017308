import React, {useEffect, useState} from 'react';
import {SlLogout} from "react-icons/sl";
import IconButton from "../../iconbutton/IconButton";
import {useDispatch, useSelector} from "react-redux";
import {setSideBarOpen} from "../../../statemangment/Reducer";
import {DarkGreenModeIcon, DarkModeIcon, DarkRedModeIcon, GreenModeIcon, LightModeIcon, RedModeIcon} from "../../index";
import {
    CreateWorkoutIcon,
    FindWorkoutIcon, GoalsIcon,
    HomeIcon,
    NutritionIcon,
    RunWorkoutIcon, SettingsIcon,
    useReduxActions
} from "../logic/Events";
import NavButton from "./button/NavButton";

const Sidebar = () => {
    //const sideBarOpen = useSelector(state => state.default.sideBarOpen);
    const {sideBarOpen , darkMode } = useSelector(state => state.default);
    const dispatch = useDispatch();
    const [showText, setShowText] = useState(true); // State to control text visibility
    const sidebarClass = sideBarOpen ? 'sidebar-open' : 'sidebar-closed';
    const {page} = useSelector(state => state.default);
    const {
        handleNavigation,
        handleLogout,
        handleCreateWorkoutNavigation,
        handleRunWorkoutNavigation,
        handleNutritionNavigation
    } = useReduxActions();
    const icon = (darkMode,classname) => {
        return darkMode === 'dark' ? <DarkModeIcon className={classname}/>:
            darkMode === 'red' ? <RedModeIcon className={classname}/>:
                darkMode === 'green' ? <GreenModeIcon className={classname}/>:
                    darkMode === 'red-dark' ? <DarkRedModeIcon className={classname}/>:
                        darkMode === 'green-dark' ? <DarkGreenModeIcon className={classname}/>
                            :  <LightModeIcon className={classname}/>;}

    useEffect(() => {
        if (sideBarOpen) {
            setTimeout(() => setShowText(true), 300);
        } else {
            // Delay hiding the text to match the sidebar transition duration
            setShowText(false);
        }
    }, [sideBarOpen]); // Run this effect when `isOpen` changes

    const className = "sidebar-icon";
    return (
        <div>
            <div id="sidebar" className={sidebarClass}>
                <div className="icon-sidebar-container">
                    <IconButton onClick={() => dispatch(setSideBarOpen(!sideBarOpen))}
                                icon={icon(darkMode,'sidebar-logo')}/>
                    {showText && <span className="sidebar-text"
                                       onClick={() => dispatch(setSideBarOpen(!sideBarOpen))}>FitLifes</span>}
                </div>
                <ul>
                    <NavButton onClick={() => handleNavigation('HOME', dispatch)} showText={showText} text={'Home'} children={HomeIcon({page, className})} />
                    <NavButton onClick={() => handleRunWorkoutNavigation(dispatch)} showText={showText} text={'Run Workout'} children={RunWorkoutIcon({page, className})}/>
                    <NavButton onClick={() => handleCreateWorkoutNavigation(dispatch)} showText={showText} text={'Create Workout'} children={CreateWorkoutIcon({page, className})}/>
                    <NavButton onClick={() => handleNavigation('FINDWORKOUT', dispatch)} showText={showText} text={'Find Workout'} children={FindWorkoutIcon({page, className})}/>
                    <NavButton onClick={() => handleNavigation('GOALS', dispatch)} showText={showText} text={'Goals'} children={GoalsIcon({page, className})}/>
                    <NavButton onClick={() => handleNutritionNavigation(dispatch)} showText={showText} text={'Nutrition'} children={NutritionIcon({page, className : page !== 'NUTRITION' ? className : darkMode === 'green' ? 'sidebar-icon-carrot': className})}/>
                    <NavButton onClick={() => handleNavigation('SETTINGS', dispatch)} showText={showText} text={'Settings'} children={SettingsIcon({page, className})}/>
                    <NavButton onClick={() => handleLogout(dispatch)} showText={showText} text={'Logout'} children={<SlLogout  className="sidebar-icon"/>}/>
                </ul>
            </div>
        </div>
    );
};

export default Sidebar;

