import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {Card, TwoCards, VerticalCards} from "../../../common/fieldfactory";
import {useReduxActions} from "../logic/Events";
import AcceptedMealComponent from "./AcceptedMealComponent";
import GeneratedMeals from "./GeneratedMeals";
import MealPlanGeneration from "./MealPlanGeneration";

const GeneratingComponent = () => {
    const {
        page
    } = useSelector(state => state.nutrition);
    const {
        handleSetPage
    } = useReduxActions();
    const {mobile} = useSelector(state => state.default);
    const [expandedComponent, setExpandedComponent] = useState(!mobile);
    const handleCardClick = () => {
        if (mobile) {
            setExpandedComponent(prevState => !prevState);
            if (expandedComponent) {
                handleSetPage('ALL');
            } else {
                handleSetPage('GENERATING');
            }
        }
    };

    useEffect(() => {
        if (mobile) {
            setExpandedComponent(false);
        } else {
            setExpandedComponent(true);
        }
    }, [mobile]);

    useEffect(() => {
        if (mobile) {
            if (page === 'GENERATING') {
                setExpandedComponent(true);
            } else if (page === 'ALL') {
                setExpandedComponent(false);
            }
        }
    }, [mobile, page]);
    const cards = <TwoCards card1={
        <VerticalCards cards={[<MealPlanGeneration expandedComponent={expandedComponent}
                                                             handleCardClick={handleCardClick}/>,
        <AcceptedMealComponent/>
        ]}
        />} card2={<GeneratedMeals/>}/>
    return (<>
            {expandedComponent ?
                (
                    <>
                        {cards}
                    </>
                ) : (
                    <Card header={'Meal plan generation'}
                          onClick={mobile && !expandedComponent ? handleCardClick : null}
                          expandable={true}
                          expanded={expandedComponent}
                          collapseOnClick={handleCardClick}
                    />
                )}
        </>

    )
}

export default GeneratingComponent;