import React from "react";
import {BsPencil, BsPencilFill} from "react-icons/bs";
import {HiCog6Tooth, HiOutlineCog6Tooth} from "react-icons/hi2";
import {IoHome, IoHomeOutline} from "react-icons/io5";
import {
    PiCarrotFill,
    PiCarrotLight,
    PiMagnifyingGlass,
    PiMagnifyingGlassDuotone
} from "react-icons/pi";
import {TbTarget, TbTargetArrow} from "react-icons/tb";
import {useSelector} from "react-redux";
import {loadNutrition, setAnimateGraph} from "../../../../pages/nutrition/logic/Reducer";
import callApi from "../../../api/Apiutils";
import {setPage, setShowTimer, setSideBarOpen} from "../../../statemangment/Reducer";
import {useAlert} from "../../alert/AlertContext";
import DumbbellFilled from "../../customicons/DumbbellFilled";
import DumbbellOutlined from "../../customicons/DumbbellOutlined";

export const useReduxActions = () => {
    const handleNavigation = useHandleNavigation();
    const handleLogout = useHandleLogout();
    const handleCreateWorkoutNavigation = useHandleCreateWorkoutNavigation();
    const handleRunWorkoutNavigation = useHandleRunWorkoutNavigation();
    const handleNutritionNavigation = useHandleNutritionNavigation();
    return {
        handleNavigation,
        handleLogout,
        handleCreateWorkoutNavigation,
        handleRunWorkoutNavigation,
        handleNutritionNavigation
    }
}
const useHandleNavigation = () => {
    return (page,dispatch) => {
        dispatch(setPage(page));
    }
};

const useHandleCreateWorkoutNavigation = () => {
    // const {showAlert} = useAlert();
    // const authorization = useSelector(state => state.default.authorization);
    return async (dispatch) => {
        // const success = await loadDropDownLists(dispatch, showAlert,authorization);
        // if(success){
            dispatch(setPage('CREATEWORKOUT'));
        //}
    }
};

const useHandleNutritionNavigation = () => {
    const {showAlert} = useAlert();
    const {dataLoaded} = useSelector(state => state.nutrition);
    const authorization = useSelector(state => state.default.authorization);
    return async (dispatch) => {

        if (!dataLoaded) {
            const response = await callApi(`/fit/nutrition`, 'GET', null, {
                Authorization: authorization,
                service: 'NutritionService'
            }, undefined, dispatch);
            if (response.error) {
                showAlert(response.message);
            }else {
                dispatch(loadNutrition(response));
            }
        }

        dispatch(setPage('NUTRITION'));
        dispatch(setAnimateGraph());
    }

}

const useHandleRunWorkoutNavigation = () => {
    const {pageState} = useSelector(state => state.runWorkout);
    return async (dispatch) => {
            dispatch(setShowTimer(pageState === 'RUN'));
            dispatch(setPage('RUNWORKOUT'));
    }
};

export const SettingsIcon = ({ page, className }) => {return page === 'SETTINGS' ? (<HiCog6Tooth className={className} />) : (<HiOutlineCog6Tooth className={className} />);};
export const HomeIcon = ({page,className}) => {return page ===  'HOME' ?  <IoHome className={className}/> : <IoHomeOutline className={className}/>;}
export const CreateWorkoutIcon = ({page,className}) => { return page === 'CREATEWORKOUT' ?  <BsPencilFill className={className}/> : <BsPencil className={className}/>;}
export const RunWorkoutIcon = ({page,className}) => { return page === 'RUNWORKOUT' ?  <DumbbellFilled  className={className} /> : <DumbbellOutlined  className={className} />;}
export const FindWorkoutIcon = ({page,className}) => { return page === 'FINDWORKOUT' ?  <PiMagnifyingGlassDuotone   className={className}/> : <PiMagnifyingGlass  className={className}/>;}
export const NutritionIcon = ({page,className}) => { return page === 'NUTRITION' ?   <PiCarrotFill  className={className}/> :  <PiCarrotLight  className={className}/>;}
export const GoalsIcon = ({page,className}) => { return page === 'GOALS' ?   <TbTargetArrow  className={className}/> :  <TbTarget  className={className}/>;}

const useHandleLogout = () => {
    const {showAlert, hideAlert} = useAlert();
    return (dispatch) => {
        const newButtons = [
            {
                label: "Yes",
                onClick: () => {
                    dispatch(setSideBarOpen(true));
                    dispatch(setShowTimer(false));
                    dispatch(setPage('LOGIN'));

                }
            },
            {label: "No", onClick: hideAlert}
        ];
        showAlert('Are you sure you want to logout?', newButtons);
    }
}
