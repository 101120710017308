import React from 'react';
import PropTypes from 'prop-types';
import Button from "../button/Button";

/**
 * Handles display alert contents and buttons
 *
 * @const Alert
 * @param {number} count - The number of alerts in the alert queue.
 * @param {string} message - message to display in alert
 * @param {boolean} isOpen - if true display alert.
 * @param {function} onClose - Function to close alert.
 * @param {array} buttons - buttons and functions to facilitate button press.
 * @param {...object} rest - Additional props passed to the Alert component. These can include any other valid attributes or event handlers that should be applied to the alert container.
 * @returns {Alert}
 *
 * @example
 * // Usage in a React component
 *                <Alert
 *                     count={alertQueue.length}
 *                     key={currentAlert.id}
 *                     message={currentAlert.message}
 *                     isOpen={true}
 *                     onClose={hideAlert}
 *                     buttons={currentAlert.buttons}
 *                 />
 *
 * * @description
 *  * This component displays an alert on the screen for the end user to acknowledge or continue with their actions.
 *  The `...rest` parameter allows for the passing of additional props, making the Alert component flexible and adaptable to different use cases.
 *  */
const Alert = ({ count,message, buttons, isOpen, onClose , ...rest }) => {
    if (!isOpen) return null;

    const handleClick = (button) => {
        if (button && button.onClick) {
            button.onClick();
        }
        onClose();
    };
    const displayCount = count > 1;
    return (
        <div className="custom-alert-overlay" onClick={onClose} {...rest}>
            <div className="custom-alert" onClick={(e) => e.stopPropagation()}>
                {displayCount && <span className="alert-count">{count}</span>}{}
                <div className="custom-alert-message">{message}</div>
                <div className="custom-alert-buttons">
                    {buttons && buttons.length > 0 ? (
                        buttons.map((button, index) => (
                            <Button
                                className={`button button-fixed-width ${index === 1 ? 'button-secondary' : ''}`}
                                key={index} label={button.label} onClick={() => handleClick(button)} />
                        ))
                    ) : (
                        <Button className="button button-fixed-width" label="OK" onClick={onClose} />
                    )}
                </div>
            </div>
        </div>
    );
};


Alert.propTypes = {
    message: PropTypes.string.isRequired,
    buttons: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            onClick: PropTypes.func,
        })
    ),
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default Alert;
