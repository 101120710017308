import React from 'react';
import {Page,Card} from '../../common/fieldfactory/index';

const Search = (props) => {
    return (
        <Page header="Find Workout" >
            <Card header={'Coming soon'}
                  children={'This screen will allow you to find other users workouts that they have made public'}
            />
        </Page>
    );
}

export default Search;