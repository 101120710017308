import {useDispatch, useSelector} from "react-redux";
import callApi from "../../../common/api/Apiutils";
import {useAlert} from "../../../common/fieldfactory/alert/AlertContext";
import {
    loadGoals,
    setEditGoal, setExerciseGoalOpen,
    setGoals,
    setGoalType,
    setGoalValue,
    setGoalWeight,
    setOneRepMaxValue,
    setPage
} from "./Reducer";

export const useReduxActions = () => {
    const handleChangeWeightGoal = useHandleChangeWeightGoal();
    const handleChangeGoalWeight = useHandleChangeGoalWeight();
    const handleSaveGoals = useHandleSaveGoals();
    const handleSetPage = useHandleSetPage();
    const handleSetGoalType = useHandleSetGoalType();
    const handleSetGoalValue = useHandleSetGoalValue();
    const handleSetOneRepMaxValue = useHandleSetOneRepMaxValue();
    const handelSaveGoal = useHandelSaveGoal();
    const handelDeleteGoal = useHandelDeleteGoal();
    const handelSetEdit = useHandelSetEdit();
    const handelSetExerciseGoalOpen = useHandelSetExerciseGoalOpen();
    return {
        handelSetExerciseGoalOpen,
        handelSetEdit,
        handelSaveGoal,
        handelDeleteGoal,
        handleSetGoalType,
        handleChangeGoalWeight,
        handleChangeWeightGoal,
        handleSaveGoals,
        handleSetPage,
        handleSetGoalValue,
        handleSetOneRepMaxValue
    }
}

const useHandelSetExerciseGoalOpen = () => {
    const dispatch = useDispatch();
    return(e)=> {
        dispatch(setExerciseGoalOpen(e))
    }
}

const useHandelSetEdit = () => {
    const dispatch = useDispatch();
    return(goalType , goalNameValue , goalValue,setEdit,edit) => {
        setEdit(!edit);
        dispatch(setEditGoal({goalType,goalNameValue,goalValue}));
    }
}

const useHandelSaveGoal = () => {
    const { newGoal} = useSelector(state => state.goal);
    const {showAlert, hideAlert} = useAlert();
    const dispatch = useDispatch();
    const authorization = useSelector(state => state.default.authorization);
    const {goalType, goalNameValue, goalValue} = newGoal;

    return async () => {
        const newButtons = [
            {
                label: "Yes",
                onClick: async () => {
                    const response = await callApi(`/fit/user/custom/goal`, 'POST', {goalType,goalNameValue,goalValue}, {
                        Authorization: authorization,
                        service: 'UserService'
                    }, undefined, dispatch);


                    if (response.error) {
                        showAlert(response.message);
                    } else {
                        dispatch(loadGoals(response));
                        dispatch(setPage('ALL'));
                    }

                }
            },
            {label: "No", onClick: hideAlert}
        ];
        showAlert('Are you sure you want to create the custom goal?', newButtons);
    }
}

const useHandelDeleteGoal = () => {
    const {showAlert, hideAlert} = useAlert();
    const dispatch = useDispatch();
    const authorization = useSelector(state => state.default.authorization);
    return async (e) => {
        const newButtons = [
            {
                label: "Yes",
                onClick: async () => {
                    const response = await callApi(`/fit/user/delete/goal`, 'PUT', {oneRepMaxName : e}, {
                        Authorization: authorization,
                        service: 'UserService'
                    }, undefined, dispatch);


                    if (response.error) {
                        showAlert(response.message);
                    } else {
                        dispatch(loadGoals(response));
                        dispatch(setPage('ALL'));
                    }

                }
            },
            {label: "No", onClick: hideAlert}
        ];
        showAlert('Are you sure you want to delete the '+e+' goal?', newButtons);
    }
}

const useHandleSetGoalValue = () => {
    const dispatch = useDispatch();
    return (e) => {
        dispatch(setGoalValue(e))
    }
}

const useHandleSetOneRepMaxValue = () => {
    const dispatch = useDispatch();
    return (e) => {
        dispatch(setOneRepMaxValue(e))
    }
}

const useHandleSetGoalType = () => {
    const dispatch = useDispatch();
    return (e) => {
        dispatch(setGoalType(e))
    }
}

const useHandleSetPage = () => {
    const dispatch = useDispatch();
    return (e) => {
        dispatch(setPage(e))
    }
}

const useHandleChangeWeightGoal = () => {
    const dispatch = useDispatch();
    return (e) => {
        dispatch(setGoals(e))
    }
}

const useHandleChangeGoalWeight = () => {
    const dispatch = useDispatch();
    return (e) => {
        dispatch(setGoalWeight(e.target.value))
    }
}

const useHandleSaveGoals = () => {
    const {showAlert, hideAlert} = useAlert();
    const dispatch = useDispatch();
    const {weightGoal, goalWeight} = useSelector(state => state.goal);
    const authorization = useSelector(state => state.default.authorization);
    return async () => {
        const newButtons = [
            {
                label: "Yes",
                onClick: async () => {
                    const response = await callApi(`/fit/user/goals`, 'POST', {weightGoal: weightGoal, goalWeight: goalWeight}, {
                        Authorization: authorization,
                        service: 'UserService'
                    }, undefined, dispatch);

                    if (response.error) {
                        showAlert(response.message);
                    } else {
                        dispatch(loadGoals(response));
                    }

                }
            },
            {label: "No", onClick: hideAlert}
        ];
        showAlert('Are you sure you want to update the weight goal?', newButtons);
    }
}