import React from 'react';

const RadioButton = ({ options, name, onChange, direction = 'vertical' ,selectedOption}) => {


    const handleOptionChange = (event) => {
        onChange(event.target.value);
    };

    return (
        <div className={`form-group ${direction === 'horizontal' ? 'horizontal' : ''}`}>
            {options.map((option, index) => (
                <label key={index} className={`label-container ${direction === 'horizontal' ? 'horizontal' : ''}`}>
                    <input
                        type="radio"
                        name={name}
                        value={option}
                        checked={selectedOption === option}
                        className="radio-button"
                        onChange={handleOptionChange}
                    />
                    {option}
                </label>
            ))}
        </div>
    );
};

export default RadioButton;