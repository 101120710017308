import React from 'react';
import './loader.css'

// Keyframes for the spinning circle
const Loader = () => {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '15vh' }}>
            <div className="spinning-loader"></div> {/* Apply the CSS class */}
            <h5 style={{ marginLeft: '10px' }}>Loading...</h5>
        </div>
    );
};

export default Loader;
