import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    workoutSchedule: [''],
    oneRepMaxesList: [
    ],
    workoutList: [],
    currentWeight: '',
    weightData: {},
    nextWorkout: '',
    newWeight: {
        dateLogged: new Date().toDateString(),
        weightLogged: ''
    },
    selectedDate: new Date().toDateString(),
    page : 'ALL'
};

export const homeSlice = createSlice({
    name: 'home',
    initialState,
    reducers: {
        setPage: (state, action) => {
            state.page = action.payload;
        },
        updateOneRepMaxesListVisibility: (state, action) => {
            const {index, checked} = action.payload;
            const newOneRepMaxesList = [...state.oneRepMaxesList];
            newOneRepMaxesList[index] = {
                ...newOneRepMaxesList[index],
                visible: checked
            };
            state.oneRepMaxesList = newOneRepMaxesList;
        },
        setOneRepMaxesList: (state, action) => {
            state.oneRepMaxesList = action.payload;
        },
        setWorkOutSchedule: (state, action) => {
            const {workOutDates, workoutList, nextWorkout} = action.payload;
            state.workoutSchedule = workOutDates;
            state.nextWorkout = nextWorkout;
            state.workoutList = workoutList;
        },
        setSelectedDate: (state, action) => {
            if (typeof action.payload === 'string') {
                state.selectedDate = action.payload;
            } else {
                state.selectedDate = new Date(action.payload).toISOString();
            }
        },
        setWeightData: (state, action) => {
            state.weightData = action.payload;
        },
        setNewWeight: (state, action) => {
            state.newWeight.weightLogged = action.payload;
        },
        setNewWeightDate: (state, action) => {
            state.newWeight.dateLogged = action.payload;
        },
        setClearNewWeight(state) {
            state.newWeight = {
                dateLogged: new Date().toDateString(),
                weightLogged: ''
            }
        }

    },
});

export const {
    setPage,
    updateOneRepMaxesListVisibility,
    setOneRepMaxesList,
    setWorkOutSchedule,
    setSelectedDate,
    setNewWeightDate,
    setNewWeight,
    setWeightData,
    setClearNewWeight
} = homeSlice.actions;

export default homeSlice.reducer;

