import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    username: '',
    password: '',
    email: '',
    pageState: 'LOGIN',
    loading: false,
};


export const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setUsername: (state, action) => {
            state.username = action.payload;
        },
        setUsernameAndEmail :(state,action) => {
            const {username,email} = action.payload;
            state.username = username;
            state.email = email;
        },
        setPassword: (state, action) => {
            state.password = action.payload;
        },
        setEmail: (state, action) => {
            state.email = action.payload;
        },
        setPageState: (state, action) => {
            state.pageState = action.payload;
        },

    },
});


export const {setLoading, setUsernameAndEmail,setUsername, setPassword, setEmail, setPageState } = loginSlice.actions;


export default loginSlice.reducer;
