import React from 'react';
import Calendar from 'react-calendar';

const WorkoutCalendar = ({ workoutSessions, value, onChange }) => {

    const renderNavigationLabel = ({label }) => {
        return <h3>{label}</h3>;
    };

    return (
        <div className="workout-calendar" data-testid="calendar-tile">
            <Calendar
                onChange={onChange}
                value={value}
                tileClassName={(props) => tileClassName(props, workoutSessions)}
                navigationLabel={renderNavigationLabel}
                next2Label={null}
                prev2Label={null}
            />
        </div>
    );
};

export default WorkoutCalendar;

export const tileClassName = ({ date, view }, workoutSessions) => {
    const dateString = date.toLocaleDateString('en-CA'); // Format date as YYYY-MM-DD
    if (view === 'month') {
        if (workoutSessions.includes(dateString)) {
            const dayOfWeek = date.getDay();

            const prevDate = new Date(date);
            prevDate.setDate(prevDate.getDate() - 1);
            const nextDate = new Date(date);
            nextDate.setDate(nextDate.getDate() + 1);

            const prevDateString = prevDate.toLocaleDateString('en-CA');
            const nextDateString = nextDate.toLocaleDateString('en-CA');

            const prevHighlighted = workoutSessions.includes(prevDateString);
            const nextHighlighted = workoutSessions.includes(nextDateString);
            if (dayOfWeek === 0) { // Sunday
                return prevHighlighted ? 'highlight highlight-end' : 'highlight';
            } else if (dayOfWeek === 1) { // Monday
                return nextHighlighted ? 'highlight highlight-start' : 'highlight';
            }

            if (prevHighlighted && nextHighlighted) {
                return 'highlight highlight-middle';
            } else if (prevHighlighted) {
                return 'highlight highlight-end';
            } else if (nextHighlighted) {
                return 'highlight highlight-start';
            } else {
                return 'highlight';
            }
        }
    }
    return null;
};




