import React, {useEffect} from 'react';
import {ButtonRow} from '../../../common/fieldfactory/index';
import {useReduxActions} from "../logic/Events";
import {useSelector} from "react-redux";
import LinkExerciseList from "./LinkExerciseItem";

const LinkExerciseModal = ({weekIndex,  exerciseModalState,exerciseModalSetState}) => {
    const {weeks} = useSelector(state => state.createWorkOut);
    const {
        handleSetLinkedExerciseWeek,
        handleSetLinkedExerciseDay,
        handleSetLinkedExerciseDefault
    } = useReduxActions();

    const availableWeeks = weeks.slice(0, weekIndex);


    const { linkedExercise } = exerciseModalState;
    const { week, day } = linkedExercise || {};

    useEffect(() => {
        if (!linkedExercise) {
            handleSetLinkedExerciseDefault(exerciseModalSetState);
        }
    }, [linkedExercise, handleSetLinkedExerciseDefault, exerciseModalSetState]);


    const availableDays = availableWeeks.length === 0 || !availableWeeks[week] ? [] : availableWeeks[week].days;

    const exercises = availableDays.length === 0 || !availableDays[day] ? [] : availableDays[day].exercises || [];

    return (
        <div>
            <h3>Linked exercise</h3>
            <h4>Select Week</h4>
            <ButtonRow buttons={availableWeeks} label={'Week'} onClick={(e) => handleSetLinkedExerciseWeek(e,exerciseModalSetState)}
                       buttonSelected={week}/>
            <h4>Select Day</h4>
            <ButtonRow buttons={availableDays} label={'Day'} onClick={(e) => handleSetLinkedExerciseDay(e,exerciseModalSetState)}
                       buttonSelected={day}/>
            {day !== null &&
                <>
                    <h4>Select Exercise</h4>
                    <LinkExerciseList exercises={exercises} setState={exerciseModalSetState} linkedExercise={exerciseModalState.linkedExercise}/>
                </>
            }
        </div>
    );
}

export default LinkExerciseModal;