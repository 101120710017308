import React from 'react';
import {useSelector} from "react-redux";
import {Card} from "../../common/fieldfactory";
import ExercisesComponent from "./components/ExercisesComponent";
import RunComponent from "./components/RunComponent";
import {Page} from '../../common/fieldfactory/index';
import SetupProgramComponent from "./components/SetupProgramComponent";
import NoProgramComponent from "./components/NoProgramComponent";

const RunProgram = () => {
    const {pageState, workoutName, day, week, workoutComplete} = useSelector(state => state.runWorkout);
    const {nextWorkout} = useSelector(state => state.home);
    const currentDay = day + 1;
    const currentWeek = week + 1;
    const dayWeekHeader = pageState !== 'NOPROGRAM' ? `Week - ${currentWeek} Day - ${currentDay}` : '';
    return (
        <Page header="Run Workout" subHeader={workoutName} subHeaderTwo={dayWeekHeader}>
            {!workoutComplete ? ( <>
                {pageState === 'NOPROGRAM' && <NoProgramComponent/>}
                {pageState === 'SETUP' && <SetupProgramComponent/>}
                {pageState === 'START' && <>
                    <ExercisesComponent/>
                </>}
                {pageState === 'RUN' && <>
                    <ExercisesComponent/>
                    <RunComponent/>
                </>}
            </>
            ):(<Card header={'Workout Completed!'}>
                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <h4 style={{marginBottom: 0}}>Next workout</h4>
                        <h5 style={{marginBottom: 0}}>{nextWorkout}</h5>
                    </div>
                </Card>
            )}

        </Page>
    );
};

export default RunProgram;