import {useDispatch, useSelector} from "react-redux";
import callApi from "../../../common/api/Apiutils";
import {useAlert} from "../../../common/fieldfactory/alert/AlertContext";
import {setShowTimer} from "../../../common/statemangment/Reducer";
import {setWorkOutSchedule} from "../../home/logic/Reducer";
import {
    addSet,
    completeSet, deleteSet, rollbackSet,
    setCurrentExercise,
    setRunState,
    setUpRunProgram,
    updateExerciseEdit,
    updateOneRepMax, updateSetLogs,
    updateUserOneRepMaxName
} from "./Reducer";


export const useReduxActions = () => {
    const handelSetMax = useHandleSetMax();
    const handleSetUserOneRepMax = useHandleSetUserOneRepMax();
    const handelSaveSetUp = useHandelSaveSetUp();
    const handleSetRunPage = useSetRunPage();
    const handleCompleteExercise = useCompleteExercise();
    const handleCompleteWorkout = useCompleteWorkout();
    const handleEditExercise = useEditExercise();
    const handleCompleteSet = useHandleCompleteSet();
    const handleRollBackSet = useHandleRollBackSet();
    const handleUpdateSetLogs = useHandleUpdateSetLogs();
    const handleAddSet = useHandleAddSet();
    const handleDeleteSet = useHandleDeleteSet();
    return {
        handleCompleteSet,
        handelSetMax,
        handleSetUserOneRepMax,
        handelSaveSetUp,
        handleSetRunPage,
        handleCompleteExercise,
        handleCompleteWorkout,
        handleEditExercise,
        handleRollBackSet,
        handleUpdateSetLogs,
        handleDeleteSet,
        handleAddSet
    }
}

const useHandleRollBackSet = () => {
    const dispatch = useDispatch();
    return () => {
        dispatch(rollbackSet())
    }
}

const useHandleCompleteSet = () => {
    const dispatch = useDispatch();
    return () => {

        dispatch(completeSet())
    }
}

const useHandelSaveSetUp = () => {
    const authorization = useSelector(state => state.default.authorization);
    const dispatch = useDispatch();
    const {
        setUpProgram
    } = useSelector(state => state.runWorkout);
    return async () => {
        const response = await callApi(`/fit/run`, 'POST', {oneRepMaxLinks: setUpProgram.oneRepMaxLinks}, {
            Authorization: authorization,
            service: 'RunWorkoutService'
        }, undefined, dispatch);
        dispatch(setUpRunProgram(response));
    }
}


const useCompleteWorkout = () => {
    const {showAlert, hideAlert} = useAlert();
    const dispatch = useDispatch();
    const {exercises, sessionStart} = useSelector(state => state.runWorkout);
    const authorization = useSelector(state => state.default.authorization);
    return async (setInputs, index) => {
        const updatedExercises = [...exercises];
        if (index) {
            const {weightCompleted, setsCompleted, ...rest} = updatedExercises[index];
            updatedExercises[index] = {
                ...rest,
                log: setInputs
            };
        }
        const newButtons = [
            {
                label: "Yes",
                onClick: async () => {
                    const response = await callApi(`/fit/run/completeworkout`, 'POST', {
                        exercises: updatedExercises,
                        sessionStart: sessionStart
                    }, {
                        Authorization: authorization,
                        service: 'RunWorkoutService'
                    }, undefined, dispatch);
                    dispatch(setUpRunProgram(response.today));
                    dispatch(setWorkOutSchedule(response.home));
                    dispatch(setShowTimer(false));
                }
            },
            {label: "No", onClick: hideAlert}
        ];
        showAlert('Are you sure you want to complete the workout?', newButtons);
    }
}

const useEditExercise = () => {
    const dispatch = useDispatch();
    return (index) => {
        dispatch(updateExerciseEdit(index));
    }
}

const useCompleteExercise = () => {
    const dispatch = useDispatch();
    return (index, weightCompleted) => {
        dispatch(setCurrentExercise({index: index, weightCompleted: weightCompleted}));
    }
}

const useSetRunPage = () => {
    const dispatch = useDispatch();
    return () => {
        dispatch(setShowTimer(true))
        dispatch(setRunState());
    }
}

const useHandleSetMax = () => {
    const dispatch = useDispatch();
    return (index, val) => {
        dispatch(updateOneRepMax({index, val}))
    }
}

const useHandleSetUserOneRepMax = () => {
    const dispatch = useDispatch();
    return (index, val) => {
        dispatch(updateUserOneRepMaxName({index, val}))
    }
}

const useHandleUpdateSetLogs = () => {
    const dispatch = useDispatch();
    return (value, setIndex, key,index) => {
        dispatch(updateSetLogs({value, setIndex, key,index}))
    }
}

const useHandleAddSet = () => {
    const dispatch = useDispatch();
    return (exerciseIndex) => {
        dispatch(addSet(exerciseIndex));
    }
}

const useHandleDeleteSet = () => {
    const dispatch = useDispatch();
    return (setIndex, exerciseIndex) => {
        dispatch(deleteSet({setIndex, exerciseIndex}))
    }
}

export const getDynamicInitialSetState = (log, weight, reps) => Array.from({length: log === null ? 1 : log.length}, (_, i) => {
    if (log === null) {
        return {weight, reps};
    } else if (log && log.length > i) {
        // Ensure each log entry is correctly loaded, and handle potential null values.
        return {
            weight: log[i]?.weight ?? weight,
            reps: log[i]?.reps ?? reps,
        };
    } else {
        // If log data isn't available, initialize with default values.
        return {weight, reps};
    }
});


export const initialSetState = (log, weight, reps, sets) => Array.from({length: sets}, (_, i) => {
    if (log && log.length > i && log[i] !== null) {
        return log[i];
    } else {
        return {weight: weight, reps: reps};
    }
});
