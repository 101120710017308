import React, {useState} from 'react';
import {useReduxActions} from "../logic/Events";
import { useSelector} from "react-redux";
import {formatFloat} from "../../../common/logic/Events";
import {Button,TextBox,ButtonRow,DropDown} from '../../../common/fieldfactory/index';
import LinkExerciseModal from "./LinkExerciseModal";

const ExerciseModal = ({pageState, setShowCreate,weekIndex,dayIndex,exerciseIndex}) => {
    const {
        repList,
        roundValueList,
        weeks,
        oneRepMaxList
    } = useSelector(state => state.createWorkOut);
    const isEditMode = pageState === 'EDIT';
    const buttons = [
        { name: '0', label: 'RPE' },
        { name: '1', label: 'Intensity' },
        { name: '2', label: 'Set Weight' },
        { name: '3', label: 'Link exercise' }
    ];

    const getInitialState = () => {
        if (isEditMode) {
            const exercise = weeks[weekIndex].days[dayIndex].exercises[exerciseIndex];
            if (exercise) {
                return {
                    exerciseMode: exercise.linkedExercise ? 3 : exercise.weight !== null && !isNaN(exercise.weight) ? 2 :
                        exercise.intensity !== null && !isNaN(exercise.intensity) ? 1 :
                            exercise.rpe !== null ? 0 : null,
                    amrAp: exercise.repetitionType === null ? '' : exercise.repetitionType,
                    exerciseName: exercise.name,
                    sets: exercise.sets,
                    reps: exercise.reps,
                    weight: exercise.weight,
                    intensity: exercise.intensity,
                    rpe: exercise.rpe,
                    increment: exercise.increment,
                    roundWeight: exercise.roundWeight,
                    oneRepMaxLink: exercise.oneRepMaxLink,
                    linkedExercise : exercise.linkedExercise,
                };
            }
        }

        return {
            exerciseMode: null,
            amrAp: '',
            exerciseName: '',
            sets: '',
            reps: null,
            weight: null,
            intensity: null,
            rpe: null,
            increment: null,
            roundWeight: null,
            linkedExercise : {
                week: undefined,
                day: undefined,
                exercise: undefined,
                exerciseName: undefined
            },
            oneRepMaxLink: oneRepMaxList.length !== 0 ? oneRepMaxList[0].value : ''
        };
    };

    const [state, setState] = useState(getInitialState());

    const header = !isEditMode ? `Create Exercise Week ${weekIndex + 1}, Day ${dayIndex + 1}` : `Edit Exercise Week ${weekIndex + 1}, Day ${dayIndex + 1}`;
    const handleInputChange = (name, value) => {
        setState(prev => ({...prev, [name]: value}));
    };
    const {
        handleAddOrUpdateExercise
    } = useReduxActions();
    return (
        <>
            <h3>{header}</h3>
            <TextBox label="Exercise Name" value={state.exerciseName}
                     onChange={(e) => handleInputChange('exerciseName', e.target.value)}/>
            {state.amrAp === 'Custom' || state.amrAp === 'AMRAP' ? (
                <>
                    <TextBox label="Sets" type="number" value={state.sets}
                             onChange={(e) => handleInputChange('sets', parseInt(e.target.value, 10) || 0)}/>
                    {state.amrAp === 'AMRAP' && (
                        <DropDown value={state.amrAp} options={repList}
                                  label="Repetition options" onChange={(e) => handleInputChange('amrAp', e)}/>
                    )}
                    {state.amrAp !== 'AMRAP' && (
                        <div className="flex-row-container">
                            <div className="flex-item-half">
                                <TextBox label="Reps" type="number" value={state.reps}
                                         onChange={(e) => handleInputChange('reps', parseInt(e.target.value, 10) || 0)}/>
                            </div>
                            <div className="flex-item-half">
                                <DropDown value={state.amrAp} options={repList}
                                          label="Repetition options" onChange={(e) => handleInputChange('amrAp', e)}/>
                            </div>
                        </div>
                    )}
                </>
            ) : (
                <DropDown value={state.amrAp} options={repList}
                          label="Repetition options" onChange={(e) => handleInputChange('amrAp', e)}/>
            )}

            <h4>Effort Determination Method</h4>
            {/*<ButtonRow buttons={buttons} onClick={(index) => handleInputChange('exerciseMode', index)} />*/}
            <ButtonRow buttons={buttons} onClick={(e) => {
                setState(prev => ({...prev, 'exerciseMode': e}))
            }} buttonSelected={state.exerciseMode}
                       label={'test'}/>
            {(state.exerciseMode === 3) &&
                <LinkExerciseModal weekIndex={weekIndex}  exerciseModalState={state} exerciseModalSetState={setState}/>
            }
            {(state.exerciseMode === 2) &&
                <TextBox label="Weight" type="number" value={state.weight}
                         onChange={(e) => handleInputChange('weight', formatFloat(e.target.value, 1000))}/>
            }
            {state.exerciseMode === 1 &&
                <div className="row-container">
                    <div className="flex-row-container">
                        <div className="flex-item-half">
                            <TextBox label="Intensity" type="number" value={state.intensity}
                                     onChange={(e) => handleInputChange('intensity', formatFloat(e.target.value, 100))}/>
                        </div>
                        <div className="flex-item-half">
                            <DropDown value={state.oneRepMaxLink} options={oneRepMaxList}
                                      label="One Rep Max" onChange={(e) => handleInputChange('oneRepMaxLink', e)}/>
                        </div>
                    </div>
                </div>
            }

            {(state.exerciseMode === 0) && (
                <TextBox label="RPE" type="text" value={state.rpe}
                         onChange={(e) => handleInputChange('rpe', e.target.value)}/>
            )}
            <div className="flex-row-container mt-10">
                <div className="flex-item-half">
                    <TextBox label="Increment by" type="number" value={state.increment}
                             onChange={(e) => handleInputChange('increment', e.target.value)}/>
                </div>

                <div className="flex-item-half">
                    <DropDown value={state.roundWeight} options={roundValueList}
                              label="Round weight to closest" onChange={(e) => handleInputChange('roundWeight', e)}/>
                </div>
            </div>
            <div className="button-group">
                <Button
                    label={isEditMode ? "Update Exercise" : "Add Exercise"}
                    className="button mt-10 half-width"
                    onClick={() => handleAddOrUpdateExercise(pageState,setShowCreate, exerciseIndex, dayIndex,
                        weekIndex, state)}
                />
                <Button
                    label={"Cancel"}
                    className="button mt-10 button-secondary half-width"
                    onClick={() => setShowCreate(false)}
                />
            </div>
        </>
    );
};

export default ExerciseModal;
