import React from 'react';
import {Page} from '../../common/fieldfactory/index'
import OneRepMaxComponent from "./components/OneRepMaxComponent";
import ProgramsComponent from "./components/ProgramsComponent";
import ScheduleComponent from "./components/ScheduleComponent";

const CreateWorkout = () => {
    return (
        <Page header={'Create Workout'}>
            <ProgramsComponent/>
            <OneRepMaxComponent/>
            <ScheduleComponent/>
        </Page>
    );
};

export default CreateWorkout;
