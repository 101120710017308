// rootReducer.js
import { combineReducers } from '@reduxjs/toolkit';
import defaultReducer from './Reducer';
import createWorkoutReducer from '../../pages/createworkout/logic/Reducer'; // Update path as needed
import loginReducer from '../../pages/login/logic/Reducer'
import settingReducer from '../../pages/settings/logic/Reducer'
import runWorkoutReducer from '../../pages/runworkout/logic/Reducer'
import homeReducer from '../../pages/home/logic/Reducer'
import nutritionReducer from '../../pages/nutrition/logic/Reducer'
import goalsReducer from '../../pages/goals/logic/Reducer'

const rootReducer = combineReducers({
    default: defaultReducer,
    createWorkOut: createWorkoutReducer,
    runWorkout: runWorkoutReducer,
    login: loginReducer,
    goal : goalsReducer,
    setting:settingReducer,
    home : homeReducer,
    nutrition : nutritionReducer
});

export default rootReducer;
