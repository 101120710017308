import React from 'react';

const HorizontalWrapper = ({ children }) => {
    return (
        <div
            className="horizontal-wrapper"
        >
            {children}
        </div>
    );
};

export default HorizontalWrapper;
