import React, {useState} from 'react';
import {FaArrowDown, FaArrowUp} from "react-icons/fa";
import {useSelector} from "react-redux";
import {Button, ButtonRow, Card, IconButton, TextBox} from "../../../common/fieldfactory";
import {useReduxActions} from "../logic/Events";

const NutritionSettings = () => {
    const {nutrition} = useSelector(state => state.setting);
    const {categories} = nutrition;

    const [pageState, setPageState] = useState('ALL');
    const displayConfigure = pageState === 'ALL';

    return (
        <Card header={'Nutrition settings'}>
            {displayConfigure ? (<CreateCategories categories={categories} setPageState={setPageState}/>) :
                (<ConfigureDefaultMealOptions categories={categories} setPageState={setPageState}
                                              index={pageState}/>)
            }
        </Card>
    )
}

const CreateCategories = ({categories, setPageState}) => {
    const {
        handleDecreaseOrder,
        handleIncreaseOrder,
        handleDeleteCategory,
        handleAddCategory,
        handleEditCategory,
        handleSaveCategories
    } = useReduxActions();
    const categoriesLength = categories.length;
    return (
        <>
            <h4>Meal categories</h4>
            <div className="category-list">
                {categories.map((category, index) => (
                    <div key={'category' + index}>
                        <div className="flex-row-container" key={'flex-row-container' + index}>
                            <div className="flex-item-half" key={'flex-item-half' + index}>
                                <TextBox type="text" id={index + 'category'} label="Category name"
                                         value={category.name}
                                         onChange={(e) => handleEditCategory(e.target.value, index)}
                                         className="form-control" readOnly={false}/>
                            </div>
                            {arrowUpCheck(index) && <div style={{marginTop: "15px"}}>
                                <IconButton onClick={() => handleDecreaseOrder(index)} icon={<FaArrowUp/>}/>
                            </div>}
                            {arrowDownCheck(categoriesLength, index) && <div style={{marginTop: "15px"}}>
                                <IconButton onClick={() => handleIncreaseOrder(index)} icon={<FaArrowDown/>}/>
                            </div>}
                        </div>
                        <div className="button-group">
                            <Button label="Configure category" className="button button-secondary mt-10 half-width"
                                    onClick={() => setPageState(index)}/>
                            <Button label="Delete category" className="button button-tertiary mt-10 half-width"
                                    onClick={() => handleDeleteCategory(index)}/>
                        </div>
                    </div>
                ))}
                <div className="button-group">
                    <Button label="Add category" className="button button-secondary mt-10 half-width"
                            onClick={() => handleAddCategory()}/>
                    <Button label="Save" className="button mt-10 half-width"
                            onClick={() => handleSaveCategories()}/>
                </div>
            </div>
        </>
    )
}

export default NutritionSettings;

const arrowDownCheck = (categoriesLength, index) => {
    return categoriesLength - 1 !== index;
}

const arrowUpCheck = (index) => {
    return index !== 0;
}

const ConfigureDefaultMealOptions = ({categories, setPageState, index}) => {
    const {name, items} = categories[index];
    const {
        handleSaveCategories,
        handleEditItem,
        handleAddItem,
        handleDeleteItem
    } = useReduxActions();
    const buttons = [{name: '0', label: 'Generate by item'}, {name: '1', label: 'Pre prepared frozen meal'}];
    const [selected, setSelected] = useState(items ? 0 : 1);
    const selectedValue = selected === 0;
    const headerItem = selectedValue ? 'Default generation items' : 'A frozen meal will be used to fill this meal';
    return (
        <>
            <h4>{name} configuration</h4>
            <ButtonRow buttons={buttons} label={''}
                       buttonSelected={selected}
                       onClick={setSelected}/>
            <h5 style={{marginTop: 0}}>{headerItem}</h5>
            {items && items.map((item, itemIndexIndex) => (
                <div key={itemIndexIndex}>
                    <TextBox type="text" id={itemIndexIndex + 'item'} label="Item name"
                             value={item.name}
                             onChange={(e) => handleEditItem(e.target.value, index, itemIndexIndex)}
                             className="form-control" readOnly={false}/>
                    <div className="button-container-right">
                        <Button label="Delete item" className="button half-width button-tertiary mt-10"
                                onClick={() => handleDeleteItem(index, itemIndexIndex)}/>
                    </div>
                </div>
            ))}
            {selectedValue &&<div className="button-group">
                <Button label="Add item" className="button half-width button-secondary mt-10"
                        onClick={() => handleAddItem(index)}/>

                <Button label="Save config" className="button mt-10 half-width"
                        onClick={() => handleSaveCategories()}/>
            </div>}
            <Button label="Cancel" className="button button-tertiary mt-10"
                    onClick={() => setPageState('ALL')}/>
        </>
    )
}