import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {TwoCards} from "../../../common/fieldfactory";
import {useReduxActions} from "../logic/Events";
import DatePickerComponent from "./DatePickerComponent";
import LoggingComponent from "./LoggingComponent";
import MacroComponent from "./MacroComponent";
import RemainingCaloriesComponent from "./RemainingCaloriesComponent";

const FoodLogComponent = () => {

    const {mobile} = useSelector(state => state.default);
    const {
        handleSetPage
    } = useReduxActions();
    const {
        page
    } = useSelector(state => state.nutrition);
    const [expandedComponent, setExpandedComponent] = useState(page ==='LOGGING');


    const handleCardClick = () => {
        if (mobile) {
            setExpandedComponent(prevState => !prevState);
            if (expandedComponent) {
                handleSetPage('ALL');
            } else {
                handleSetPage('LOGGING');
            }
        }
    };

    useEffect(() => {
        if (mobile) {
            setExpandedComponent(false);
        } else {
            setExpandedComponent(true);
        }
    }, [mobile]);

    useEffect(() => {
        if (mobile) {
            if (page === 'LOGGING') {
                setExpandedComponent(true);
            } else if (page === 'ALL') {
                setExpandedComponent(false);
            }
        }
    }, [mobile, page]);
    return (<>
            {mobile ? (
                <>
                    {expandedComponent &&
                        <DatePickerComponent expandedComponent={expandedComponent} handleCardClick={handleCardClick} />
                    }
                    <RemainingCaloriesComponent expandedComponent={!expandedComponent ? expandedComponent : undefined}
                                                handleCardClick={!expandedComponent ? handleCardClick  : undefined}/>
                    {expandedComponent &&
                        <>
                            <MacroComponent/>
                            <LoggingComponent/>
                        </>
                    }
                </>
            ) : (<>
                    <TwoCards card2={<RemainingCaloriesComponent expandedComponent={expandedComponent}
                                                                 setExpandedComponent={setExpandedComponent}
                                                                 handleCardClick={handleCardClick}/>}
                              card1={<MacroComponent/>}/>
                    <LoggingComponent/>
                </>
            )}


        </>
    )

}

export default FoodLogComponent;