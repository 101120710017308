import React from 'react';

const DarkModeIcon = ({className}) => (
    <svg className={className} viewBox="0 0 1024 1024" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 0V1024H1024V0H0Z" fill="#333333"/>
        <path
            d="M846 165L797 164L777 167L764 177L740 178L718 181L713 185L671 189L657 198L645 219V224L640 227V236L635 241L564 244L553 253L504 257L496 261L490 269L443 273L432 285L395 290L365 320L355 319L350 301L322 273L307 268H252L239 273L236 277L191 281L184 289L135 293L121 307V312L117 316L112 357V409L113 452L117 455V465L121 469L125 506L133 519V530L141 540L145 558L153 569L157 581L179 607L196 618L206 620L253 619L257 615H265L272 607H321L328 599H353L356 594L363 591L380 588L397 614L405 619V626L408 628V656L396 777V817L407 823L419 835L456 836L467 825V773L471 766V742L476 738L474 688L479 685V668L483 664H501L504 667L505 796L509 803L513 884L521 892H568L580 879H585L592 871L601 867L607 861L608 855H625L648 835L655 831H665L680 816L678 761L675 754L674 731L671 729L666 674L663 672L657 607L691 567L693 560L731 521V516L735 512L797 511L804 503L835 502L853 495L856 491H881L885 487H899L920 468V463L927 457L926 299L923 297L920 268L911 256L907 236L895 217L894 204L876 190L861 173H856L846 165Z"
            fill="#333333"/>
        <path
            d="M128 364V390L129 398L138 465L159 531L183 577L198 593L211 600L232 602L241 600L256 592L261 586L276 590L291 589L306 582L314 573L325 577H340L353 572L364 562L372 545L377 493L376 481L375 465L379 464L381 482L383 492L396 566L410 593L427 609L424 653L423 658L420 703L419 706L416 752L415 757L412 801L446 822L448 812L449 807V798L450 792V782L451 776V764L452 757L455 688L456 682L458 642V627L479 632L500 631L516 625L527 617L530 636L540 730L541 735L542 766L543 774V779V786L541 779L523 701L519 688L518 699L519 705V719L520 724V736L521 741L524 788L525 794L528 840L529 845L531 873L539 879L588 848L589 792V709L592 678L595 709L600 765L601 770L605 837L663 799L645 665L644 662L635 596L726 486L744 495L767 496L785 489L794 480L808 484L828 482L842 474L846 468L859 472L876 471L890 464L900 454L907 441L915 402V360L906 304L892 259L878 230L864 209L840 188L821 181H805L789 187L781 196L757 192L739 197L729 207L716 202H700L680 210L667 225L658 246L654 272L655 316L637 276L626 264L608 257L586 258L573 264L565 274L550 269L530 270L515 276L505 288L495 285L471 286L458 292L447 306L424 304L408 309L367 353L371 373L355 377L342 340L321 305L309 293L295 285L274 283L259 288L251 297L222 294L205 300L199 308L175 304L160 307L149 313L137 327L132 339L128 364Z"
            fill="white"/>
        <path d="M375 465L376 481L378 484L381 482L379 464L375 465Z" fill="#020101"/>
        <path d="M458 627V642L480 647H497L513 642L527 633L530 636L527 617L516 625L500 631L479 632L458 627Z"
              fill="#5B5A5A"/>
        <path d="M592 678L589 709H595L592 678Z" fill="#5B5A5A"/>
        <path d="M376 481L377 493L383 492L381 482L378 484L376 481Z" fill="#444343"/>
        <path d="M543 786V779H541L543 786Z" fill="#919090"/>
        <path
            d="M876 319L886 379L887 384L888 412L890 407V353L889 348L884 314L869 266L850 230L831 211L829 214L840 233L841 241L816 209L805 203L794 204L814 224L837 268L854 319L862 357L861 323L876 319Z"
            fill="#020101"/>
        <path
            d="M809 373L810 348L809 344L808 332L823 330L835 392L836 368L835 362L830 322L819 288L800 247L786 228L779 230L787 251L789 256L786 252L761 218L751 213L740 215L757 232L779 272L796 318L809 373Z"
            fill="#020101"/>
        <path
            d="M721 244L714 248L734 286L749 329L761 387L764 434L763 444V467L771 440L773 388L772 384L768 351L758 319V315L771 311L756 276L741 253L738 256L750 288L740 269L721 244Z"
            fill="#020101"/>
        <path d="M613 280H597L585 288L583 296L603 342L643 338L619 284L613 280Z" fill="#020101"/>
        <path
            d="M553 365V374L552 381V389L551 394V417L557 424L571 425L582 418L562 393L559 369L563 358L570 350L586 346V343L560 293L536 292L525 302V309L553 365Z"
            fill="#020101"/>
        <path
            d="M501 381L500 398L499 405V416V430L503 436L510 439L524 437L531 431L534 393L526 396L516 394L513 382L529 381L536 372L535 364L508 311L499 305L476 310L471 316V326L501 381Z"
            fill="#020101"/>
        <path
            d="M228 318L211 315L232 342L252 386L270 443L278 486L279 460L275 437L291 434L304 487L303 458L297 423L287 389L271 351L257 329L244 318L240 316L238 318L250 336L255 357L238 328L228 318Z"
            fill="#020101"/>
        <path
            d="M297 316L294 319L303 342L305 353L303 349L297 352L313 400L325 399L340 473L341 476L344 516L345 522V537L347 536L351 508L352 502L348 444L335 390L315 340L297 316Z"
            fill="#020101"/>
        <path
            d="M449 324L439 323L424 328L395 365L422 357L440 387L448 393L449 442L457 448H469L478 442L482 407L469 409L463 404L462 397L476 396L481 393L484 386L456 330L449 324Z"
            fill="#020101"/>
        <path
            d="M715 330L706 324L673 333L687 334L699 341L712 340L714 347L706 348V351L709 355L718 353L720 363L715 364V368L722 370V382L728 394L729 379L725 352L715 330Z"
            fill="#020101"/>
        <path
            d="M184 327L197 356L198 357V360L201 370L196 361L195 360L186 345L181 348L184 359L181 355L177 356V359L203 430L213 478L216 514L217 519L218 565L223 551L226 528L225 485L224 481L219 451L235 448L245 485L247 504L248 485L247 481L242 442L227 391L203 342L195 333L184 327Z"
            fill="#020101"/>
        <path
            d="M638 388L623 389L618 390H610L601 382L632 378L627 359L615 360L610 361H605L602 362H597L592 363H587L583 367V379L588 389L604 401L632 409H647L661 404L674 392V407L663 422L675 446L680 455H675L625 442L577 468L554 492L552 491L546 510V533L552 553L533 534L516 522L514 526L512 551L499 523L486 510L470 501L439 495L466 511V515L434 504L418 505L426 549L436 574L457 595L482 603L501 602L516 596L531 583L539 567L556 580L576 586H592L612 579L626 566L595 568L579 562L594 557L608 548L639 517L640 516L652 503L654 501L653 503L644 517L639 526V531L642 532L715 452L721 442L722 428L691 362L682 354L642 358L648 373L646 383L638 388Z"
            fill="#020101"/>
        <path d="M363 396L368 421L369 424L429 410V404H426L374 417H372L370 409L424 396L417 384L363 396Z" fill="#020101"/>
        <path
            d="M162 418L151 412L146 418L145 431L153 429L158 434L167 455L171 474L170 493L167 497L161 498L170 511L177 512L183 496L181 466L172 435L162 418Z"
            fill="#020101"/>
        <path d="M429 421H425L373 434L372 440L427 428L429 421Z" fill="#020101"/>
        <path d="M555 622V626L554 644V724L560 697L563 659L564 655L565 634V628L555 622Z" fill="#020101"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M722 233V231L736 245L732 247L722 233ZM689 254L680 277L677 312L673 296L676 272L689 254ZM166 339L154 323L168 327L174 332L173 336L179 348L169 337L166 339ZM675 446L651 430L663 422L675 446ZM552 491L560 473L579 452L609 437L625 442L577 468L554 492L552 491ZM418 505L414 487L449 492L470 501L439 495L466 511V515L434 504L418 505ZM514 526L504 489L520 508L533 534L516 522L514 526ZM333 534L332 553L322 564L333 534ZM279 540V562L273 573L268 576L276 560L279 540ZM555 622V606L566 611L565 634V628L555 622Z"
              fill="#5B5A5A"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M829 214L820 204L831 211L829 214ZM779 230L769 215L786 228L779 230ZM714 248L694 224L707 230L721 244L714 248ZM732 247L736 245L741 253L738 256L732 247ZM297 352L281 322L269 309L270 306L284 316L303 349L297 352ZM294 319L287 308L297 316L294 319ZM166 339L169 337L179 348L173 336L174 332L186 345L181 348L184 359L181 355L177 356V359L166 339ZM811 440L812 457L802 471L809 455L811 440ZM642 532L622 550L639 526V531L642 532ZM345 537L347 536L346 541L345 537Z"
              fill="#444343"/>
        <path d="M195 360H196V361L198 360L201 370L196 361H195V360Z" fill="#3B3C3C"/>
        <path d="M652 503L640 516L644 517L653 503H652Z" fill="#3B3C3C"/>
        <path d="M720 232L722 233V231L720 232Z" fill="#919090"/>
        <path d="M787 251L786 252L789 256L787 251Z" fill="#919090"/>
        <path d="M863 450L864 430L862 442L854 459L863 450Z" fill="#919090"/>
        <path d="M654 501L652 503H653L654 501Z" fill="#919090"/>
        <path
            d="M642 468H623L616 471L601 487H591L585 495L577 498L575 513L567 523V536L576 540L583 537L591 528H596L628 496V491L638 481L646 478V472L642 468Z"
            fill="black"/>
        <path d="M444 548L442 552L447 567L460 581L475 588H494L477 583L464 575L444 548Z" fill="white"/>
        <path d="M198 356H197V357H198V356Z" fill="#3B3C3C"/>
        <path d="M640 516H639V517H640V516Z" fill="#3B3C3C"/>
        <path d="M441 544L442 552L444 548L441 544Z" fill="#3B3C3C"/>
    </svg>
)

export default DarkModeIcon;