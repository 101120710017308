import {useDispatch, useSelector} from "react-redux";
import callApi from "../../../common/api/Apiutils";
import {useAlert} from "../../../common/fieldfactory/alert/AlertContext";
import {setPage} from "./Reducer";
import {setClearNewWeight, setNewWeight, setNewWeightDate, setSelectedDate, setWeightData} from "./Reducer";


export const useReduxActions = () => {
    const handleSetDate = useHandleSetDate();
    const handleSetLoggedWeight = useHandleSetLoggedWeight()
    const handelSetNewWeightDate = useHandelSetNewWeightDate();
    const handleSaveNewWeightData = useHandleSaveNewWeightData();
    const handleSetPage = useHandleSetPage();
    return {
        handleSetDate,
        handleSetLoggedWeight,
        handelSetNewWeightDate,
        handleSaveNewWeightData,
        handleSetPage
    }
}

const useHandleSetPage = () => {
    const dispatch = useDispatch();
    return (page) => {
        dispatch(setPage(page))
    }
}

const useHandleSetLoggedWeight = () => {
    const dispatch = useDispatch();
    return (e) => {
        dispatch(setNewWeight(e.target.value))
    }
}

const useHandelSetNewWeightDate = () => {
    const dispatch = useDispatch();
    return (e) => {
        dispatch(setNewWeightDate(e.toDateString()))
    }
}

const useHandleSaveNewWeightData = () => {
    const dispatch = useDispatch();
    const {dateLogged, weightLogged} = useSelector(state => state.home.newWeight);
    const {showAlert} = useAlert();
    const authorization = useSelector(state => state.default.authorization);
    return async (setWeight) => {
        setWeight(prevWeight => !prevWeight);

        const response = await callApi('/fit/user/weight', 'POST', {
            date: dateLogged,
            weight: weightLogged
        }, {
            service: 'UserService',
            Authorization: authorization,
        }, undefined, dispatch)
        if (response.error) {
            showAlert(response.message);
            return;
        }
        dispatch(setClearNewWeight())
        dispatch(setWeightData(response))
    }
}

const useHandleSetDate = () => {
    const dispatch = useDispatch();
    return (e) => {
        let date;

        if (e instanceof Date) {
            date = e;
        } else {
            date = new Date(e);
        }

        dispatch(setSelectedDate(date.toDateString()));
    }
}

export const workoutInFuture = (dateString, workoutLog) => {

    if (workoutLog) {
        const {workout} = workoutLog;

        const log = workout.exercises?.[0]?.log;

        if (!log) {
            return true;
        }
    }


    const givenDate = new Date(dateString);
    const today = new Date();

    givenDate.setHours(0, 0, 0, 0);
    today.setHours(0, 0, 0, 0);

    return givenDate > today;
}

export const convertToDateFormat = (dateString) => {
    // Parse the date string
    const date = new Date(dateString);

    // Extract the year, month, and day
    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();

    // Ensure month and day are two digits
    month = month < 10 ? '0' + month : month;
    day = day < 10 ? '0' + day : day;

    // Format the date as yyyy-MM-dd
    return `${year}-${month}-${day}`;
}