import React from 'react';

const DumbbellOutlined = ({className}) => (
    <svg className={className} viewBox="0 0 395 300" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="65" y="5" width="40" height="290" rx="5" stroke="currentColor" strokeWidth="20"/>
        <rect x="25" y="55" width="40" height="190" rx="5" stroke="currentColor" strokeWidth="20"/>
        <path d="M0 150C0 144.477 4.47715 140 10 140H20V160H10C4.47715 160 0 155.523 0 150V150Z" fill="currentColor"/>
        <rect x="330" y="295" width="40" height="290" rx="5" transform="rotate(180 330 295)" stroke="currentColor"
              strokeWidth="20"/>
        <rect x="370" y="245" width="40" height="190" rx="5" transform="rotate(180 370 245)" stroke="currentColor"
              strokeWidth="20"/>
        <path d="M395 150C395 155.523 390.523 160 385 160H375V140H385C390.523 140 395 144.477 395 150V150Z"
              fill="currentColor"/>
        <rect x="110" y="140" width="175" height="20" fill="currentColor"/>
    </svg>

);


export default DumbbellOutlined;
