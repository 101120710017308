import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
    CreateWorkoutIcon,
    FindWorkoutIcon, GoalsIcon,
    HomeIcon,
    NutritionIcon,
    RunWorkoutIcon,
    useReduxActions
} from "../../logic/Events";
import MobileNavButton from "../button/MobileNavButton";

const BottomNav = () => {
    const dispatch = useDispatch();
    const {page,darkMode} = useSelector(state => state.default);
    const {
        handleCreateWorkoutNavigation,
        handleRunWorkoutNavigation,
        handleNavigation,
        handleNutritionNavigation
    } = useReduxActions();

    const className = "nav-icon";

    return (
        <div id="bottom-nav" className={'bottom-nav'}>
            <ul style={{marginBottom:'15px'}}>
                <MobileNavButton children={HomeIcon({page,className})} onClick={() => handleNavigation('HOME', dispatch)}/>
                <MobileNavButton children={CreateWorkoutIcon({page,className})} onClick={() =>  handleCreateWorkoutNavigation(dispatch)}/>
                <MobileNavButton children={RunWorkoutIcon({page,className : 'nav-icon-dumbbell'})} onClick={() =>  handleRunWorkoutNavigation(dispatch)}/>
                <MobileNavButton children={GoalsIcon({page,className})} onClick={() => handleNavigation('GOALS', dispatch)}/>
                <MobileNavButton children={FindWorkoutIcon({page,className})} onClick={() => handleNavigation('FINDWORKOUT', dispatch)}/>
                <MobileNavButton children={NutritionIcon({page,className : page !== 'NUTRITION' ? className : darkMode === 'green' ? 'nav-icon-carrot': className})} onClick={() => handleNutritionNavigation(dispatch)}/>
            </ul>
        </div>
    );
};

export default BottomNav;
