import React, {useState} from "react";
import {useSelector} from "react-redux";
import {useReduxActions} from "../logic/Events";
import {TextBox,RadioButton,DropDown} from '../../../common/fieldfactory/index';


const OneRepMaxComponent = ({index, oneRepMax}) => {

    const options = ['Link existing record', 'Create new record'];
    const [selectedOption, setSelectedOption] = useState(oneRepMax.userOneRepMaxName !== '' ? 'Link existing record' : 'Create new record');
    const {
        setUpProgram
    } = useSelector(state => state.runWorkout);
    const {
        handelSetMax,
        handleSetUserOneRepMax
    } = useReduxActions();
    const handleRadioChange = (selectedOption) => {
        setSelectedOption(selectedOption);
    };
    const layout = selectedOption === 'Link existing record';
    return (
        <>
            <h4 key={index}>
                {oneRepMax.programOneRepMaxName}
                <br/>
            </h4>
            <RadioButton options={options} name={`mode-${index}`} onChange={handleRadioChange} direction="vertical"
                         selectedOption={selectedOption}/>
            <div className="form-group">
                {!layout &&
                    <TextBox type="text" id={oneRepMax.programOneRepMaxName} label="Max weight" value={oneRepMax.max}
                             onChange={(e) => handelSetMax(index, e.target.value)}
                             className="form-control" readOnly={false}/>}
                {layout && <DropDown label="Link existing one rep maxes"
                                     options={setUpProgram.userOneRepMaxes}
                                     onChange={(e) => handleSetUserOneRepMax(index, e)}
                                     value={oneRepMax.userOneRepMaxName}
                />}
            </div>
        </>
    )
}

export default OneRepMaxComponent;