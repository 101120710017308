import React from "react";
import {useSelector} from "react-redux";
import WorkoutItem from "./WorkoutItem";
import {Card} from '../../../common/fieldfactory/index';

const ProgramSettings = () => {

    const {currentProgram, userWorkouts} = useSelector(state => state.setting.program);

    return (
        <Card header="Workout Settings">
            <h3>Current Program: {currentProgram.name} | Week: {currentProgram.week} | Day: {currentProgram.day}</h3>
            <p>Program ends on: {currentProgram.endDate}</p>
            <h4>Your Workouts</h4>
            <div className={'exercise-list'}>
                {userWorkouts.map((workout, index) => (
                    <WorkoutItem key={index} workout={workout} index={index}/>
                ))}
            </div>
        </Card>
    );
}

export default ProgramSettings;