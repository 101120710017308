import React from 'react';


const MobileNavButton = ({children , onClick}) => {

    return (
        <li onClick={onClick}>
            <button
                className="tooltip link-button"
            >
                {children}
            </button>
        </li>
    )
}

export default MobileNavButton;