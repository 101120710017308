import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {FaEdit, FaSave, FaTrash} from "react-icons/fa";
import {useReduxActions} from "../logic/Events";
import ExerciseModal from "./ExerciseModal";
import {IconButton} from '../../../common/fieldfactory/index';

const ExerciseItem = ({index, exercise, thisDayIndex, thisWeekIndex}) => {
    const renderField = (label, value) => {
        if (value !== null && value !== '' && value !== undefined) {
            return (
                <h5><strong>{label}:</strong> {value}</h5>
            );
        }
        return null;
    };
    const {
        handleOpenModalForEdit,
        handleDeleteExercise
    } = useReduxActions();
    const [state, setState] = useState(false);
    const heading = <h4>Exercise {index + 1} - {exercise.name}</h4>;

    return (
        <>{!state && <div className={'exercise-item'}>
            <div className="side-by-side-div">
                <div className="left-content">
                    {!state && <><IconButton icon={<FaEdit size={20}/>} onClick={() => {
                        handleOpenModalForEdit(index,thisDayIndex,thisWeekIndex)
                        setState(true)
                    }}/>
                        {heading}
                    </>}
                    {state && <>
                        <IconButton icon={<FaSave size={20}/>} onClick={() => {
                            setState(false)
                        }}/>
                        {heading}
                    </>}
                </div>
                <IconButton icon={<FaTrash size={20}/>} onClick={() => handleDeleteExercise(thisDayIndex,index,thisWeekIndex)}/>
            </div>
            <>
                {renderField('Repetition Type', exercise.repetitionType)}
                {renderField('Sets', exercise.sets)}
                {renderField('Reps', exercise.reps)}
                {renderField('Weight', exercise.weight)}
                {renderField('Intensity', exercise.intensity !== null ? `${exercise.intensity}%` : null)}
                {renderField('One Rep Max Link', exercise.oneRepMaxLink)}
                {renderField('RPE', exercise.rpe)}
                {renderField('Linked Exercise', exercise.linkedExerciseName ? `Week - ${exercise?.linkedExercise?.week+1} Day - ${exercise?.linkedExercise?.day+1} ${exercise.linkedExerciseName}` : null)}
                {renderField('Increment By', exercise.increment)}
                {renderField('Round to closest', exercise.roundWeight)}
            </>
        </div>}

            {state&&<ExerciseModal pageState={'EDIT'} setShowCreate={setState} dayIndex={thisDayIndex} weekIndex={thisWeekIndex} exerciseIndex={index}/>}

        </>

    );
};

ExerciseItem.propTypes = {
    exercise: PropTypes.shape({
        name: PropTypes.string.isRequired,
        repetitionType: PropTypes.string,
        sets: PropTypes.number,
        reps: PropTypes.number,
        weight: PropTypes.number,
        intensity: PropTypes.string,
        oneRepMaxLink: PropTypes.string,
        rpe: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        linkedExercise: PropTypes.object,
        linkedExerciseName: PropTypes.string,
        increment: PropTypes.string,
        roundWeight: PropTypes.string,
    }).isRequired,
};

const ExerciseList = ({exercises, dayIndex, weekIndex}) => {


    return (<>
        <div className={'exercise-list'}>
            {exercises.map((exercise, index) => (
                <ExerciseItem key={index} exercise={exercise} index={index} thisDayIndex={dayIndex}
                              thisWeekIndex={weekIndex}/>
            ))}
        </div>
    </>);
};

ExerciseList.propTypes = {
    exercises: PropTypes.arrayOf(PropTypes.object).isRequired,
};



export default ExerciseList;