// defaultSlice.js
import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    authenticated: false,
    sideBarOpen: true,
    page: 'LOGIN',
    showTimer : false,
    authorization: '',
    mobile : false,
    darkMode : 'light'
};

export const defaultSlice = createSlice({
    name: 'default',
    initialState,
    reducers: {
        setIsMobile : (state , action) => {
            state.mobile = action.payload;
        },
        setShowTimer : (state , action) => {
            state.showTimer = action.payload;
        },
        setAuthenticated: (state, action) => {
            state.authenticated = action.payload;
        },
        setSideBarOpen: (state, action) => {
            state.sideBarOpen = action.payload;
        },
        setPage: (state, action) => {
            state.page = action.payload;
        },
        setAuthorization: (state, action) => {
            state.authorization = action.payload;
        },
        setNonAuthorized: (state) => {
            state.authorization = '';
            state.page = 'LOGIN';
            state.authenticated = false;
            state.showTimer = false;

        },
        setIsDarkMode: (state,action) => {
            state.darkMode = action.payload;
        }
    },
});

export const {setIsDarkMode,setIsMobile,setShowTimer,setNonAuthorized,setAuthorization, setPage, setAuthenticated, setSideBarOpen} = defaultSlice.actions;
export default defaultSlice.reducer;
