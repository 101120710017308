export const IsEmptyOrSpaces = (str) => {
    return str === null || str.match(/^ *$/) !== null;
}

export const formatFloat = (inputValue,maxValue) => {

    const cleanedInput = inputValue.replace(/^0+/, '');
    const value = parseFloat(cleanedInput);
    if (isNaN(value)) {
        return 0;
    }

    return Math.min(maxValue, Math.max(0, parseFloat(value.toFixed(2))));
}

