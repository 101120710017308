import React from 'react';
import {useSelector} from "react-redux";
import {Page} from "../../common/fieldfactory";
import CreateGoalComponent from "./components/CreateGoalComponent";
import ExerciseGoalsComponent from "./components/ExerciseGoalsComponent";
import SessionGoals from "./components/SessionGoals";
import WeightGoalComponent from "./components/WeightGoal";

const Goals = () => {
    const {page} = useSelector(state => state.goal);
    const pageHeader = page === 'ALL' ? 'Goals' : page === 'WEIGHT' ? 'Weight goal' : page === 'CREATEGOAL' ? 'Create goal' : page === 'EXERCISEGOAL' ? 'One rep max goal' : 'Workout Session Goals';
    const getPageComponent = () => {
        switch (page) {
            case 'ALL':
                return <>
                    <WeightGoalComponent/>
                    <SessionGoals/>
                    <ExerciseGoalsComponent/>
                    <CreateGoalComponent/>
                </>;
            case 'CREATEGOAL':
                return <CreateGoalComponent/>;
            case 'SESSIONGOAL':
                return <SessionGoals/>;
            case 'WEIGHT':
                return <WeightGoalComponent/>;
            case 'EXERCISEGOAL':
                return <ExerciseGoalsComponent/>;
            default: <></>;
        }
    }
    return (
        <Page header={pageHeader}>
            {getPageComponent()}
        </Page>
    );
}

export default Goals;