import React from "react";
import {useSelector} from "react-redux";
import {Card,TextBox,Button} from '../../../common/fieldfactory/index'
import {useReduxActions} from "../logic/Events";

const OneRepMaxComponent = () => {
    const {
        oneRepMaxList
    } = useSelector(state => state.createWorkOut);
    const display = oneRepMaxList.length !== 0;
    const {
        handleDeleteOneRepMax,
        handleAddOneRepMax,
        handleUpdateOneRepMaxName
    } = useReduxActions();


    return (
        <Card header="Required One Rep Maximum's">
            {display && <>
                {oneRepMaxList.slice(1).map((oneRep, index) => (
                    <div className="form-group" key={index}>
                        <TextBox type="text" id="name" label="Exercise name" value={oneRep.value}
                                 onChange={(e) => handleUpdateOneRepMaxName(index, e.target.value)}
                                 className="form-control" readOnly={false}/>
                        {/*<Button label="Edit" className="button mt-10 half-width" onClick={() => setReadOnly(false)}/>*/}
                        <div className="button-container-right">
                            <Button label="Delete On Rep Max" className="button button-tertiary mt-10 half-width"
                                    onClick={() => handleDeleteOneRepMax(index)}/>

                        </div>
                    </div>
                ))}
            </>
            }
            <div className="button-container-right">
                <Button label="Create Record" type="submit" className="button half-width mt-10 mb-10"

                        onClick={handleAddOneRepMax}/>
            </div>
        </Card>);
}

export default OneRepMaxComponent;