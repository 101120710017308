import React from 'react';
import { useSelector } from "react-redux";
import styled, { keyframes, css } from 'styled-components';

// Keyframes to animate the clip-path from bottom to top
const fillUpAnimation = keyframes`
    0% { clip-path: inset(100% 0 0 0); }
    100% { clip-path: inset(0 0 0 0); }
`;

// Styled component to apply the animation to the carrot SVG
const StyledCarrot = styled.svg`
    width: 100px;
    height: 100px;
    ${({ duration }) => css`
        animation: ${fillUpAnimation} ${duration}s ease-out forwards;
    `}
    fill: orangered;
    stroke: currentColor;
`;

const CarrotLoader = () => {
    // Calculate the loading time based on the Redux state
    const recipesToGenerate = useSelector(state => state.nutrition.generation.recipesToGenerate);
    const loadingTime = recipesToGenerate > 4 ? 6 : 10;

    return (
        <div className="load-container">
            <StyledCarrot
                duration={loadingTime} // Pass the loading time as a prop
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 256 256"
                strokeWidth="0"
            >
                <path d="M232,80H199.44a64,64,0,0,1-10.19,77.26c-8.52,8.69-19.61,16.92-31.85,24.51a4,4,0,0,1-4.91-.59l-34.84-34.84a8,8,0,0,0-11.49.18,8.23,8.23,0,0,0,.41,11.38l29.88,29.88a4,4,0,0,1-1,6.39C95.74,214.79,53,228.54,46.78,230.48a16,16,0,0,1-21.26-21.26c2.73-8.71,29-90.27,64.86-133.35a4,4,0,0,1,5.9-.26l42.05,42.06a8,8,0,0,0,11.71-.43,8.19,8.19,0,0,0-.6-11.1L108.08,64.78a4,4,0,0,1,.63-6.18,64,64,0,0,1,67.28-2V24a8,8,0,0,1,8.54-8A8.18,8.18,0,0,1,192,24.28V52.69l26.34-26.35a8,8,0,0,1,11.32,11.32L203.31,64h28.41A8.18,8.18,0,0,1,240,71.47,8,8,0,0,1,232,80Z"></path>
            </StyledCarrot>
            <h5>Generating meal plan...</h5>
        </div>
    );
};

export default CarrotLoader;



