import React from 'react';
import {SlArrowLeft, SlArrowRight} from "react-icons/sl";
import { useSelector } from 'react-redux';
import {IconButton} from "../index";

const Card = ({ header, children, expandable ,expanded ,onClick , collapseOnClick,customHeader }) => {
    const { mobile } = useSelector(state => state.default);
    return (
        <div
            className="large-centered-container"
            onClick={onClick ? onClick: null}
            style={{ cursor: onClick ? 'pointer' : 'default' }}
        >
            {mobile ? (
                <div style={customHeader ? {} :{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    {customHeader ? customHeader :<h3 style={{ margin: 0 }}>{header}</h3>}
                    {expandable&&!expanded && <IconButton icon={<SlArrowRight size={15} />} onClick={() => {}} />}
                    {expandable&&expanded && <IconButton icon={<SlArrowLeft size={15}/>} onClick={collapseOnClick} />}
                </div>
            ) : (
                <>{customHeader ? customHeader :<h2>{header}</h2>}</>
            )}
            {children}
        </div>
    );
};

export default Card;
