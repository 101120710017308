import React from "react";
import {useSelector} from "react-redux";
import {useReduxActions} from "../logic/Events";
import OneRepMaxComponent from "./OneRepMaxComponent";
import {Card,Button} from '../../../common/fieldfactory/index';

const SetupProgramComponent = () => {
    const {
        setUpProgram
    } = useSelector(state => state.runWorkout);
    const {
        handelSaveSetUp
    } = useReduxActions();
    return(
        <Card header={'Set up program'}>
            <p>This program requires you to know your one rep maxes for the following exercises.</p>
            <p>
                {setUpProgram.requiredOneRepMaxes.map((name, index) => (
                    <span key={index}>
                        {index + 1} : {name}
                        <br/>
                    </span>
                ))}
            </p>
            <p>You can either link a current record to these one rep maxes or create a new One rep max record.</p>
            <p>
                {setUpProgram.oneRepMaxLinks.map((oneRepMax, index) => (
                    <OneRepMaxComponent oneRepMax={oneRepMax} index={index}/>
                ))}
            </p>
            <div className="button-container-right">
                <Button label="Save" type="submit" className="button button-secondary mt-10 half-width"
                        onClick={handelSaveSetUp}/>
            </div>
        </Card>
    );

}

export default SetupProgramComponent;