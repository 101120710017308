import React, {useState} from 'react';
import {FaArrowDown, FaArrowUp, FaMinus, FaPlus} from "react-icons/fa";
import {FaX} from "react-icons/fa6";
import {useSelector} from "react-redux";
import {Button, ButtonRow, Card, IconButton, TextBox} from "../../../common/fieldfactory";
import Loader from "../../../common/fieldfactory/loader/Loader";
import {useReduxActions} from "../logic/Events";

const LoggingComponent = () => {

    return (<>
            <MealLogs/>
        </>
    )
}

export default LoggingComponent;

const MealLogs = () => {
    const {meals} = useSelector(state => state.nutrition.logging);
    const {selectedDate} = useSelector(state => state.nutrition);
    const selectedLog = meals.filter(item => item.date === selectedDate);

    if (selectedLog.length === 0) {
        return <></>;
    }
    const {logCategories} = selectedLog[0];

    const mealHeader = (name, calories) => {
        return <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            <h3>{name}</h3>
            <h4 style={{marginTop: 0}}>{calories} Kcal</h4>
        </div>
    }
    return (
        <>
            {
                logCategories.map((meal, mIndex) => (
                    <Card key={mIndex} customHeader={mealHeader(meal.name, meal.totalCalories)}>
                        <Log key={meal.name} meal={meal} mIndex={mIndex} date={selectedDate}/>
                    </Card>
                ))
            }
        </>
    )
}

const Log = ({meal, mIndex, date}) => {
    const {logItems, isAdding, isEditing, isGenerating} = meal;
    const {handleNewLogItem, handleRemoveLogItem, handleEditLogItem, handleUpdateMealLog} = useReduxActions();
    const canEdit = logItems.length !== 0;
    const toggleAddItem = () => {
        if (isAdding) {
            handleRemoveLogItem(mIndex, date);
        } else if (isEditing) {
            handleEditLogItem(mIndex, date);
        } else {
            handleNewLogItem(mIndex, date);
        }
    };

    return (
        <>
            {!isGenerating ? (
                <>
                    {logItems.map((item, index) => (
                        <LogItem item={item} key={index} index={index} mIndex={mIndex} date={date} mealName={meal.name}
                                 isEditing={isEditing}/>
                    ))}
                    {logButtons(isEditing, canEdit, isAdding, toggleAddItem, () => handleEditLogItem(mIndex, date), () => handleUpdateMealLog(mIndex, date))}
                </>
            ) : (
                <Loader/>
            )}
        </>
    )
}

const logButtons = (isEditing, canEdit, isAdding, toggleAddItem, handleEditLogItem, handleUpdateMealLog) => {
    if (canEdit && !isAdding && !isEditing) {
        return (
            <div className="button-group mb-10 mt-10">
                <Button label={'Edit'}
                        onClick={handleEditLogItem}
                        className={"button button-secondary half-width"}/>
                <Button label={isAdding ? 'Cancel' : 'Add food'}
                        onClick={toggleAddItem}
                        className={isAdding ? "button button-tertiary half-width " : "button half-width"}/>
            </div>
        )
    } else {
        const showCancel = isAdding || isEditing;
        return (
            <>
                {isEditing && <Button onClick={handleUpdateMealLog} className="button mt-10" label={'Update'}/>}
                <Button label={showCancel ? 'Cancel' : 'Add food'}
                        onClick={toggleAddItem}
                        className={showCancel ? "button button-tertiary mb-10 mt-10" : "button mb-10 mt-10"}/>
            </>

        )
    }
}


const LogItem = ({item, index, mIndex, date, mealName, isEditing}) => {
    const {
        type,
        name,
        calories,
        newItem,
        fmUUID,
        portion,
        portionUnit
    } = item;
    const {handleSetItemType, handleUpdateNutrition} = useReduxActions();
    const options = [
        {label: 'Frozen meal', value: 'fm'},
        {label: 'Generate meal', value: 'gm'},
    ];
    const {frozenMealInventory} = useSelector(state => state.nutrition.logging)
    return (
        <div key={'logItem' + mIndex}>
            {newItem ? (
                <>
                    <ButtonRow
                        onClick={(e) => handleSetItemType(e, index, mIndex, date)}
                        buttons={options}
                        label={'test'}
                        buttonSelected={type}
                    />
                    {type === 0 && <>
                        <FrozenMealSelector key={'fm' + name} data={frozenMealInventory} index={index} mIndex={mIndex}
                                            date={date}
                                            fmUUID={fmUUID}/>
                        {fmUUID && <Button label={'Add meal to log'} className={"button"}
                                           onClick={() => handleUpdateNutrition(index, mIndex, date)}/>}
                    </>}
                    {type === 1 && <>
                        <GenerateMealBasedOnDefaultItems name={mealName} index={index} mIndex={mIndex} date={date}/>
                    </>}
                </>
            ) : (
                isEditing ? (
                        <EditItem index={index} mIndex={mIndex} item={item} date={date}/>)
                    :
                    (
                        <DisplayItem label={name} value={calories + ' Kcal'} index={index} portion={portion}
                                     portionUnit={portionUnit}/>
                    )

            )}
        </div>
    )
}

const GenerateMealBasedOnDefaultItems = ({name, index, mIndex, date}) => {
    const {categories} = useSelector(state => state.setting.nutrition);

    const {items} = categories.filter(item => item.name === name)[0];
    const {handleUpdateNutrition} = useReduxActions();
    if (!items || items.length === 0) {
        return <h5>
            Please configure default generation items in nutrition settings
        </h5>
    }

    return (
        <>
            <h5>
                Generate meal based on your pre set items
            </h5>
            {items.map((item, index) => (
                <h5 key={index}>{item.name}</h5>
            ))}
            <Button label={"Generate meal"}
                    onClick={() => handleUpdateNutrition(index, mIndex, date)} className={"button mt-10"}/>
        </>
    )
}

const FrozenMealSelector = (props) => {
    const {data, index, mIndex, date, fmUUID} = props;
    const {mobile} = useSelector(state => state.default);
    const recordsToDisplay = mobile ? 3 : 5; // Pagination size differs for mobile vs non-mobile

    const [startIndex, setStartIndex] = useState(0);

    // Handle pagination for both mobile and non-mobile
    const handleUp = () => {
        if (startIndex > 0) {
            setStartIndex(startIndex - 1);
        }
    };

    const handleDown = () => {
        if (startIndex + recordsToDisplay < data.length) {
            setStartIndex(startIndex + 1);
        }
    };

    const displayedData = data.slice(startIndex, startIndex + recordsToDisplay);
    const iconButtonSize = mobile ? 20 : 35;

    return (
        <div key={'frozenMealSelector' + fmUUID} className="flex-row-container"
             style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            <div style={{flexGrow: 1, maxWidth: '80%'}}>
                {displayedData.map((meal, fIndex) => (
                    <FrozenMealComponent
                        key={`frozenMeal-${fmUUID}-${fIndex}`}
                        meal={meal}
                        mobile={mobile}
                        fIndex={fIndex}
                        index={index}
                        mIndex={mIndex}
                        date={date}
                        fmUUID={fmUUID}
                    />
                ))}
            </div>
            <div className="icon-button-container"
                 style={{
                     display: 'flex',
                     flexDirection: 'column',
                     justifyContent: 'center',
                     width: '10%',
                     gap: '20px'
                 }}>
                <IconButton onClick={handleUp} icon={<FaArrowUp size={iconButtonSize}/>}/>
                <IconButton onClick={handleDown} icon={<FaArrowDown size={iconButtonSize}/>}/>
            </div>
        </div>
    );
};


const FrozenMealComponent = ({meal, mobile, fIndex, index, mIndex, date, fmUUID}) => {
    const {frozenMealId, calories, carbohydrates, expirationDate, fats, name, protein, remainingPortions} = meal;
    const [open, setOpen] = useState(false);
    const {handleSetFrozenMeal} = useReduxActions();
    const selected = fmUUID === frozenMealId;
    return (
        <div key={'FrozenMealComponentm' + mIndex + 'f' + fmUUID}
             style={{width: '100%', minWidth: '200px', marginBottom: '10px'}}>
            {fIndex !== 0 && <div className="line-relative"/>}
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', minWidth: '200px'}}>
                <h4 style={mobile ? {flex: '1 1 70%', minHeight: '36px'} : {flex: '1 1 70%'}}>{name}</h4>
                {mobile && (
                    <IconButton onClick={() => setOpen(!open)} icon={open ? <FaMinus/> : <FaPlus/>}/>
                )}
            </div>

            <div style={{
                display: 'flex',
                flexDirection: mobile ? 'column' : 'row',
                justifyContent: mobile ? 'flex-start' : 'space-between',
                marginBottom: '10px',
                alignItems: mobile ? 'flex-start' : 'center',
            }}>
                <span><strong>Calories:</strong> {calories} kcal</span>
                <span style={{marginLeft: mobile ? '0' : '15px', marginTop: mobile ? '5px' : '0'}}>
                    <strong>Remaining Portions:</strong> {remainingPortions}
                </span>
            </div>
            {mobile ? (
                open && (
                    <div className={"mb-10"}>
                        <div><strong>Carbohydrates:</strong> {carbohydrates} g</div>
                        <div><strong>Fats:</strong> {fats} g</div>
                        <div><strong>Protein:</strong> {protein} g</div>
                        <div><strong>Expiration Date:</strong> {expirationDate}</div>
                    </div>
                )
            ) : (
                <div style={{display: 'flex', justifyContent: 'space-between', marginTop: '10px'}}>
                    <span><strong>Carbohydrates:</strong> {carbohydrates} g</span>
                    <span><strong>Fats:</strong> {fats} g</span>
                    <span><strong>Protein:</strong> {protein} g</span>
                    <span><strong>Expiration Date:</strong> {expirationDate}</span>
                </div>
            )}
            <div className={mobile ? "button-container-right" : "button-container-right mt-10"}>
                <Button label={!selected ? "Select" : "Selected"}
                        className={!selected ? "button half-width" : "button button-tertiary half-width"}
                        onClick={() => handleSetFrozenMeal(frozenMealId, index, mIndex, date)}/>

            </div>
        </div>
    );
};

const EditItem = ({index, mIndex, item, date}) => {
    const {
        name,
        calories,
        portion,
        updatedValue,
        portionUnit,
        mealId
        , deletedItem
    } = item;
    const {
        handleEditLogItemValue,
        handleDeleteLogItem
    } = useReduxActions();
    if (deletedItem) {
        return <></>
    }
    const displayValue = (typeof updatedValue !== 'undefined') ? updatedValue : portion;

    const mealIdExists = !!mealId;
    const trimmedUnit = portionUnit ? portionUnit.charAt(0) : '';
    return (
        <div>
            {
                mealIdExists ?
                    (
                        <div className="flex-row-container">
                            <DisplayItem label={name} value={calories + ' Kcal'} index={index} portion={portion}
                                         portionUnit={portionUnit}/>
                            <IconButton onClick={() => handleDeleteLogItem(date, mIndex, index)} icon={<FaX/>}/>
                        </div>
                    )
                    :
                    (
                        <>
                            {index !== 0 && <div className="line-relative"/>}
                            <div className="flex-row-container">
                                <div className="flex-row-container">
                                    <div className="flex-item-half">
                                        <h5>{name}</h5>
                                    </div>
                                    <div className="flex-item-half">
                                        <TextBox label={`Amount (${trimmedUnit})`} className={''}
                                                 value={displayValue}
                                                 onChange={(e) => handleEditLogItemValue(e, date, mIndex, index)}
                                                 id={'m' + mIndex + 'i' + index} type={'number'}/>
                                    </div>
                                </div>
                                <IconButton onClick={() => handleDeleteLogItem(date, mIndex, index)} icon={<FaX/>}/>
                            </div>
                        </>
                    )
            }
        </div>
    )
}


const DisplayItem = ({label, value, index, portion, portionUnit}) => {
    const trimmedUnit = portionUnit ? portionUnit.charAt(0) : '';
    return (
        <>
            {index !== 0 && <div className="line-relative"/>}
            <div className="flex-row-container">
                {/* Label aligned to the left */}
                <h5 style={{marginBottom: 0, marginTop: 5, flex: '1', textAlign: 'left'}}>{label}</h5>

                {/* Right item with or without middle item */}
                <h5 style={{marginBottom: 0, marginTop: 5, flex: '1', textAlign: 'right'}}>
                    {portion && portionUnit ? `${portion} ${trimmedUnit} / ${value}` : value}
                </h5>
            </div>
        </>
    );
};





