import React, {useEffect, useState} from 'react';
import {LuPause, LuPlay, LuTimerReset} from "react-icons/lu";
import {useSelector} from "react-redux";
import {Button, IconButton} from '../../../common/fieldfactory/index';
import {useReduxActions} from "../logic/Events";

const Timer = ({ initialMinutes = 0, initialSeconds = 0 }) => {
    const {  handleCompleteWorkout,handleCompleteSet } = useReduxActions();
    const {completeWorkout} = useSelector(state => state.runWorkout);
    const [time, setTime] = useState({
        minutes: initialMinutes,
        seconds: initialSeconds,
    });
    const [isRunning, setIsRunning] = useState(false);

    useEffect(() => {
        let interval;
        if (isRunning) {
            interval = setInterval(() => {
                setTime((prevTime) => {
                    let { minutes, seconds } = prevTime;

                    if (seconds < 59) {
                        seconds++;
                    } else {
                        minutes++;
                        seconds = 0;
                    }

                    return { minutes, seconds };
                });
            }, 1000);
        }

        return () => clearInterval(interval);
    }, [isRunning]);

    const [isDisabled, setIsDisabled] = useState(false);

    const handleReset = () => {
        if (isDisabled) return;

        // Disable button for 1 second
        setIsDisabled(true);
        setTimeout(() => {
            setIsDisabled(false);
        }, 1500);

        // Your original reset logic
        handleCompleteSet();
        setTime({
            minutes: initialMinutes,
            seconds: initialSeconds,
        });
    }

    const handleStartPause = () => {
        setIsRunning(!isRunning);
    };

    const formatTime = (time) => {
        return String(time).padStart(2, '0');
    };

    return (
        <>
            {!completeWorkout && (
                <div className="timer-base timer">
                    <h4>{formatTime(time.minutes)}:{formatTime(time.seconds)}</h4>
                    <IconButton onClick={handleStartPause} icon={isRunning ? <LuPause className={'timer-icon'}/> : <LuPlay className={'timer-icon'}/>}/>
                    <IconButton onClick={handleReset} icon={<LuTimerReset className={'timer-icon'}/>}/>
                </div>
            )}
            {completeWorkout && (
                <Button className="timer-base timer-complete" onClick={handleCompleteWorkout} label={'Complete Workout'}/>
            )}
        </>
    );
};

export default Timer;