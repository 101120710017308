import React from 'react';
import {useSelector} from "react-redux";
import Button from "../../../common/fieldfactory/button/Button";
import TextBox from "../../../common/fieldfactory/textbox/TextBox";
import {useReduxActions} from "../logic/Events";


const StandardRepTypeComponent = ({exercise, index}) => {
    const {handleCompleteExercise, handleEditExercise, handleRollBackSet,handleUpdateSetLogs} = useReduxActions();
    const { sets } = exercise;
    const {exercises} = useSelector(state => state.runWorkout);
    const {unit} = useSelector(state => state.setting);
    const {log,complete} = exercises[index];
    if(log=== null){
        return;
    }
    return(<>

            {Array.from({length: sets}).map((_, setIndex) => (
                <div key={setIndex}>
                    <h4>Set {setIndex + 1}</h4>
                    {!complete && <>
                        <div className="flex-row-container">
                            <div className="flex-item-half">
                                <TextBox
                                    type="text"
                                    id={`${setIndex}weight`}
                                    label="Weight completed"
                                    value={log[setIndex].weight}
                                    onChange={(e) => handleUpdateSetLogs(e.target.value, setIndex, 'weight',index)}
                                    className="form-control"
                                />
                            </div>
                            <div className="flex-item-half">
                                <TextBox
                                    type="text"
                                    id={`${setIndex}reps`}
                                    label="Reps completed"
                                    value={log[setIndex].reps}
                                    onChange={(e) => handleUpdateSetLogs(e.target.value, setIndex, 'reps',index)}
                                    className="form-control"
                                />
                            </div>
                        </div>
                    </>}
                    {complete && <>
                        <h5>Weight - {log[setIndex].weight} {unit} Reps - {log[setIndex].reps}</h5>
                    </>}
                </div>
            ))}
            <div className="button-group">
                <Button
                    label={"Roll Back Set"}
                    className="button button-tertiary mt-10 half-width"
                    onClick={handleRollBackSet}
                />
                <Button
                    label={!complete ? "Complete Exercise" : "Edit Exercise"}
                    type="submit"
                    className={!complete ? "button mt-10 half-width" : "button button-tertiary mt-10 half-width"}
                    onClick={() => !complete ? handleCompleteExercise(index, undefined) : handleEditExercise(index)}
                />
            </div>
        </>
    )
}

export default StandardRepTypeComponent;