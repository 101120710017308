import React from 'react';


const NavButton = ({children, onClick, showText, text}) => {

    return (
        <li onClick={onClick}>
            <button
                className="tooltip link-button"
            >
                {children}
                {showText && <span className="sidebar-text">{text}</span>}
            </button>
        </li>
    )
}

export default NavButton;