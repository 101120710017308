import React from 'react';
import './nutrition.css';


const PlanLoader = () => {
    // Calculate the loading time based on the Redux state
    const loadingTime = 30;

    return (
        <div className="load-container" style={{width: '95%'}}>
            <svg stroke="currentColor" fill="currentColor" viewBox="0 0 256 256" height="1em" className="loader"
                 width="1em" xmlns="http://www.w3.org/2000/svg"
                 style={{
                     animation: `fillUpAnimation ${loadingTime}s ease-out forwards`
                 }}
            >
                <path
                    d="M224,104h-8.37a88,88,0,0,0-175.26,0H32a8,8,0,0,0-8,8,104.35,104.35,0,0,0,56,92.28V208a16,16,0,0,0,16,16h64a16,16,0,0,0,16-16v-3.72A104.35,104.35,0,0,0,232,112,8,8,0,0,0,224,104ZM173.48,56.23q2.75,2.25,5.27,4.75a87.92,87.92,0,0,0-49.15,43H100.1A72.26,72.26,0,0,1,168,56C169.83,56,171.66,56.09,173.48,56.23ZM148.12,104a71.84,71.84,0,0,1,41.27-29.57A71.45,71.45,0,0,1,199.54,104ZM128,40a71.87,71.87,0,0,1,19,2.57A88.36,88.36,0,0,0,83.33,104H56.46A72.08,72.08,0,0,1,128,40Z"></path>
            </svg>
            <h5>Generating instructions...</h5>
        </div>
    );
};

export default PlanLoader;



