import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {Button, ButtonRow, Card} from "../../../common/fieldfactory";
import {useReduxActions} from "../logic/Events";

const CookingComponent = () => {
    const {mobile} = useSelector(state => state.default);
    const {cookingRecipes, page} = useSelector(state => state.nutrition);
    const {currentRecipe, recipes} = cookingRecipes;
    const {handleSetPage, handleSetCurrentRecipe} = useReduxActions();
    const [expandedComponent, setExpandedComponent] = useState(!mobile);

    const handleCardClick = () => {
        if (mobile) {
            setExpandedComponent(prevState => !prevState);
            if (expandedComponent) {
                handleSetPage('ALL');
            } else {
                handleSetPage('COOKING');
            }
        }
    };

    useEffect(() => {
        if (mobile) {
            setExpandedComponent(false);
        } else {
            setExpandedComponent(true);
        }
    }, [mobile]);

    useEffect(() => {
        if (mobile) {
            if (page === 'COOKING') {
                setExpandedComponent(true);
            } else if (page === 'ALL') {
                setExpandedComponent(false);
            }
        }
    }, [mobile, page]);

    const buttons = recipes.map((recipe, index) => {
        return {name: String(index), label: String(index + 1)};
    });

    // Fallback for when currentRecipe is out of bounds after a recipe is removed
    const displayedRecipe = recipes[currentRecipe] || recipes[0] || null;

    const cardHeader = page === 'ALL' ? 'Cooking instructions' : 'Recipe selector';
    return (
        <Card
            header={cardHeader}
            onClick={mobile && !expandedComponent ? handleCardClick : null}
            expandable={true}
            expanded={expandedComponent}
            collapseOnClick={mobile ? handleCardClick : null} // Only collapse on click if mobile
        >
            {expandedComponent ? (
                <>
                    {recipes.length > 1 && <ButtonRow buttons={buttons} onClick={(e) => handleSetCurrentRecipe(e)}
                                                      buttonSelected={currentRecipe} label={'R'}/>}
                    {displayedRecipe ? (
                        <Recipe recipeObject={displayedRecipe}/>
                    ) : (
                        <div>No recipe selected or remaining</div>
                    )}
                </>
            ) : (
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <h4 style={{marginBottom: 0}}>Meals remaining to cook</h4>
                    <h5 style={{marginBottom: 0}}>{recipes.length}</h5>
                </div>
            )}
        </Card>
    );
};

export default CookingComponent;

const Recipe = ({recipeObject}) => {
    // Check if recipeObject is defined
    const {handleCompleteMeal} = useReduxActions();
    if (!recipeObject) {
        return <div>No recipe found</div>;
    }
    const {
        recipe,
        ingredients,
        nutrition_per_portion,
        cooking_instructions,
        portions
    } = recipeObject;
    const sectionStyle = {
        marginBottom: '20px',
    };

    const listStyle = {
        paddingLeft: '20px',
        marginBottom: '10px',
    };

    const ingredientStyle = {
        marginBottom: '5px',
    };

    const nutritionStyle = {
        fontSize: '14px',
        color: '#28a745',
        fontWeight: 'bold',
    };

    return (
        <div>
            <h3 style={{marginTop: '20px'}}>{recipe}</h3>
            <div style={sectionStyle}>
                <strong>Ingredients:</strong>
                <ul style={listStyle}>
                    {ingredients.map((ingredient, index) => (
                        <li key={index} style={ingredientStyle}>
                            {ingredient.quantity} {ingredient.unit} {ingredient.name}
                        </li>
                    ))}
                </ul>
            </div>

            <div style={sectionStyle}>
                <strong>Cooking Instructions:</strong>
                {cooking_instructions.split(/(?:\d+\.\s+)/).map((instruction, index) => (
                    instruction && <p key={index}>{index}. {instruction}</p>
                ))}
            </div>
            <div style={sectionStyle}>
                <strong>Servings per Recipe:</strong>
                <p>{portions}</p>
            </div>

            <div style={sectionStyle}>
                <strong>Nutrition:</strong>
                <div style={nutritionStyle}>
                    Calories: {nutrition_per_portion.calories} kcal | Protein: {nutrition_per_portion.protein} g |
                    Carbs: {nutrition_per_portion.carbohydrates} g | Fats: {nutrition_per_portion.fats} g
                </div>
            </div>
            <Button label="Recipe meal prep complete" className="button mt-10"
                    onClick={() => handleCompleteMeal(recipe)}/>
        </div>
    );
};
