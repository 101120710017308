import {useDispatch, useSelector} from 'react-redux';
import callApi from "../../../common/api/Apiutils";
import {useAlert} from "../../../common/fieldfactory/alert/AlertContext";
import {setAuthenticated, setIsDarkMode, setPage} from "../../../common/statemangment/Reducer";
import {loadGoals} from "../../goals/logic/Reducer";
import {setOneRepMaxesList, setWeightData, setWorkOutSchedule} from "../../home/logic/Reducer";
import {setUpRunProgram} from "../../runworkout/logic/Reducer";
import {setUsername, setPassword, setEmail, setPageState, setLoading} from './Reducer';
import {loadNutritionCategories, setState} from "../../settings/logic/Reducer";
import {setDropDownLists, setNewWorkout, setUnitList} from "../../createworkout/logic/Reducer";

export const useReduxActions = () => {
    const handleUsernameChange = useHandleUsernameChange();
    const handlePasswordChange = useHandlePasswordChange();
    const handleEmailChange = useHandleEmailChange();
    const handleSetPageState = useHandleSetPageState();
    const handleLogin = useHandleLogin();
    const handleSignUp = useHandleSignUp();
    const handleResetPassword = useHandlePassword();

    return {
        handleUsernameChange,
        handlePasswordChange,
        handleEmailChange,
        handleSetPageState,
        handleLogin,
        handleSignUp,
        handleResetPassword
    };
};
export const useHandleUsernameChange = () => {
    const dispatch = useDispatch();
    return (e) => {
        dispatch(setUsername(e.target.value));
    };
};

export const useHandlePasswordChange = () => {
    const dispatch = useDispatch();
    return (e) => {
        dispatch(setPassword(e.target.value));
    };
};

export const useHandleEmailChange = () => {
    const dispatch = useDispatch();
    return (e) => {
        dispatch(setEmail(e.target.value));
    };
};

export const useHandleSetPageState = () => {
    const dispatch = useDispatch();
    return (newState) => {
        dispatch(setPageState(newState));
    };
};

export const useHandleLogin = () => {
    const {showAlert} = useAlert();
    const dispatch = useDispatch();
    const {username, password} = useSelector(state => state.login);
    return async e => {
        dispatch(setLoading(true))
        e.preventDefault();
        const response = await callApi('/fit/user/login', 'POST', {
            username,
            password
        }, {service: 'UserService'}, undefined, dispatch)
        if (response.error) {
            dispatch(setLoading(false))
            showAlert(response.message);
            return;
        }
        await loadUserData(dispatch, showAlert, username, response.authorization);
        dispatch(setLoading(false))
    };
};

const fetchUserData = async (username, authorization) => {
    return await callApi(`/fit/user/user`, 'GET', null, {Authorization: authorization, service: 'UserService'});
};

const updateUserState = (dispatch, userData) => {
    dispatch(setAuthenticated(true));
    dispatch(setIsDarkMode(userData.style));
    dispatch(setEmail(userData.email));
    dispatch(setNewWorkout());
    dispatch(setState({unit: userData.unit, oneRepMaxList: userData.oneRepMaxListDTO, programs: userData.programsDTO}));
    dispatch(setUnitList(userData.units));
    dispatch(setPage('HOME'));
    dispatch(setWorkOutSchedule(userData.homeDTO));
    dispatch(setDropDownLists(userData.dropDownListDTO));
    dispatch(setUpRunProgram(userData.todayDTO));
    dispatch(setWeightData(userData.userWeightsDTO));
    dispatch(setOneRepMaxesList(userData.oneRepMaxHistoryDTOList));
    dispatch(loadGoals(userData.userGoalsDTO));
    dispatch(loadNutritionCategories(userData.categories))
};

export const loadUserData = async (dispatch, showAlert, username, authorization) => {
    try {
        const response = await fetchUserData(username, authorization);
        if (response.error) {
            showAlert(response.message);
            return;
        }
        updateUserState(dispatch, response);
        // await loadDropDownLists(dispatch, showAlert, authorization);
    } catch (error) {
        showAlert("An error occurred while loading user data.");
    }
};


export const useHandleSignUp = () => {
    const dispatch = useDispatch();
    const {showAlert} = useAlert();
    const {username, password, email} = useSelector(state => state.login);
    return async e => {
        e.preventDefault();
        const response = await callApi('/fit/user/signup', 'POST', {
            username,
            password,
            email
        }, {service: 'UserService'}, undefined, dispatch)
        if (response.error) {
            await showAlert(response.message);
        } else {
            await loadUserData(dispatch, showAlert, username, response.authorization)
        }
    };
};

export const useHandlePassword = () => {
    const dispatch = useDispatch();
    return (newState) => {
        dispatch(setPageState(newState));
    };
};