import React from 'react';
import NutritionSettings from "./components/NutritionSettings";
import OneRepMaxComponent from "./components/OneRepMaxComponent";
import ProgramSettings from "./components/ProgramSettings";
import AccountSettings from "./components/AccountSettings";
import {Page} from '../../common/fieldfactory/index';

const Settings = () => {
    return (
        <Page header="Settings">
            <AccountSettings/>
            <OneRepMaxComponent/>
            <ProgramSettings/>
            <NutritionSettings/>
        </Page>
    );
};

export default Settings;
