import React from "react";
import {useSelector} from "react-redux";
import {Card,WorkoutCalendar} from '../../../common/fieldfactory/index';
import {useReduxActions} from "../logic/Events";

const WorkOutCalendarComponent = ({collapseOnClick,expanded}) => {
    const { workoutSchedule , selectedDate } = useSelector(state => state.home);
    const {handleSetDate} = useReduxActions();
    return(
        <Card header={'Calendar'} collapseOnClick={collapseOnClick} expandable={collapseOnClick} onClick={!expanded ? collapseOnClick : null} expanded={expanded}>
            <WorkoutCalendar workoutSessions={workoutSchedule} value={selectedDate} onChange={handleSetDate} />
        </Card>
    )
}

export default WorkOutCalendarComponent;