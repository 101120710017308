import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {setIsDarkMode} from "../../../common/statemangment/Reducer";
import {useReduxActions} from "../logic/Events";
import {Button,Card,TextBox,DropDown} from '../../../common/fieldfactory/index';


const AccountSettings = () => {
    const {username, email} = useSelector(state => state.login);
    const {darkMode} = useSelector(state => state.default);
    const {unitList} = useSelector(state => state.createWorkOut);
    const {unit} = useSelector(state => state.setting);
    const dispatch = useDispatch();
    const dropDownOptions = [
        {label: 'Light mode', value: 'light'}
        , {label: 'Dark mode', value: 'dark'}
        , {label: 'Red mode', value: 'red'}
        , {label: 'Red dark mode', value: 'red-dark'}
        , {label: 'Green mode', value: 'green'}
        , {label: 'Green dark mode', value: 'green-dark'}
    ];
    const {
        handleSetUnit,
        handleSaveSettings
    } = useReduxActions();
    return (
        <Card header="Account Settings">
            <div className="form-group">
                <TextBox type="text" id="username" label="Username" value={username}
                         className="form-control" readOnly={true}/>
                <TextBox type="email" id="email" label="Email" value={email}
                         className="form-control" readOnly={true}/>
            </div>
            <div className="form-group">
                <DropDown label="Weight Unit:"
                          options={unitList}
                          onChange={handleSetUnit}
                          value={unit}
                />
            </div>
            <div className="form-group">
                <DropDown label="Style selector"
                          options={dropDownOptions}
                          onChange={(e) => dispatch(setIsDarkMode(e))}
                          value={darkMode}
                />
            </div>
            <div className="button-container-right">
                <Button label="Save" type="submit" className="button mt-10 half-width"
                        onClick={handleSaveSettings}/>
            </div>
        </Card>
    )
}

export default AccountSettings;