import React, {useState} from 'react';
import {FaEdit, FaSave, FaTrash} from "react-icons/fa";
import {IconButton,TextBox} from '../../../common/fieldfactory/index';

const ComponentName = ({heading, handleDelete, name,handleSave}) => {
    const [state, setState] = useState(false);
    const [value, setValue] = useState(name)
    return (<>
            <div className="side-by-side-div">
                <div className="left-content">
                    {!state && <><IconButton icon={<FaEdit size={20}/>} onClick={() => {
                        setState(true)
                    }}/>
                        <h4>{heading + value}</h4>
                    </>}
                    {state && <>
                        <IconButton icon={<FaSave size={20}/>} onClick={() => {
                            handleSave(value);
                            setState(false)
                        }}/>
                        <h4>{heading}</h4>
                    </>}
                </div>
                <IconButton icon={<FaTrash size={20}/>} onClick={handleDelete}/>
            </div>
        {state &&<TextBox type="text" className="form-control mt-10" label={"Edit Name"} value={value}
                     onChange={(e) => setValue(e.target.value)}/>}
        </>
    );
};

export default ComponentName;