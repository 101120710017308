import React from 'react'
import {useSelector} from "react-redux";
import {useReduxActions} from "../logic/Events";
import ExerciseComponent from "./ExerciseComponent";
import {Card, Button} from '../../../common/fieldfactory/index';

const ExercisesComponent = () => {
    const {weekName, dayName, exercises, pageState} = useSelector(state => state.runWorkout);
    const {nextWorkout} = useSelector(state => state.home);
    const {
        handleSetRunPage
    } = useReduxActions();
    const restDay = exercises.length === 0;
    return (
        <Card header={weekName + ' - ' + dayName}>
            {exercises.map((exercise, index) => (
                <ExerciseComponent exercise={exercise} index={index} key={index}/>
            ))}
            {pageState === 'START' && <>
                {restDay ? (<div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <h4 style={{marginBottom: 0}}>Next workout</h4>
                    <h5 style={{marginBottom: 0}}>{nextWorkout}</h5>
                </div>) : (<div className="button-container-right">
                    <Button label={"Start workout"} type="submit" className="button mt-10 half-width"
                            onClick={handleSetRunPage}/>
                </div>)}</>
            }
        </Card>
    )
}

export default ExercisesComponent;