import React from "react";
import {useSelector} from "react-redux";

const Page = ({header, subHeader, subHeaderTwo, children}) => {

    const {mobile} = useSelector(state => state.default);
    return (<>

            {mobile && <div className="mobile-content">
                <header className="header">
                    <h2>{header}</h2>
                    <h4>{subHeader}</h4>
                    <h5>{subHeaderTwo}</h5>
                </header>
            </div>}
            {!mobile && <header className="header">
                <h1>{header}</h1>
                <h3>{subHeader}</h3>
                <h4>{subHeaderTwo}</h4>
            </header>}
            {children}

        </>
    );
}
export default Page;