import React from 'react';

const TextBox = ({ type, id, value, onChange, formatOnBlur, className, label, readOnly }) => {
    const handleChange = (e) => {
        if (onChange && typeof onChange === 'function') {
            onChange(e); // Directly call onChange
        }
    };

    const handleBlur = (e) => {
        if (formatOnBlur && typeof formatOnBlur === 'function') {
            // This should correctly update the value to the formatted version
            onChange(formatOnBlur(e.target.value));
        }
    };

    const handleWheel = (e) => {
        if (type === 'number') {
            e.preventDefault();
        }
    };

    return (
        <div className="form-group mt-10">
            <label htmlFor={id}>{label}</label>
            <input
                type={type || 'text'}
                id={id}
                // Explicitly handle the value for '0' and other falsy values
                value={value !== null && value !== undefined ? value : ''}
                onChange={handleChange}
                onBlur={handleBlur}
                className={`form-control ${className || ''}`}
                readOnly={readOnly}
                onWheel={handleWheel}
                style={type === 'number' ? { MozAppearance: 'textfield', appearance: 'textfield' } : {}}
            />
            <style jsx="true">{`
                input[type=number]::-webkit-outer-spin-button,
                input[type=number]::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }
                input[type=number] {
                    -moz-appearance: textfield;
                }
            `}</style>
        </div>
    );
};

export default TextBox;


