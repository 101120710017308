import React from 'react';
import {useSelector} from 'react-redux';
import {Card} from "../../../common/fieldfactory";
// Ensure correct path

const CollapsedWorkoutCalendarComponent = (props) => {
    const {handleCardClick, expandedComponent, nextWorkout} = props;
    const {workoutSchedule} = useSelector(state => state.home);

    return (
        <Card header={'Workout schedule'}
              expandable={true}
              onClick={handleCardClick}
              expanded={expandedComponent}
        >
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <h4 style={{marginBottom: 0}}>Next workout</h4>
                <h5 style={{marginBottom: 0}}>{nextWorkout}</h5>
            </div>
            <WorkoutCalendar workoutSessions={workoutSchedule}/>
        </Card>
    );
}

export default CollapsedWorkoutCalendarComponent;

const WorkoutCalendar = ({workoutSessions}) => {
    // Days of the week (static), starting with Monday
    const daysOfWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

    // Get the current week's dates, starting from Monday
    const today = new Date();

    const dayOfWeek = today.getDay();
    const startOfWeek = new Date(today.setDate(today.getDate() - (dayOfWeek === 0 ? 6 : dayOfWeek - 1)));
    // Adjust to Monday; if it's Sunday (0), go back 6 days.

    // Function to check custom highlighting for a specific date
    const getDayHighlightClass = (date) => {
        // Format the date as 'YYYY-MM-DD'
        const dateString = date.toLocaleDateString('en-CA');
        const workoutDays = workoutSessions.map(session => session);
        const prevDate = new Date(date);
        prevDate.setDate(prevDate.getDate() - 1);
        const nextDate = new Date(date);
        nextDate.setDate(nextDate.getDate() + 1);

        const prevDateString = prevDate.toLocaleDateString('en-CA');
        const nextDateString = nextDate.toLocaleDateString('en-CA');

        const prevHighlighted = workoutDays.includes(prevDateString);
        const nextHighlighted = workoutDays.includes(nextDateString);
        const dayOfWeek = date.getDay();

        if(new Date().toLocaleDateString('en-CA') === date.toLocaleDateString('en-CA')){
            return 'react-calendar__tile mt-5 react-calendar__tile--now '
        }
        let highlightClass = 'react-calendar__tile react-calendar__month-view__days__day react-calendar__month-view__days__day--weekend highlight';
        if (workoutDays.includes(dateString)) {
            if (dayOfWeek === 1) { // Monday
                return nextHighlighted ? `${highlightClass} highlight-start` : highlightClass;
            } else if (dayOfWeek === 0) { // Sunday
                return prevHighlighted ? `${highlightClass} highlight-end` : highlightClass;
            }

            if (prevHighlighted && nextHighlighted) {
                return `${highlightClass} highlight-middle`;
            } else if (prevHighlighted) {
                return `${highlightClass} highlight-end`;
            } else if (nextHighlighted) {
                return `${highlightClass} highlight-start`;
            } else {
                return highlightClass;
            }
        }

        return 'react-calendar__tile mt-5'; // Default class for non-highlighted days
    };

    // Generate the dates for the current week (starting from Monday)
    const weekDates = Array.from({length: 7}, (_, i) => {
        const dayDate = new Date(startOfWeek);
        dayDate.setDate(dayDate.getDate() + i);
        return dayDate;
    });

    return (
        <div
             style={{display: 'flex',padding: '10px 0 0 0'}}>

            {weekDates.map((date, index) => (
                    <div key={index} style={{textAlign: 'center'}}>
                        <div style={{textDecoration: 'underline dotted'}}>
                            {daysOfWeek[(date.getDay() + 6) % 7]} {/* Shift day labels so Monday is the first */}
                        </div>
                        <div className={getDayHighlightClass(date)} style={{width: '47.5px'}}>
                            <div style={{fontSize: '13px'}}>{date.getDate()}</div>
                        </div>
                    </div>
                )
            )}
        </div>
    );
};
