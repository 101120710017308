import React from 'react';
import {useSelector} from "react-redux";
import RunExerciseComponent from "./RunExerciseComponent";

const RunComponent = () => {
    const {exercises,currentExercise} = useSelector(state => state.runWorkout);
    return(<>
            {exercises.slice(0, currentExercise).map((exercise, index) => (
                <RunExerciseComponent exercise={exercise} index={index} finalIndex={(index+1) === exercises.length} key={index}/>
            ))}
        </>
    )
}

export default RunComponent;