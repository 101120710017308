import React, { useState, useEffect } from 'react';
import {IoIosArrowDown, IoIosArrowUp} from "react-icons/io"; // Make sure to create a corresponding CSS file

const DropDown = ({ label, options, onChange, value }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedValue, setSelectedValue] = useState(value);

    useEffect(() => {
        setSelectedValue(value);
    }, [value]);

    const handleOptionClick = (selectedOptionValue) => {
        setSelectedValue(selectedOptionValue);
        setIsOpen(false); // Close dropdown after selection
        if (onChange && typeof onChange === 'function') {
            onChange(selectedOptionValue);
        }
    };

    const getLabelByValue = (value) => {
        const stringValue = value != null ? value.toString() : '';
        return options.find(option => option.value === stringValue)?.label || '';
    };

    return (
        <div className="dropdown-container form-group mt-10">
            {label && <label className="dropdown-label">{label}</label>}
            <div className="form-control" onClick={() => setIsOpen(!isOpen)}>
                {getLabelByValue(selectedValue)}
                <span className="dropdown-arrow">
            {isOpen ? <IoIosArrowUp size={15} style={{color: '#0077b6'}}/> :
                <IoIosArrowDown size={15} style={{color: '#0077b6'}}/>}
        </span>
            </div>
            {isOpen && (
                <ul className="dropdown-list">
                    {options.map((option) => {
                        const isSelected = selectedValue === option.value;
                        const isEmpty = !option.label.trim();
                        const itemClasses = `dropdown-item ${isSelected ? (isEmpty ? 'empty-selected' : 'selected') : ''}`;

                        return (
                            <li
                                key={option.value}
                                className={itemClasses}
                                onClick={() => handleOptionClick(option.value)}
                            >
                                {option.label || ' '} {/* Ensure even empty labels take up space, but can adjust based on your design */}
                            </li>
                        );
                    })}
                </ul>
            )}
        </div>
    );
};

export default DropDown;
