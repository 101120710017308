import React, { useState } from 'react';

const Checkbox = ({ label, checked, onChange }) => {
    const [isChecked, setIsChecked] = useState(checked || false);

    // Function to handle the change event
    const handleChange = (event) => {
        setIsChecked(event.target.checked);
        if (onChange) {
            onChange(event.target.checked);
        }
    };

    return (
        <label className="checkbox-container mt-10">
            <input
                type="checkbox"
                checked={isChecked}
                onChange={handleChange}
                className="checkbox-input"
            />
            <span className="custom-checkbox"></span>
            <span className="checkbox-label">{label}</span>
        </label>
    );
};

export default Checkbox;
