import React from 'react';

const VerticalCards = ({ cards }) => {
    return (
        <div className={'vertical-cards'}>
            {cards.map((card, index) => (
                <div key={index} className={'vertical-cards-item'}>
                    {card}
                </div>
            ))}
        </div>
    );
};

export default VerticalCards;