import React from 'react'
import {useSelector} from "react-redux";
import {Button, Card} from "../../../common/fieldfactory";
import {useReduxActions} from "../logic/Events";
import CarrotLoader from "./loaders/CarrotLoader";
import '../../../common/styles/stylesheet.css'
import Loader from "../../../common/fieldfactory/loader/Loader";

const GeneratedMeals = () => {
    const {generatedMealPlan, generation} = useSelector(state => state.nutrition);
    const {awaitingAcceptance, mealPlanName,regeneratingIndexes} = generatedMealPlan;
    const loadingModalOpen = generation.loadingModalOpen;
    const {handleAcceptMeal, handleRegenerate} = useReduxActions();
    if (Object.entries(awaitingAcceptance).length === 0 && !loadingModalOpen) {
        return;
    }
    if (loadingModalOpen) {
        return (
            <CarrotLoader/>
        );
    }

    const indexRegeneration = (index) => {
        return regeneratingIndexes.includes(index)
    }

    return (
        <Card header={mealPlanName}>
            <div>
                {awaitingAcceptance.map((meal, index) => (
                    <div key={index} className={'set-log-item-container'}>
                        {index !== 0 && <div className={'line'}></div>}
                        {indexRegeneration(index) ? (
                            <>
                                <h4>Meal {index + 1}</h4>
                                <Loader/>
                            </>
                        ) : (
                            <>
                                <h4>Meal {index + 1}: {meal.name}</h4>
                                <h5>Description:</h5>
                                <p> {meal.description}</p>

                                <h5>Ingredients:</h5>
                                <ul>
                                    {meal.sampleIngredients.map((ingredient, idx) => (
                                        <li key={idx}>
                                            {ingredient}
                                        </li>
                                    ))}
                                </ul>
                                <div className="button-group mt-10 mb-10">
                                    <Button label="Regenerate option" className="button button-secondary half-width"
                                            onClick={() => handleRegenerate(meal.name,index)}/>
                                    <Button label="Accept meal" className="button half-width"
                                            onClick={() => handleAcceptMeal(meal.name)}/>
                                </div>
                            </>)}
                    </div>))}
            </div>
        </Card>
    )
}

export default GeneratedMeals;