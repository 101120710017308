import React from 'react';
import {useSelector} from "react-redux";


const ExerciseComponent = ({exercise , index}) => {
    const {exerciseName, weight, sets, reps, repetitionType, rpe} = exercise;
    const showWeight = weight !== '0' && weight !== '';
    const {unit} = useSelector(state => state.setting);
    const type = repetitionType === 'Custom' ? <h5>Sets - {sets} Reps - {reps}</h5> : repetitionType === 'AMRAP' ?
        <h5>Sets - {sets} Reps - As many reps as possible</h5> : <h5>{repetitionType}</h5>;
    return (
        <div id={index} key={'ExerciseComponent' + index}>
            <h4>Exercise {index + 1} - {exerciseName}</h4>
            {showWeight && <h5>Weight : {weight} {unit}</h5>}
            {rpe && <h5>RPE : {rpe}</h5>}
            {type}
        </div>
    )
}

export default ExerciseComponent;