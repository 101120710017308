import React from 'react'
import {useSelector} from "react-redux";
import {Button, ButtonRow, Card} from "../../../common/fieldfactory";
import {getCuisinesByRegion, useReduxActions, worldRegions} from "../logic/Events";

const MealPlanGeneration = (props) => {
    const {expandedComponent, handleCardClick} = props;
    const {nutrition} = useSelector(state => state.setting);
    const {categories} = nutrition;
    const {
        handleSetRecipesToGenerate,
        handleSetDaysToGenerate,
        handleSetWorldRegion,
        handleSetCuisineToGenerate,
        handleGeneratePlan,
        handleResetMealPlan
    } = useReduxActions()
    const {
        recipesToGenerate,
        daysToGenerate,
        worldRegion,
        cuisineToGenerate,
        loadingModalOpen
    } = useSelector(state => state.nutrition.generation);
    const {accepted, awaitingAcceptance} = useSelector(state => state.nutrition.generatedMealPlan);
    const {mobile} = useSelector(state => state.default);
    const disableGeneration = accepted.length !== 0 || awaitingAcceptance.length !== 0 || loadingModalOpen;
    const cuisines = getCuisinesByRegion(worldRegion);
    const emptyItemsNames = categories
        .filter(meal => !meal.items || meal.items.length === 0)
        .map(meal => meal.name)
        .join(', ');
    return (<Card header="Generation options"
                  onClick={mobile && !expandedComponent ? handleCardClick : null}
                  expandable={true}
                  expanded={expandedComponent}
                  collapseOnClick={handleCardClick}
    >
        {!disableGeneration ? (<>
                <h5 style={{marginTop: '5px'}}>Generating frozen meal options for {emptyItemsNames}</h5>
                {/*<ButtonRow buttons={[1, 2, 3, 4, 5]} label={''} buttonSelected={mealsPerDay}*/}
                {/*           onClick={handleSetMealsPerDay}/>*/}
                <h5 style={{marginTop: '5px'}}>Days to generate</h5>
                <ButtonRow
                    buttons={[{label: 5}, {label: 6}, {label: 7}, {label: 8}, {label: 9}, {label: 10}, {label: 11}, {label: 12}, {label: 13}, {label: 14}]}
                    label={''}
                    buttonSelected={daysToGenerate}
                    onClick={handleSetDaysToGenerate}/>
                <h5 style={{marginTop: '5px'}}>Select the number of recipes to generate</h5>
                <ButtonRow buttons={[1, 2, 3, 4, 5]} label={''} buttonSelected={recipesToGenerate}
                           onClick={handleSetRecipesToGenerate}/>
                {/*<h5 style={{marginTop: '5px'}}>Do you want to generate a breakfast meal?</h5>*/}
                {/*<ButtonRow buttons={[{name: '0', label: 'Yes'}, {name: '1', label: 'No'}]} label={''}*/}
                {/*           buttonSelected={generateBreakfastRecipe}*/}
                {/*           onClick={handleSetGenerateBreakfastRecipe}/>*/}
                <h5 style={{marginTop: '5px'}}>Cuisine world region</h5>
                <ButtonRow buttons={worldRegions} label={''}
                           buttonSelected={worldRegion} setButtonPerRow={4}
                           onClick={handleSetWorldRegion}/>
                <h5 style={{marginTop: '5px'}}>Cuisine</h5>
                <ButtonRow buttons={cuisines} label={''} setButtonPerRow={mobile ? 4 : 5}
                           buttonSelected={cuisineToGenerate}
                           onClick={handleSetCuisineToGenerate}/>
                <Button label={'Generate plan'} onClick={handleGeneratePlan} className={'mt-10 button'}/>
            </>)
            : (<>
                {!loadingModalOpen ? (
                    <Button label={'Reset generation'} onClick={handleResetMealPlan} className={'mt-10 button'}/>
                ) : (
                    <Button label={'Generating'} onClick={() => console.log('Empty')}
                            className={'mt-10 button button-tertiary'}/>
                )}
            </>)}

    </Card>)
}

export default MealPlanGeneration