import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {Button, Card, DropDown, TextBox} from "../../../common/fieldfactory";
import {useReduxActions} from "../logic/Events";

const CreateGoalComponent = () => {
    const {page} = useSelector(state => state.goal);
    const open = page === "CREATEGOAL";
    const {mobile} = useSelector(state => state.default);
    const [expandedComponent, setExpandedComponent] = useState(!mobile);
    const {handleSetPage} = useReduxActions();

    const handleCardClick = () => {
        if (mobile) {
            setExpandedComponent(prevState => !prevState);
            if (expandedComponent) {
                handleSetPage('ALL');
            } else {
                handleSetPage('CREATEGOAL');
            }
        }
    };

    useEffect(() => {
        if (mobile) {
            setExpandedComponent(false);
        } else {
            setExpandedComponent(true);
        }
    }, [mobile]);

    useEffect(() => {
        if (mobile) {
            if (page === 'CREATEGOAL') {
                setExpandedComponent(true);
            } else if (page === 'ALL') {
                setExpandedComponent(false);
            }
        }
    }, [mobile, page]);

    return (
        <>
            {open ?
                (<OpenComponent mobile={mobile} expandedComponent={expandedComponent}
                                handleCardClick={handleCardClick}/>) :
                (<CloseComponent mobile={mobile} expandedComponent={expandedComponent}
                                 handleCardClick={handleCardClick}/>)
            }
        </>
    )

}

const OpenComponent = (props) => {
    const {mobile, expandedComponent, handleCardClick} = props;
    const {goalTypeDropDown, oneRepMaxesWithNoGoal, newGoal} = useSelector(state => state.goal);
    const {handleSetGoalType, handleSetGoalValue, handleSetOneRepMaxValue,handelSaveGoal} = useReduxActions();

    const {goalType, goalNameValue, goalValue} = newGoal;
    const oneRepMaxGoal = goalType === "One rep max";
    const sessionGoal = goalType === "Cardio sessions" || goalType === "Strength sessions";
    const goalNameValueSelected = goalNameValue !== '';
    return (
        <Card header={'Goal options'}
              onClick={mobile && !expandedComponent ? handleCardClick : null}
              expandable={true}
              expanded={expandedComponent}
              collapseOnClick={handleCardClick}
        >
            <>

                <DropDown options={goalTypeDropDown} onChange={(e) => handleSetGoalType(e)} value={goalType}
                          label="Goal Type"/>
                {sessionGoal &&
                    <>
                        <TextBox type="text" id="name" label="How many sessions a week?" value={goalNameValue}
                                 onChange={(e) => handleSetGoalValue(e.target.value)}
                                 className="form-control" readOnly={false}/>
                        {goalNameValueSelected &&
                            <TextBox type="text" id="name" label="Duration of session (miniutes)" value={goalValue}
                                     onChange={(e) => handleSetOneRepMaxValue(e.target.value)}
                                     className="form-control" readOnly={false}/>
                        }
                    </>
                }
                {oneRepMaxGoal &&
                    <>
                        <DropDown options={oneRepMaxesWithNoGoal} onChange={(e) => handleSetGoalValue(e)}
                                  value={goalType}
                                  label="Select one rep max to create goal for?"/>
                        {goalNameValueSelected &&
                            <TextBox type="text" id="name" label="One rep max goal" value={goalValue}
                                     onChange={(e) => handleSetOneRepMaxValue(e.target.value)}
                                     className="form-control" readOnly={false}/>
                        }
                    </>
                }
                <Button
                    label={"Create goal"}
                    className="button mt-10"
                    onClick={() => handelSaveGoal()}
                />
            </>
        </Card>
    )
}

const CloseComponent = (props) => {
    const {mobile, expandedComponent, handleCardClick} = props;
    return (
        <Card header={'Create Goal'}
              onClick={mobile && !expandedComponent ? handleCardClick : null}
              expandable={true}
              expanded={expandedComponent}
              collapseOnClick={handleCardClick}
        />

    )
}

export default CreateGoalComponent;