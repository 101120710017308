import React from 'react';
import PropTypes from 'prop-types';

const IconButton = ({ icon, onClick }) => {
    return (
        <button onClick={onClick} style={{ border: 'none', background: 'none', cursor: 'pointer', color: 'var(--text-color)' }}>
            {icon}
        </button>
    );
};

IconButton.propTypes = {
    icon: PropTypes.element.isRequired,
    onClick: PropTypes.func.isRequired,
};

export default IconButton;
