import {Button,TextBox,TextArea,DropDown,Card} from '../../../common/fieldfactory/index';
import React from "react";
import {useReduxActions} from "../logic/Events";
import {useSelector} from "react-redux";


const ProgramsComponent = () => {
    const {workOutSelected , workOutList , name,description } = useSelector(state => state.createWorkOut);
    const {
        handleSaveWorkout,
        handleSwitchWorkout,
        handleCreateNewWorkOut,
        handleDeleteWorkOut,
        handleUpdateWorkoutName,
        handleUpdateWorkoutDescription
    } = useReduxActions();
    return (
        <Card header="Workout">
            <DropDown label="Unpublised Program List" value={workOutSelected}
                      onChange={(e) => handleSwitchWorkout(e)} options={workOutList}/>

            <div className="button-group mt-10 mb-10">
                <Button label="Delete Workout" className="button button-secondary half-width"
                        onClick={handleDeleteWorkOut}/>
                <Button label="Create Workout" className="button half-width"
                        onClick={handleCreateNewWorkOut}/>
            </div>
            <TextBox type="text" className="form-control mt-10" label={'Workout Name'} value={name}
                     onChange={(e) => handleUpdateWorkoutName(e)}/>
            <TextArea label="Description"
                      className="form-control mt-10"
                      value={description}
                      onChange={(e) => handleUpdateWorkoutDescription(e)}/>
            <div className="button-container-right">
                <Button label="Save Workout" className="button mt-10 half-width"
                        onClick={handleSaveWorkout}></Button>
            </div>
        </Card>
    );
}

export default ProgramsComponent;