import React from 'react';
import {useSelector} from "react-redux";
import {Card} from "../../../common/fieldfactory";
import DoughnutGraph from "../../../common/fieldfactory/graphs/DoughnutGraph";

const RemainingCaloriesComponent = ({expandedComponent,handleCardClick}) => {
    const {selectedDate} = useSelector(state => state.nutrition);
    const {meals} = useSelector(state => state.nutrition.logging);
    const {mobile} = useSelector(state => state.default);
    const selectedLog = meals.filter(item => item.date === selectedDate)[0];
    const {
        consumedCalories = 0
    } = selectedLog || {};
    const {
        caloriesGoal: currentCaloriesGoal,
    } = useSelector(state => state.nutrition.goals);
    const {
        caloriesConsumed: currentCaloriesConsumed,
    } = useSelector(state => state.nutrition.consumed);

    const {
        animateGraph,
    } = useSelector(state => state.nutrition);

    const dateCheck = new Date().toLocaleDateString('en-CA') === selectedDate;

    if (!dateCheck && !selectedLog) {
        return <></>;
    }

    const caloriesGoal = dateCheck ? Math.round(currentCaloriesGoal * 10) / 10 : Math.round(consumedCalories * 10) / 10;
    const caloriesConsumed = dateCheck ? Math.round(currentCaloriesConsumed * 10) / 10 : Math.round(consumedCalories * 10) / 10;


    const caloriesRemaining = (caloriesGoal - caloriesConsumed).toFixed(1);


    const graphSize = mobile ? '120px' : '250px';
    const cutoutSize = mobile ? '80%' : '85%';

    const style = {marginTop: '5px', marginBottom: '5px'}

    const valueDisplay = () => {
        return (!mobile ? <div><h2 style={style}>{caloriesRemaining}</h2><h3 style={style}>Remaining</h3></div> :
                <div><h4 style={style}>{caloriesRemaining}</h4><h5 style={style}>Remaining</h5></div>
        )
    }
    const expandable = expandedComponent !== undefined;
    return (
        <Card header={'Remaining calories'}
              onClick={mobile && !expandedComponent ? handleCardClick : null}
              expandable={expandable}
              expanded={expandedComponent}
              collapseOnClick={handleCardClick}
        >

            <h5 style={{marginTop: '5px'}}>Remaining calories = Goal - Food</h5>
            <div style={{
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center',
                width: '100%',
                marginTop: '5px'
            }}>
                <div style={{
                    width: '50%',
                }}>
                    <DoughnutGraph goal={caloriesGoal} used={caloriesConsumed} cutoutSize={cutoutSize}
                                   graphSize={graphSize}
                                   usedColour={'rgb(54, 162, 235)'} text={valueDisplay()} animation={animateGraph} defaultOverColour={true}/>

                </div>

                <div style={{width: '50%'}}>
                    <div
                        style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <h4 style={{marginBottom: 0}}>Calorie goal</h4>
                        <h5 style={{marginBottom: 0}}>
                            {caloriesGoal}
                        </h5>
                    </div>
                    <div
                        style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <h4 style={{marginBottom: 0}}>Calories consumed</h4>
                        <h5 style={{marginBottom: 0}}>
                            {caloriesConsumed}
                        </h5>
                    </div>
                </div>
            </div>
        </Card>
    );
}

export default RemainingCaloriesComponent;
