import React from 'react';
import EditComponentName from "./EditComponentName";
import {Button} from '../../../common/fieldfactory/index'
import {
    useReduxActions
} from "../logic/Events";
import {useSelector} from "react-redux";
import ExerciseList from "./ExerciseItem";
import ExerciseModal from "./ExerciseModal";

const DayComponent = ({thisDayIndex, day, weekIndex}) => {
    const {
        handleOpenModalForCreate,
        handleDeleteDay,
        handleEditDayName,
    } = useReduxActions();
    const {dayIndex} = useSelector(state => state.createWorkOut);
    const showExercises = dayIndex === thisDayIndex;
    const heading = `Day ${thisDayIndex + 1} - `;
    const [showCreate , setShowCreate] = React.useState(false);
    return (
        <>{showExercises && <>
            <div className="form-group">
                <EditComponentName
                    heading={heading}
                    handleDelete={() => handleDeleteDay(thisDayIndex, weekIndex)}
                    name={day.name}
                    handleSave={handleEditDayName}
                />
                <ExerciseList exercises={day.exercises} dayIndex={thisDayIndex} weekIndex={weekIndex} />
                {!showCreate&&<div className="button-container-right">
                    <Button label="Create New Exercise" className="button mt-10 half-width"
                            onClick={() => handleOpenModalForCreate(thisDayIndex, weekIndex,setShowCreate)}/></div>}

            </div>
            {showCreate && <ExerciseModal pageState={'CREATE'} setShowCreate={setShowCreate} weekIndex={weekIndex} dayIndex={thisDayIndex}/>}
        </>
        }
        </>
    );
};

export default DayComponent;
